export class Toast {
  type: ToastType;
  message: string;
  title: string;
  smallText: string;

  constructor(type: ToastType, message: string, title: string, smallText: string) {
    this.type = type,
    this.message = message,
    this.title = title,
    this.smallText = smallText
  }
}

export enum ToastType {
  Primary,
  Secondary,
  Success,
  Danger,
  Warning,
  Info,
  Light,
  Dark,
  Error,
}