import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'demandeLivraisonBarcode'
})
export class DemandeLivraisonBarcodePipe implements PipeTransform {

  transform(value: any, arg1: any): any {
    const serialNumber = value;
    //console.log('DemandeLivraisonBarcodePipe | serialNumber', serialNumber);
    const provider = arg1;
    // console.log('LivraisonBarcodePipe | provider', provider);

    const providerStr = provider.toString().padStart(2, '0');
    // console.log('LivraisonBarcodePipe | providerStr', providerStr);
    const serialNumberStr = serialNumber.toString().padStart(6, '0');
    // console.log('LivraisonBarcodePipe | serialNumberStr', serialNumberStr);

    const barcode = 'LI' + providerStr + serialNumberStr;
    // console.log('LivraisonBarcodePipe | barcode', barcode);

    return barcode;
  }

}
