import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import localeFr from '@angular/common/locales/fr';

import { CartonBarcodePipe } from './pipes/carton-barcode.pipe';
import { CartonStatusPipe } from './pipes/carton-status.pipe';
import { CartonTypePipe } from './pipes/carton-type.pipe';
import { PaletteBarcodePipe } from './pipes/palette-barcode.pipe';
import { PaletteStatusPipe } from './pipes/palette-status.pipe';
import { PaletteModelePipe } from './pipes/palette-modele.pipe';
import { LivraisonBarcodePipe } from './pipes/livraison-barcode.pipe';
import { MaterielTypePipe } from './pipes/materiel-type.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { CartonListComponent } from './carton-list/carton-list.component';
import { CartonDetailsComponent } from './carton-details/carton-details.component';
import { ScanProductsComponent } from './scan-products/scan-products.component';
import { CartonAddFormComponent } from './carton-add-form/carton-add-form.component';
import { ScanCartonsComponent } from './scan-cartons/scan-cartons.component';
import { PaletteListComponent } from './palette-list/palette-list.component';
import { PaletteDetailsComponent } from './palette-details/palette-details.component';
import { ScanPalettesComponent } from './scan-palettes/scan-palettes.component';
import { PaletteAddFormComponent } from './palette-add-form/palette-add-form.component';
import { LivraisonListComponent } from './livraison-list/livraison-list.component';
import { LivraisonDetailsComponent } from './livraison-details/livraison-details.component';
import { LivraisonStatusPipe } from './pipes/livraison-status.pipe';
import { LivraisonDocumentTypePipe } from './pipes/livraison-document-type.pipe';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { LivraisonsHistoryComponent } from './livraisons-history/livraisons-history.component';
import { LivraisonsAdressesListComponent } from './livraisons-adresses-list/livraisons-adresses-list.component';
import { LivraisonAsiListComponent } from './livraison-asi-list/livraison-asi-list.component';
import { LivraisonAsiDetailsComponent } from './livraison-asi-details/livraison-asi-details.component';
import { MaterielAsiTypePipe } from './pipes/materiel-asi-type.pipe';
import { MaterielAsiConditionnementPipe } from './pipes/materiel-asi-conditionnement.pipe';
import { LivraisonAsiStatusPipe } from './pipes/livraison-asi-status.pipe';
import { LivraisonsAsiHistoryComponent } from './livraisons-asi-history/livraisons-asi-history.component';
import { RetourListComponent } from './retour-list/retour-list.component';
import { RetourBarcodePipe } from './pipes/retour-barcode.pipe';
import { RetourDetailsComponent } from './retour-details/retour-details.component';
import { RetourStatusPipe } from './pipes/retour-status.pipe';
import { ScanCartonsRetourComponent } from './scan-cartons-retour/scan-cartons-retour.component';
import { InventaireFranchiseComponent } from './inventaire-franchise/inventaire-franchise.component'; 
import { AlertComponent } from './alert/alert.component';
import { BordereauLivraisonBasicComponent } from './bordereau-livraison-basic/bordereau-livraison-basic.component';
import { FournituresComponent } from './fournitures/fournitures.component';
import { FournituresCommandesComponent } from './fournitures-commandes/fournitures-commandes.component';
import { CommandeBarcodePipe } from './pipes/commande-barcode.pipe';
import { RetoursHistoryComponent } from './retours-history/retours-history.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AboutComponent } from './about/about.component';
import { GlobalRetourDetailsComponent } from './global-retour-details/global-retour-details.component';
import { GlobalRetourListComponent } from './global-retour-list/global-retour-list.component';
import { HistoryRetoursDetailsComponent } from './history-retours-details/history-retours-details.component';
import { ToastComponent } from './toast/toast.component';
import { FranchisesListComponent } from './franchises-list/franchises-list.component';
import { FranchisesDetailsComponent } from './franchises-details/franchises-details.component';
import { FranchiseAddFormComponent } from './franchise-add-form/franchise-add-form.component';
import { DemandesLivraisonListComponent } from './demandes-livraison-list/demandes-livraison-list.component';
import { DemandesLivraisonDetailsComponent } from './demandes-livraison-details/demandes-livraison-details.component';
import { DemandeLivraisonPipe } from './pipes/demande-livraison.pipe';
import { DemandeLivraisonBarcodePipe } from './pipes/demande-livraison-barcode.pipe';
import { StatsComponent } from './stats/stats.component';
import { StatsBetweenDatesComponent } from './stats-between-dates/stats-between-dates.component';
import { StatsYearComponent } from './stats-year/stats-year.component';
import { FranchisesInactifsListComponent } from './franchises-inactifs-list/franchises-inactifs-list.component';
import { ScanProductsRetourComponent } from './scan-products-retour/scan-products-retour.component';
import { FranchiseLivraisonsHistoryComponent } from './franchise-livraisons-history/franchise-livraisons-history.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    ScanPalettesComponent,
    CartonListComponent,
    CartonBarcodePipe,
    CartonStatusPipe,
    CartonTypePipe,
    PaletteBarcodePipe,
    PaletteModelePipe,
    PaletteStatusPipe,
    LivraisonBarcodePipe,
    LivraisonStatusPipe,
    LivraisonDocumentTypePipe,
    LivraisonAsiStatusPipe,
    MaterielAsiTypePipe,
    RetourBarcodePipe,
    RetourStatusPipe,
    MaterielAsiConditionnementPipe,
    MaterielTypePipe,
    CommandeBarcodePipe,
    CartonDetailsComponent,
    ScanProductsComponent,
    CartonAddFormComponent,
    ScanCartonsComponent,
    PaletteListComponent,
    PaletteDetailsComponent,
    PaletteAddFormComponent,
    LivraisonListComponent,
    LivraisonDetailsComponent,
    LivraisonsHistoryComponent,
    LivraisonsAdressesListComponent,
    LivraisonAsiListComponent,
    LivraisonAsiDetailsComponent,
    LivraisonsAsiHistoryComponent,
    RetourListComponent,
    RetourDetailsComponent,
    ScanCartonsRetourComponent,
    AlertComponent,
    InventaireFranchiseComponent,
    BordereauLivraisonBasicComponent,
    FournituresComponent,
    FournituresCommandesComponent,
    RetoursHistoryComponent,
    UserProfileComponent,
    AboutComponent,
    GlobalRetourDetailsComponent,
    GlobalRetourListComponent,
    HistoryRetoursDetailsComponent,
    ToastComponent,
    FranchisesListComponent,
    FranchisesDetailsComponent,
    FranchiseAddFormComponent,
    DemandesLivraisonListComponent,
    DemandesLivraisonDetailsComponent,
    DemandeLivraisonPipe,
    DemandeLivraisonBarcodePipe,
    StatsComponent,
    StatsBetweenDatesComponent,
    StatsYearComponent,
    FranchisesInactifsListComponent,
    ScanProductsRetourComponent,
    FranchiseLivraisonsHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgChartsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
