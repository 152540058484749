<div class="container">

  <table class="table table-hover table-bordered">
    <thead>
    <tr>
      <th scope="col">N° de Commande</th>
      <th scope="col">Matériel</th>
      <th scope="col">Unité</th>
      <th *ngIf="isAdmin" scope="col">Centre</th>
      <th scope="col">Date</th>

    </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let commande of commandes">
        <tr>
          <th scope="row">{{commande.serialNumber | commandeBarcode: commande.provider.id}}</th>

          <td>{{commande.type | materielType}}</td>
          <td>{{commande.number}}</td>
          <td *ngIf="isAdmin">{{commande.provider.nom}}</td>
          <td>
            <ng-container *ngIf="commande.commandeDate > 0 ; else no_date">
              {{commande.commandeDate * 1000 | date}}
            </ng-container>
            <ng-template #no_date>-</ng-template>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</div>
