import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FournituresService {

  fournitures = [];
  fournituresSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getFournitures(token: string) {
    // console.log('getFournitures');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/fournitures', { headers });
  }

  getCommandes(token: string) {
    // console.log('getFournitures');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/fournitures/commandes', { headers });
  }
}
