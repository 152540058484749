import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartonService {

  initialCartons: [] = [];
  cartons: [] = [];
  cartonsSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getCartons(token: string) {
    // console.log('getCartons');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/cartons', { headers });
  }

  addCarton(cartonData: any, token: string) {
    // console.log("addCarton => ", cartonData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/carton', cartonData, { headers }, )
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.cartonsSubject.next(res);
          return res;
        })
      );

  }

  updateCarton(id: number, cartonData: any, token: string) {
    // console.log('updateCarton => ', cartonData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/carton/${id}`, cartonData, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.cartonsSubject.next(res);
          return res;
        })
      );

  }

  updateCartons(cartonsArray: [], token: string) {
    // console.log('updateCartons => ', cartonsArray);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + '/api/cartons/', cartonsArray, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.cartonsSubject.next(res);
          return res;
        })
      );
  }

  getCarton(id: string, token: string) {
    // console.log('getCarton', id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/carton/${id}`, { headers });
  }

  getProductsFromCarton(id: number, token: string) {
    // console.log('getProductsFromCarton', id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/carton/${id}/products`, { headers });
  }

}
