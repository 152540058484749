import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth.service';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats-between-dates.component.html',
  styleUrls: ['./stats-between-dates.component.css']
})
export class StatsBetweenDatesComponent implements OnInit, AfterViewInit {
	token: string = '';
  bcpToken: any = {};
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  provider: any = null;
  userIsLoggedin: boolean = false;

	hoveredDate: NgbDate | null = null;

	// fromDate: NgbDate;
	fromDate: NgbDate | null = null;
	toDate: NgbDate | null = null;
	fromDateJs: Date | null = null;
	toDateJs: Date | null = null;

  error: string = '';

  errorMessage: string = '';

	productsMaked: any | null = null;
	cartonsMaked: any | null = null;
	palettesMaked: any | null = null;
	productsRetours: any | null = null;

	constructor(calendar: NgbCalendar, private authService: AuthService, private statsService: StatsService) {
		this.fromDate = new NgbDate(calendar.getToday().year, calendar.getToday().month, 1);
		this.fromDateJs = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
		this.toDate = calendar.getToday();
		this.toDateJs = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
			this.fromDateJs = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
			this.toDateJs = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
			
			if (this.fromDateJs && this.toDateJs) {
				this.loadCountProductsMakedBetweenDates(this.fromDateJs, this.toDateJs);
				this.loadCountCartonsMakedBetweenDates(this.fromDateJs, this.toDateJs);
				this.loadCountPalettesMakedBetweenDates(this.fromDateJs, this.toDateJs);
				this.loadCountProductsRetoursBetweenDates(this.fromDateJs, this.toDateJs);
			}
		} else {
			this.toDate = null;
			this.toDateJs = null;
			this.fromDate = date;
			this.fromDateJs = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
			this.productsMaked = null;
			this.cartonsMaked = null;
			this.palettesMaked = null;
			this.productsRetours = null;
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
	ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      this.bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = this.bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      
      this.provider = this.decodedToken.provider;
    }
  }
	ngAfterViewInit(): void {
		if (this.fromDateJs && this.toDateJs) {
			// this.loadCountProductsMakedCombinedBetweenDates(this.fromDateJs, this.toDateJs);

			this.loadCountProductsMakedBetweenDates(this.fromDateJs, this.toDateJs);
			this.loadCountCartonsMakedBetweenDates(this.fromDateJs, this.toDateJs);
			this.loadCountPalettesMakedBetweenDates(this.fromDateJs, this.toDateJs);
			this.loadCountProductsRetoursBetweenDates(this.fromDateJs, this.toDateJs);
		}
  }
	

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

	/*loadCountProductsMakedCombinedBetweenDates(fromDate: Date, toDate: Date) {
		// console.log('loadCountProductsMakedCombinedBetweenDates | new Date().getUTCDate()', new Date().getUTCDate());
		// console.log('loadCountProductsMakedCombinedBetweenDates | new Date().getTimezoneOffset()', new Date().getTimezoneOffset());
		
		// let fromDateCorrect = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
		// console.log('loadCountProductsMakedCombinedBetweenDates | fromDateCorrect', fromDateCorrect.toISOString());
		
		let fromDateStr = fromDate.toISOString();
		// console.log('loadCountProductsMakedCombinedBetweenDates | fromDateStr', fromDateStr);
		// console.log('loadCountProductsMakedCombinedBetweenDates | fromDate.getUTCDate()', fromDate.getUTCDate());
		// console.log('loadCountProductsMakedCombinedBetweenDates | fromDate.getTimezoneOffset()', fromDate.getTimezoneOffset());

		let toDateStr = toDate.toISOString();
		// console.log('loadCountProductsMakedCombinedBetweenDates | toDateStr', toDateStr);
		// console.log('loadCountProductsMakedCombinedBetweenDates | toDate.getUTCDate()', toDate.getUTCDate());
		// console.log('loadCountProductsMakedCombinedBetweenDates | toDate.getTimezoneOffset()', toDate.getTimezoneOffset());

    this.statsService.getCountProductsMakedCombinedBetweenDates(fromDateStr, toDateStr, this.token)
      .subscribe({
        next: data => this.handleCountProductsMakedCombinedBetweenDates(data),
        error: err => console.error(err),
        complete: () => console.log("products maked combined loaded!")
      });
  }
	
	handleCountProductsMakedCombinedBetweenDates(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.productsMaked = response.productsMaked;
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }*/

	
  loadCountProductsMakedBetweenDates(fromDate: Date, toDate: Date) {
		// console.log('loadCountProductsMakedBetweenDates | new Date().getUTCDate()', new Date().getUTCDate());
		// console.log('loadCountProductsMakedBetweenDates | new Date().getTimezoneOffset()', new Date().getTimezoneOffset());
		
		// let fromDateCorrect = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
		// console.log('loadCountProductsMakedBetweenDates | fromDateCorrect', fromDateCorrect.toISOString());
		
		let fromDateStr = fromDate.toISOString();
		// console.log('loadCountProductsMakedBetweenDates | fromDateStr', fromDateStr);
		// console.log('loadCountProductsMakedBetweenDates | fromDate.getUTCDate()', fromDate.getUTCDate());
		// console.log('loadCountProductsMakedBetweenDates | fromDate.getTimezoneOffset()', fromDate.getTimezoneOffset());

		let toDateStr = toDate.toISOString();
		// console.log('loadCountProductsMakedBetweenDates | toDateStr', toDateStr);
		// console.log('loadCountProductsMakedBetweenDates | toDate.getUTCDate()', toDate.getUTCDate());
		// console.log('loadCountProductsMakedBetweenDates | toDate.getTimezoneOffset()', toDate.getTimezoneOffset());

    this.statsService.getCountProductsMakedBetweenDates(fromDateStr, toDateStr, this.token)
      .subscribe({
        next: data => this.handleCountProductsMakedBetweenDates(data),
        error: err => console.error(err),
        // complete: () => console.log("products maked loaded!")
      });
  }

	handleCountProductsMakedBetweenDates(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.productsMaked = response.productsMaked;
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }


  loadCountCartonsMakedBetweenDates(fromDate: Date, toDate: Date) {
		// console.log('loadCountCartonsMakedBetweenDates | new Date().getUTCDate()', new Date().getUTCDate());
		// console.log('loadCountCartonsMakedBetweenDates | new Date().getTimezoneOffset()', new Date().getTimezoneOffset());
		
		// let fromDateCorrect = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
		// console.log('loadCountCartonsMakedBetweenDates | fromDateCorrect', fromDateCorrect.toISOString());
		
		let fromDateStr = fromDate.toISOString();
		// console.log('loadCountCartonsMakedBetweenDates | fromDateStr', fromDateStr);
		// console.log('loadCountCartonsMakedBetweenDates | fromDate.getUTCDate()', fromDate.getUTCDate());
		// console.log('loadCountCartonsMakedBetweenDates | fromDate.getTimezoneOffset()', fromDate.getTimezoneOffset());

		let toDateStr = toDate.toISOString();
		// console.log('loadCountCartonsMakedBetweenDates | toDateStr', toDateStr);
		// console.log('loadCountCartonsMakedBetweenDates | toDate.getUTCDate()', toDate.getUTCDate());
		// console.log('loadCountCartonsMakedBetweenDates | toDate.getTimezoneOffset()', toDate.getTimezoneOffset());

    this.statsService.getCountCartonsMakedBetweenDates(fromDateStr, toDateStr, this.token)
      .subscribe({
        next: data => this.handleCountCartonsMakedBetweenDates(data),
        error: err => console.error(err),
        // complete: () => console.log("cartons maked loaded!")
      });
  }

	handleCountCartonsMakedBetweenDates(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.cartonsMaked = response.cartonsMaked;
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }


  loadCountPalettesMakedBetweenDates(fromDate: Date, toDate: Date) {
		// console.log('loadCountPalettesMakedBetweenDates | new Date().getUTCDate()', new Date().getUTCDate());
		// console.log('loadCountPalettesMakedBetweenDates | new Date().getTimezoneOffset()', new Date().getTimezoneOffset());
		
		// let fromDateCorrect = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
		// console.log('loadCountPalettesMakedBetweenDates | fromDateCorrect', fromDateCorrect.toISOString());
		
		let fromDateStr = fromDate.toISOString();
		// console.log('loadCountPalettesMakedBetweenDates | fromDateStr', fromDateStr);
		// console.log('loadCountPalettesMakedBetweenDates | fromDate.getUTCDate()', fromDate.getUTCDate());
		// console.log('loadCountPalettesMakedBetweenDates | fromDate.getTimezoneOffset()', fromDate.getTimezoneOffset());

		let toDateStr = toDate.toISOString();
		// console.log('loadCountPalettesMakedBetweenDates | toDateStr', toDateStr);
		// console.log('loadCountPalettesMakedBetweenDates | toDate.getUTCDate()', toDate.getUTCDate());
		// console.log('loadCountPalettesMakedBetweenDates | toDate.getTimezoneOffset()', toDate.getTimezoneOffset());

    this.statsService.getCountPalettesMakedBetweenDates(fromDateStr, toDateStr, this.token)
      .subscribe({
        next: data => this.handleCountPalettesMakedBetweenDates(data),
        error: err => console.error(err),
        // complete: () => console.log("palettes maked loaded!")
      });
  }

	handleCountPalettesMakedBetweenDates(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.palettesMaked = response.palettesMaked;
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

	
  loadCountProductsRetoursBetweenDates(fromDate: Date, toDate: Date) {
		// console.log('loadCountProductsRetoursBetweenDates | new Date().getUTCDate()', new Date().getUTCDate());
		// console.log('loadCountProductsRetoursBetweenDates | new Date().getTimezoneOffset()', new Date().getTimezoneOffset());
		
		// let fromDateCorrect = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000)
		// console.log('loadCountProductsRetoursBetweenDates | fromDateCorrect', fromDateCorrect.toISOString());
		
		let fromDateStr = fromDate.toISOString();
		// console.log('loadCountProductsRetoursBetweenDates | fromDateStr', fromDateStr);
		// console.log('loadCountProductsRetoursBetweenDates | fromDate.getUTCDate()', fromDate.getUTCDate());
		// console.log('loadCountProductsRetoursBetweenDates | fromDate.getTimezoneOffset()', fromDate.getTimezoneOffset());

		let toDateStr = toDate.toISOString();
		// console.log('loadCountProductsRetoursBetweenDates | toDateStr', toDateStr);
		// console.log('loadCountProductsRetoursBetweenDates | toDate.getUTCDate()', toDate.getUTCDate());
		// console.log('loadCountProductsRetoursBetweenDates | toDate.getTimezoneOffset()', toDate.getTimezoneOffset());

    this.statsService.getCountProductsRetoursBetweenDates(fromDateStr, toDateStr, this.token)
      .subscribe({
        next: data => this.handleCountProductsRetoursBetweenDates(data),
        error: err => console.error(err),
        // complete: () => console.log("products retours loaded!")
      });
  }

	handleCountProductsRetoursBetweenDates(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.productsRetours = response.productsRetours;
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }
}