import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaletteService } from '../services/palette.service';
import {AuthService} from '../services/auth.service';
import {Palette} from '../model/palette';
import {CartonService} from '../services/carton.service';
import {LivraisonService} from '../services/livraison.service';
import { ScanCartonsComponent } from '../scan-cartons/scan-cartons.component';

@Component({
  selector: 'app-palette-details',
  templateUrl: './palette-details.component.html',
  styleUrls: ['./palette-details.component.css'],
  providers: [ScanCartonsComponent]
})
export class PaletteDetailsComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  livraison: any;
  palette: any;
  paletteModeles: any;
  paletteModeleDetails: any;
  paletteSize: number = 0;
  error: any;
  errorMessage: string = '';

  isLivraisonReceived: boolean = false;
  isPalettesReceived: boolean = false;
  isCartonsReceived: boolean = false;

  constructor(private paletteService: PaletteService,
              private cartonService: CartonService,
              private livraisonService: LivraisonService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];
    this.loadPalette(id);
  }

  loadPalette(id: number) {
    this.paletteService.getPalette(id, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('palette loaded!')
        }
      );
  }

  loadCartonsFromPalette(id: number) {
    this.paletteService.getCartonsFromPalette(id, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('carton from palette loaded!')
        }
      );
  }

  loadLivraison(id: number) {
    this.livraisonService.getLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('livraison loaded!')
        }
      );
  }


  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  handleServerResponse(response: any) {

    if (response.success) {
      if (response.palette) {
        this.palette = response.palette;
        this.palette.cartons = []; // On force l'initialisation des cartons à 0.
        this.isPalettesReceived = true;

        if (this.palette.status === 0) {
          if (this.paletteModeles === undefined) {
            this.loadAllPaletteModeles();
          }
        }
        
        if (this.paletteModeleDetails === undefined) {
          this.loadPaletteModeleDetails(this.palette.modele);
        }

        if (this.palette.idLivraison) {
          this.loadLivraison(this.palette.idLivraison);
        }
        this.loadCartonsFromPalette(this.palette.id);
      }

      if (response.livraison) {
        this.livraison = response.livraison;
        this.livraison.palettes = []; // On force l'initialisation des palettes à 0.

        this.isLivraisonReceived = true;
      }

      if (response.cartons) {
        if (this.palette) {
          this.palette.cartons = response.cartons.sort((a: { id: number; }, b: { id: number; }) => {
            return a.id - b.id;
          });

          this.isCartonsReceived = true;
        }
      }

      /*if (this.isPalettesReceived && this.isCartonsReceived) {
        if (this.palette.cartons.length > 0) {
          this.setPaletteSize(this.palette.cartons[0].type);
          // console.log('handleServerResponse - this.palette', this.palette);
        } else {
          // console.log('C\'est une nouvelle Palette vide de Carton');
          this.palette.paletteSize = 1;
        }
      }*/

      // console.log(this.palette);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  loadAllPaletteModeles() {
    this.paletteService.getPaletteModeles(this.token)
      .subscribe(
        {
          next: data => this.handlePaletteModelesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette model loaded!')
        }
      );
  }

  loadPaletteModeleDetails(idModelePalette: number) {
    this.paletteService.getPaletteModeleDetails(idModelePalette, this.token)
      .subscribe(
        {
          next: data => this.handlePaletteModeleDetailsServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette model detail loaded!')
        }
      );
  }
  
  handleUpdatePaletteServerResponse(response: any) {

    if (response.success) {
      if (response.palette) {
        console.log('Palette a été mise à jour', this.palette);
      }
    } else {
      this.handleError(response);
    }
  }

  handlePaletteModelesServerResponse(response: any = {}) {

    if (response.success) {
      this.paletteModeles = response.paletteModeles.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
    } else {
      this.handleError(response);
    }
  }

  handlePaletteModeleDetailsServerResponse(response: any = {}) {

    if (response.success) {
      this.paletteModeleDetails = response.paletteModeleDetails.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });

      this.paletteSize = 0

      for(let modele of this.paletteModeleDetails) {
        this.paletteSize += modele.nbCartons;
      }

    } else {
      this.handleError(response);
    }
  }

  handleError(error: { statusText: any; }) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

  /*setPaletteSize(cartonType) {
    // console.log('setPaletteSize - cartonType', cartonType.toUpperCase());

    let paletteSize = -1;

    switch (cartonType.toUpperCase()) {
      case 'G':
      case 'K':
        paletteSize = 42;
        break;
      case 'B':
        paletteSize = 1;
        break;
      case 'C':
        paletteSize = 1;
        break;
      case 'T':
      case 'F':
        paletteSize = 1;
        break;
      case '':
      default:
        paletteSize = -1;
    }

    this.palette.paletteSize = paletteSize;

    // console.log('setPaletteSize - paletteSize', this.palette.paletteSize);
    // console.log('setPaletteSize - this.palette ', this.palette);
  }*/

  updatePalette(palette: Palette) {
    this.palette = palette;
    console.log('TOTAL CARTONS : '+this.palette.cartons.length+'/'+this.paletteSize);
    
    /*let checkedPaletteFinished = true;

    for(let modele of this.paletteModeleDetails) {
      console.log('modele', modele);
      let cartonsTypeChecked = this.palette.cartons.filter(c => c.type === modele.product_type);
      console.log('cartonsTypeChecked', cartonsTypeChecked);

      if (cartonsTypeChecked.length !== modele.nbCartons) {
        checkedPaletteFinished = false;
        break;
      } else {
        console.log('INSIDE FOR LOOP on LOOP');
      }

      console.log('INSIDE FOR LOOP on END');
    }

    console.log('OUTSIDE FOR LOOP');
    this.isPaletteFinished = checkedPaletteFinished;

    if (this.isPaletteFinished) {
      console.log('La Palette est complète !');
    }*/
  }

  

  onChangePaletteModele(newPaletteModele: any) {
    const paletteModele = this.paletteModeles.find((m: { id: string; }) => parseInt(m.id, 10) === parseInt(newPaletteModele, 10));
    console.log(paletteModele)

    this.palette.modele = paletteModele.id;
    this.loadPaletteModeleDetails(this.palette.modele);

    this.modifyPalette();
  }

  modifyPalette() {

    this.paletteService.updatePalette(this.palette.id, this.palette, this.token)
      .subscribe(
        {
          next: data => this.handleUpdatePaletteServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette was updated!')
        }
      );
  }

  savePalette(input: any) {
    this.palette.status = 1;

    this.paletteService.updatePalette(parseInt(this.palette.id, 10), this.palette, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette was updated and saved!')
        }
      );

    this.cartonService.updateCartons(this.palette.cartons, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('carton was updated!')
        }
      );
  }

}
