import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'livraisonStatus'
})
export class LivraisonStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'En préparation';
      case 2:
        return 'En attente de validation par ASI';
      case 3:
        return 'Livraison prête à partir';
      case 4:
        return 'Partie en livraison';
      case 5:
        return 'Annulée';
      case 6:
        return 'En attente de devis';
      default:
        return 'Inconnu';
    }
  }

}
