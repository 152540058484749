import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, OnDestroy} from '@angular/core';
import {Provider} from '../model/provider';
import {FranchiseService} from '../services/franchise.service';
import {Retour} from '../model/retour';
import {RetourService} from '../services/retour.service';
import {ProductService} from '../services/product.service';
import {AuthService} from '../services/auth.service';
import {Franchise} from '../model/franchise';
import {ProviderService} from '../services/provider.service';
import {RetourBarcodePipe} from '../pipes/retour-barcode.pipe';
import {ActivatedRoute} from '@angular/router';
import {CartonRetour} from '../model/carton_retour';

@Component({
  selector: 'app-retour-details',
  templateUrl: './global-retour-details.component.html',
  styleUrls: ['./global-retour-details.component.css']
})
export class GlobalRetourDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  retour: any;
  originalProvider: any;
  providers: any = [];
  franchises: any = [];
  // cartons = [];
  products: any = [];
  error: any = null;
  errorMessage: string = '';
  source: any;
  parametersObservable: any;

  isRetourReceived: boolean = false;

  constructor(private retourService: RetourService,
              private productService: ProductService,
              private providerService: ProviderService,
              private franchiseService: FranchiseService,
              private activatedRoute: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];

    this.parametersObservable = this.activatedRoute.params.subscribe((params = {}) => {
      console.log(params['source']);
      this.source = params['source'];
      this.loadRetour(id);
    });
  }

  // Don't forget to unsubscribe from the Observable
  ngOnDestroy() {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.ref.detectChanges();
  }

  loadRetour(id: number) {
    this.retourService.getRetour(id, this.token)
      .subscribe(
        {
          next: (data) => this.handleServerResponse(data),
          error: (err) => console.error(err),
          complete: () => console.log('retour loaded!')
        }
        // data => {
        //   this.handleServerResponse(data);
        // },
        // error => {
        //   this.handleError(error);
        // }
      );
  }

  /*loadCartonsFromRetour(id) {
    console.log('loadCartonsFromRetour');
    this.retourService.getCartonsFromRetour(id, this.token)
      .subscribe(
        data => {
          console.log('loadCartonsFromRetour data', data);
          this.handleLivraisonServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );
  }*/

  loadProductsFromRetour(id: string) {
    console.log('loadProductsFromRetour');
    this.retourService.getProductsFromRetour(this.source, id, this.token)
      .subscribe({
        next: (data) => this.handleServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log('products retour loaded!')
      });
  }

  loadAllProviders() {
    console.log('loadAllProviders');
    this.providerService.getProviders(this.token)
      .subscribe({
        next: (data) => this.handleProviderServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log('providers loaded!')
      });
  }

  loadAllFranchises() {
    console.log('loadAllFranchises');
    this.franchiseService.getFranchises(this.token)
      .subscribe({
        next: (data) => this.handleFranchiseServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log("franchises loaded!")
      });
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  handleServerResponse(response: any = {}) {
    // console.log('handleLivraisonServerResponse ', response);

    if (response.success) {
      if (response.retour) {
        this.retour = response.retour;
        this.retour.barcode = new RetourBarcodePipe()
          .transform(this.retour.serialNumber, this.retour.provider.id);

        console.log('this.retour', this.retour);

        this.originalProvider = Object.assign({}, this.retour.provider);
        console.log('originalProvider', this.originalProvider);

        if (!this.retour.franchise) {
          this.retour.franchise = <Franchise>{
            id: 0,
            initiales: ''
          };
        }

        // this.loadProductsFromRetour(this.retour.id);
        // this.loadCartonsFromRetour(this.retour.id);
        // this.loadProductsFromRetour(this.retour.id);
        this.isRetourReceived = true;

        // this.loadCartonsFromRetour(this.retour.id);
        this.loadProductsFromRetour(this.retour.id);
      }

      /*if (response.cartons) {
        console.log('IL Y A DES CARTONS');

        if (this.retour) {
          console.log('RETOUR', this.retour);

          this.cartons = response.cartons.sort((a, b) => {
            return a.id - b.id;
          });
        }
      }*/

      if (response.products) {
        console.log('IL Y A DES PRODUCTS');

        if (this.retour) {
          console.log('RETOUR', this.retour);

          this.products = response.products.sort((a: { id: number; }, b: { id: number; }) => {
            return a.id - b.id;
          });
        }
      }

      if (this.isRetourReceived) {
        if (this.retour.status === 0) {
          if (this.providers.length === 0) {
            this.loadAllProviders();
          }

          if (this.franchises.length === 0) {
            this.loadAllFranchises();
          }
        }
      }
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

  handleUpdateRetourServerResponse(response: { success: any; retour: any; message: string; }) {
    console.log('handleUpdateRetourServerResponse ', response);

    if (response.success) {
      if (response.retour) {
        this.retour = response.retour;
        this.retour.barcode = new RetourBarcodePipe()
          .transform(this.retour.serialNumber, this.retour.provider.id);

        console.log('this.retour.barcode', this.retour.barcode);

        this.originalProvider = Object.assign({}, this.retour.provider);

        console.log('this.retour', this.retour);
      }
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

  handleProviderServerResponse(response: any = {}) {
    console.log('handleProviderServerResponse ', response);

    if (response.success) {
      console.log('IL Y A DES PROVIDERS !');
      this.providers = response.providers.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
      console.log('this.providers', this.providers);
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

  handleFranchiseServerResponse(response: any = {}) {
    // console.log('handleFranchiseServerResponse ', response);

    if (response.success) {
      // console.log('IL Y A DES FRANCHISÉS !');
      this.franchises = response.franchises.sort((a: { initiales: number; }, b: { initiales: number; }) => {
        return a.initiales - b.initiales;
      });

      this.franchises.push(<Franchise>{
        id: 0,
        initiales: ''
      });

      // console.log('this.retour.franchise = ', JSON.stringify(this.retour.franchise));
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

  handleError(error: { statusText: any; }) {
    console.error('handleError', error.statusText);
    this.error = error;
  }

  updateRetour(event: { retour: { id: any; status: any; cartons: any; }; products: string | any[]; }) {
    console.log(`Retour ${event.retour.id} [Status: ${event.retour.status}] updated with ${event.products.length} products`);
    console.log(JSON.stringify(event.retour));
    console.log(JSON.stringify(event.retour.cartons));

    this.retour = event.retour;
    // this.products = event.products;
  }

  /*updateCarton(carton: CartonRetour) {
    console.log(`Carton ${carton.id} [Status: ${carton.status}] updated with ${carton.products.length} products`);
    console.log(JSON.stringify(carton));
    console.log(JSON.stringify(carton.products));

    this.cartons.push(event);
  }*/

  onChangeProvider(newProvider: string) {
    console.log('onChangeProvider');

    // this.retour.barcode = '';

    console.log('newProvider', newProvider);
    console.log('this.providers', this.providers);
    console.log('this.retour', this.retour);

    console.log('originalProvider', this.originalProvider);

    const provider = this.providers.find((p: { id: string; }) => parseInt(p.id, 10) === parseInt(newProvider, 10));
    console.log('provider found before', provider);

    this.retour.provider = Object.assign({}, provider);
    this.retour.provider.oldProviderId = this.originalProvider.id;
    console.log('this.retour.provider', this.retour.provider);

    console.log('provider found after', provider);

    this.modifyRetour();
  }

  onChangeFranchise(newFranchise: any) {
    const franchise = this.franchises.find((f: { initiales: any; }) => f.initiales === newFranchise);

    this.retour.franchise = franchise;
    console.log(this.retour.franchise);

    this.modifyRetour();
  }

  modifyRetour() {
    console.log('modifyRetour', this.retour);

    this.retourService.updateRetour(this.retour, this.token)
      .subscribe({
        next: (data) => this.handleUpdateRetourServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log("retour updated!")
      });
  }

  validateRetour() {
    this.retour.status = 1;

    this.retourService.updateRetour(this.retour, this.token)
      .subscribe({
        next: (data) => this.handleUpdateRetourServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log("retour validated!")
       });
  }

  saveRetour() {
    this.retour.status = 1;

    // console.log('saveRetour', this.retour);

    this.retourService.updateRetour(this.retour, this.token)
      .subscribe({
        next: (data) => this.handleUpdateRetourServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log("retour saved!")
      });

    console.log('saveRetour this.products', this.products);

    // TODO Pas de mise à jour du product ? À voir... (créatino d'une table products_retours)
    /*this.productService.updateProducts(this.products, this.token)
      .subscribe(
        data => {
          this.handleLivraisonServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );*/
  }

  startRetour(input: any) {
    this.retour.status = 2;
    this.retour.scanStartDate = Date.now().toString();

    this.sendUpdateRetour(this.retour);
  }

  sendRetour(input: any) {
    this.retour.status = 3;
    this.retour.scanFinishDate = Date.now().toString();

    this.sendUpdateRetour(this.retour);
  }

  sendUpdateRetour(retour: Retour) {
    this.retourService.updateRetour(retour, this.token)
      .subscribe({
        next: (data) => this.handleServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log("send updated retour!")
      });
  }
}
