<div class="container">

    <main role="main" class="container">
  
      <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
        <div *ngIf="isAdmin" class="lh-100">
          <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createRetour()">Préparer un Retour</button>
        </div>
  
        <div class="lh-100">
          <button type="button" class="btn btn-lg btn-success" appNoDblClick (click)="showHistory()">Historique des Retours</button>
        </div>
      </div>
  
      <ng-container *ngIf="isAdmin">
        <div class="my-3 p-3 bg-white rounded box-shadow">
          <h3 class="border-bottom border-gray pb-2 mb-0">En cours de création</h3>
  
          <div class="media row pt-3">
            <ng-container *ngFor="let retour of retours">
              <ng-container *ngIf="retour.status === 0">
                <div class="col-sm me-2 pt-3">
                  <a class="btn btn-outline-danger bloc" href="#" [routerLink]="['/retour', retour.id]">
                    {{retour.serialNumber | retourBarcode: retour.provider.id}}<br><br>
  
                    <ng-container *ngIf="retour.franchise">
                      {{retour.franchise.nomPart.toUpperCase()}}<br>
                      {{retour.franchise.prenomPart}}<br>
                      [{{retour.franchise.initiales.toUpperCase()}}]<br><br>
                    </ng-container>
  
                    {{retour.creationDate | date:'dd MMM y'}}
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">Retours attendus</h3>
  
        <div class="media row pt-3">
          <ng-container *ngFor="let retour of retours">
            <ng-container *ngIf="retour.status === 1 && (retour.franchise || isAdmin)">
              <div class="col-sm me-2 pt-3">
                <a class="btn btn-outline-danger bloc" href="#" [routerLink]="['/retour', retour.id]">
                  {{retour.serialNumber | retourBarcode: retour.provider.id}}<br><br>
  
                  <ng-container *ngIf="retour.franchise">
                    {{retour.franchise.nomPart.toUpperCase()}}<br>
                    {{retour.franchise.prenomPart}}<br>
                    [{{retour.franchise.initiales.toUpperCase()}}]<br><br>
                  </ng-container>
  
                  {{retour.creationDate | date:'dd MMM y'}}
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En cours de scan</h3>
  
        <div class="media row pt-3">
          <ng-container *ngFor="let retour of retours">
            <ng-container *ngIf="retour.status === 2 && (retour.franchise || isAdmin)">
              <div class="col-sm me-2 pt-3">
                <a class="btn btn-outline-warning bloc" href="#" [routerLink]="['/retour', retour.id]">
                  {{retour.serialNumber | retourBarcode: retour.provider.id}}<br><br>
  
                  <ng-container *ngIf="retour.franchise">
                    {{retour.franchise.nomPart.toUpperCase()}}<br>
                    {{retour.franchise.prenomPart}}<br>
                    [{{retour.franchise.initiales.toUpperCase()}}]<br><br>
                  </ng-container>
  
                  {{retour.creationDate | date:'dd MMM y'}}
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
  
    </main>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </div>
  