import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Franchise } from '../model/franchise';
import { FranchiseService } from '../services/franchise.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-franchises-list',
  templateUrl: './franchises-list.component.html',
  styleUrls: ['./franchises-list.component.css']
})
export class FranchisesListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: any = [];
  provider: any = null;
  franchises: Franchise[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  franchiseCreated: any = {};
  errorMessage: string = '';

  constructor(private router: Router, private franchiseService: FranchiseService, private authService: AuthService) { }

  ngOnInit() {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      
      if (this.isAdmin) {
        this.loadFranchises();
      } else {
        this.loadFranchises();
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadFranchises() {
    this.franchiseService.getFranchises(this.token)
      .subscribe({
        next: data => this.displayFranchises(data),
        error: err => console.error(err),
        complete: () => console.log('franchises loaded!')
      })
  }

  displayFranchises(response: any = {}) {
    if (response.success) {
      this.franchises = response.franchises;
      console.log("THIS FRANCHISES", this.franchises)

      if (this.isAdmin) {
        // const providersFranchises = this.franchises.map((franchise: { provider: any; }) => franchise.provider);
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }


  createFranchise() {
    const franchise: Franchise = <Franchise><unknown>{
      id: Date.now(),
      abr_jur: "",
      nom_soc: "",
      siren: 0,
      TVA_intracom: "",
      prenomPart: "",
      nomPart: "",
      adresse: "",
      comp_adr: "",
      codePostal: "",
      villeLivraison: "",
      ajoutAdresseDate: 0,
      ajoutAdresseDatetime: "",
      initiales: "",
      mail_part: "",
      TelPortPart: "",
      accessibilite_19T: false
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.router.navigateByUrl(`/franchise/create`);
  }

  handleServerResponse(response: { success: any; franchise: any; message: string; }) {

    if (response.success) {
      // console.log('ON PASSE PAR LÀ');
      this.franchiseCreated= response.franchise;
      this.router.navigateByUrl(`/franchise/${this.franchiseCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any = {}) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

}
