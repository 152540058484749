import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {CartonService} from '../services/carton.service';
import {Carton} from '../model/carton';

@Component({
  selector: 'app-carton-list',
  templateUrl: './carton-list.component.html',
  styleUrls: ['./carton-list.component.css']
})
export class CartonListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: any = [];
  provider: any = null;
  cartons: Carton[] = [];
  cartonStatus0: Carton[] = [];
  cartonStatus1: Carton[] = [];
  cartonsCassettes: Carton[] = [];
  cartonsBaguettes: Carton[] = [];
  cartonsCanettes: Carton[] = [];
  cartonsFusettes: Carton[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  cartonCreated: any;
  errorMessage: string = '';

  constructor(private router: Router, private cartonService: CartonService, private authService: AuthService) { }

  ngOnInit(): void {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      // admin needs to see all ads
      if (this.isAdmin) {
        this.loadCartons();
      } else {
        this.loadCartons();
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadCartons() {
    this.cartonService.getCartons(this.token)
      .subscribe({
        next: data => this.displayCartons(data),
        error: err => console.error(err),
        complete: () => console.log('cartons loaded!')
      });
  }

  displayCartons(response: any = {}) {

    if (response.success) {
      this.cartons = response.cartons;
      this.cartonStatus0 = this.cartons.filter((carton: { status: number; }) => carton.status === 0);
      console.log("this.cartonStatus0", this.cartonStatus0.length)
      this.cartonStatus1 = this.cartons.filter((carton: { status: number; }) => carton.status === 1);
      
      this.cartonsCassettes = this.cartonStatus1.filter((carton: { type: string; }) => (carton.type === 'G' || carton.type === 'K') );
      this.cartonsBaguettes = this.cartonStatus1.filter((carton: { type: string; }) => carton.type === 'B');
      this.cartonsCanettes = this.cartonStatus1.filter((carton: { type: string; }) => carton.type === 'C');
      this.cartonsFusettes = this.cartonStatus1.filter((carton: { type: string; }) => (carton.type === 'T' || carton.type === 'F') );
    
      if (this.isAdmin) {
        const providersCartons = this.cartons.map((carton: { provider: any; }) => carton.provider);

        const uniqueCartonsArray = (data: any[]) => [...new Set(data.map(o => JSON.stringify(o)))].map(s => JSON.parse(s.toString()));
        this.providers = uniqueCartonsArray(providersCartons).sort(function(a, b) {
          return a.id - b.id;
        });

        for (let p = 0; p < this.providers.length; p++) {
          this.providers[p]['cartonStatus0'] = this.cartonStatus0.filter((carton: { provider: { id: any; }; }) => carton.provider.id === this.providers[p].id);
          this.providers[p]['cartonStatus1'] = this.cartonStatus1.filter((carton: { provider: { id: any; }; }) => carton.provider.id === this.providers[p].id);
          this.providers[p]['cartonsCassettes'] = this.providers[p]['cartonStatus1'].filter((carton: { type: string; }) => (carton.type === 'G' || carton.type === 'K') );
          this.providers[p]['cartonsBaguettes'] = this.providers[p]['cartonStatus1'].filter((carton: { type: string; }) => carton.type === 'B');
          this.providers[p]['cartonsCanettes'] = this.providers[p]['cartonStatus1'].filter((carton: { type: string; }) => carton.type === 'C');
          this.providers[p]['cartonsFusettes'] = this.providers[p]['cartonStatus1'].filter((carton: { type: string; }) => (carton.type === 'T' || carton.type === 'F') );
        }
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  createCarton() {
    const carton: Carton = <Carton><unknown>{
      id: Date.now(),
      provider: { id: this.provider.id },
      serialNumber: 0,
      status: 0,
      type: '',
      idPalette: 0,
      barcode: '',
      creationDate: new Date().toISOString(),
      products: [],
      cartonSize: 1
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.cartonService.addCarton(carton, token).subscribe({
      next: data => this.handleServerResponse(data),
      error: err => console.error(err),
      complete: () => console.log('carton was created!')
    });
  }

  handleServerResponse(response: { success: any; carton: null; message: string; }) {

    if (response.success) {
      this.cartonCreated = response.carton;
      this.router.navigateByUrl(`/carton/${this.cartonCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

}