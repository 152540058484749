import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paletteModele'
})
export class PaletteModelePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 0:
        return 'Non défini';
      case 1:
        return 'Cassettes';
      case 2:
        return 'Personnalisée';
      case 3:
        return '3 produits [P3P]';
      case 4:
        return '4 produits [P4P]';
      case 5:
        return 'Cassettes (ancien modèle)';
      case 6:
        return 'Cassettes (modèle intermédiaire nouveau carton)';
      case 7:
        return '3 produits B [P3PB]';
      case 8:
        return 'Baguettes [PBG]';
      case 9:
        return 'Canettes [PCN]';
      case 10:
        return 'Fusettes [PFU]';
      default:
        return 'Modèle inconnu';
    }
  }

}
