<br>

<ng-container *ngIf="isFranchise || isAdmin">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h3>Retour Franchisé</h3>

          <ng-container *ngIf="productsFranchise; else elseTemplate">
            <ng-container *ngIf="retour.status === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun produit</h4>
            </ng-container>
            <ng-container *ngIf="productsFranchise.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ productsFranchise.length}} produit</h4>
            </ng-container>
            <ng-container *ngIf="productsFranchise.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ productsFranchise.length}} produits</h4>
            </ng-container>
  
            <div *ngIf="(productsFranchise.length >= 0)">
              <ng-container *ngIf="retour.status === 1">
                <button class="btn btn-primary me-3" type="button" (click)="CompareIsClicked()">
                  Comparer
                </button>
              </ng-container>
              <ng-container *ngIf="compareIsNotEmpty">
                <button class="btn btn-success me-3" type="button" (click)="checkScans()">Valider</button>
              </ng-container>
              <ng-container *ngIf="this.isAdmin && this.decodedToken.username === 'jcarrat'">
                <button class="btn btn-danger me-3" type="button" (click)="deleteScans('franchise')">Supprimer les scans</button>
              </ng-container>
              <ng-container *ngIf="compareIsClicked">
                <div class=" mt-2" >
                  <textarea (keyup)="onKey($event)" class="form-control" #compareScans id="compareScans" name="compareScans" rows="10"></textarea>
                </div>  
              </ng-container>
              <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRetourFranchise" aria-expanded="false" aria-controls="collapseRetourFranchise">
                Voir le détail
              </button>

              <br><br>

              <div class="container collapse" id="collapseRetourFranchise">                
                <div class="row">
                  <ng-container *ngFor="let product of productsFranchise">
                    <div class="mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                      <button type="button" class="btn btn-outline-secondary text-center form-control numFSinput">
                        {{product.barcode}}
                      </button>    
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <h4 class="card-subtitle mb-2 text-muted">Le scan n'a pas encore démarré.</h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <br>
</ng-container>

<ng-container *ngIf="isCentre || isAdmin">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h3>Retour Centre</h3>

          <ng-container *ngIf="productsCentre.length > 0; else elseTemplate">

            <!-- <ng-container *ngIf="productsCentre.length === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun produit</h4>
            </ng-container> -->
            <ng-container *ngIf="productsCentre?.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ productsCentre.length}} produit</h4>
            </ng-container>
            <ng-container *ngIf="productsCentre.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ productsCentre.length}} produits</h4>
            </ng-container>
  
            <div *ngIf="(productsCentre.length > 0)">
              <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRetourCentre" aria-expanded="false" aria-controls="collapseRetourCentre">
                Voir le détail
              </button>

              <br><br>

              <div class="container collapse" id="collapseRetourCentre">                
                <div class="row">
                  <ng-container *ngFor="let product of productsCentre">
                    <div class="mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                      <button type="button" class="btn btn-outline-secondary text-center form-control numFSinput">
                        {{product.barcode}}
                      </button>    
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <h4 class="card-subtitle mb-2 text-muted">Le scan n'a pas encore démarré.</h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <br>
</ng-container>

<ng-container *ngIf="isAdmin && productsFranchise && productsCentre">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h3>Différences</h3>
          <h4>Scanné par le Franchisé mais pas scanné par le Centre</h4>

          <ng-container *ngIf="inFranchiseNotInCentre; else elseDiff1Template">
            <ng-container *ngIf="inFranchiseNotInCentre?.length === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun produit</h4>
            </ng-container>
            <ng-container *ngIf="inFranchiseNotInCentre?.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ inFranchiseNotInCentre.length}} produit</h4>
            </ng-container>
            <ng-container *ngIf="inFranchiseNotInCentre.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ inFranchiseNotInCentre.length}} produits</h4>
            </ng-container>
  
            <div *ngIf="(inFranchiseNotInCentre.length > 0)">
              <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInFranchiseNotInCentre" aria-expanded="false" aria-controls="collapseInFranchiseNotInCentre">
                Voir le détail
              </button>

              <br><br>

              <div class="container collapse" id="collapseInFranchiseNotInCentre">                
                <div class="row">
                  <ng-container *ngFor="let barcode of inFranchiseNotInCentre">
                    <div class="mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                      <button type="button" class="btn btn-outline-secondary text-center form-control numFSinput">
                        {{barcode}}
                      </button>    
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseDiff1Template>
            <h4 class="card-subtitle mb-2 text-muted">-</h4>
          </ng-template>

          <br>

          <h4>Scanné par le Centre mais pas scanné par le Franchisé</h4>

          <ng-container *ngIf="inCentreNotInFranchise; else elseDiff2Template">
            <ng-container *ngIf="inCentreNotInFranchise?.length === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun produit</h4>
            </ng-container>
            <ng-container *ngIf="inCentreNotInFranchise?.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ inCentreNotInFranchise.length}} produit</h4>
            </ng-container>
            <ng-container *ngIf="inCentreNotInFranchise.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ inCentreNotInFranchise.length}} produits</h4>
            </ng-container>
  
            <div *ngIf="(inCentreNotInFranchise.length > 0)">
              <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInCentreNotInFranchise" aria-expanded="false" aria-controls="collapseInCentreNotInFranchise">
                Voir le détail
              </button>

              <br><br>

              <div class="container collapse" id="collapseInCentreNotInFranchise">                
                <div class="row">
                  <ng-container *ngFor="let barcode of inCentreNotInFranchise">
                    <div class="mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">
                      <button type="button" class="btn btn-outline-secondary text-center form-control numFSinput">
                        {{barcode}}
                      </button>    
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseDiff2Template>
            <h4 class="card-subtitle mb-2 text-muted">-</h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<canvas id="barcode" style="visibility:hidden; display: none;"></canvas>



<!--<br>

<ng-container *ngIf="isFranchise || isAdmin">
  <div class="row">
    <div class="col">    
      <div class="card">
        <div class="card-body">
          <h3>Retour Franchisé</h3>

          <ng-container *ngIf="retourFranchise; else elseTemplate">
            <ng-container *ngIf="retourFranchise.cartons?.length === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun carton</h4>
            </ng-container>
            <ng-container *ngIf="retourFranchise.cartons?.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ retourFranchise.cartons.length}} carton</h4>
            </ng-container>
            <ng-container *ngIf="retourFranchise.cartons?.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ retourFranchise.cartons.length}} cartons</h4>
            </ng-container>
  
            <div *ngIf="(retourFranchise.cartons?.length > 0)">
              <br>

              <div class="fs-group row">
                <div *ngFor="let carton of retourFranchise.cartons" class="input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">

                  <ng-template #popoverTitle>
                    <ng-container *ngIf="carton.products?.length <= 1">
                      {{carton.products.length}} {{carton.type | cartonType}}
                    </ng-container>
                    <ng-container *ngIf="carton.products?.length > 1">
                      {{carton.products.length}} {{carton.type | cartonType}}s
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #listProducts>
                    <div class="container">
                      <div class="row">
                        <ng-container *ngFor="let product of carton.products; let i = index;">
                          <div class="col" *ngIf="i%2 == 0">
                            {{product.barcode}}<br>
                          </div>
                          <div class="col" *ngIf="i%2 != 0">
                            {{product.barcode}}<br>
                          </div>
                        </ng-container>
                      </div>

                      <hr>

                      <div class="popover-footer">
                        <i><b>{{carton.creationDate * 1000 | date:'dd MMMM y à HH:mm'}}</b></i>
                      </div>
                    </div>
                  </ng-template>

                  <button type="button" class="btn btn-lg btn-outline-secondary text-center form-control numFSinput" popoverClass="popover-products" ngbPopover="listProducts" popoverTitle="popoverTitle" placement="bottom">
                    {{carton.serialNumber | cartonBarcode: retour.provider.id}}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <h4 class="card-subtitle mb-2 text-muted">Le scan n'a pas encore démarré.</h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <br>
</ng-container>

<ng-container *ngIf="isCentre || isAdmin">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h3>Retour Centre</h3>

          <ng-container *ngIf="retourCentre; else elseTemplate">
            <ng-container *ngIf="retourCentre.cartons?.length === 0">
              <h4 class="card-subtitle mb-2 text-muted">Aucun carton</h4>
            </ng-container>
            <ng-container *ngIf="retourCentre.cartons?.length === 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ retourCentre.cartons.length}} carton</h4>
            </ng-container>
            <ng-container *ngIf="retourCentre.cartons?.length > 1">
              <h4 class="card-subtitle mb-2 text-muted">{{ retourCentre.cartons.length}} cartons</h4>
            </ng-container>
  
            <div *ngIf="(retourCentre.cartons?.length > 0)">
              <br>

              <div class="fs-group row">
                <div *ngFor="let carton of retourCentre.cartons" class="input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">

                  <ng-template #popoverTitle>
                    <ng-container *ngIf="carton.products?.length <= 1">
                      {{carton.products.length}} {{carton.type | cartonType}}
                    </ng-container>
                    <ng-container *ngIf="carton.products?.length > 1">
                      {{carton.products.length}} {{carton.type | cartonType}}s
                    </ng-container>
                  </ng-template>
                  
                  <ng-template #listProducts>
                    <div class="container">
                      <div class="row">
                        <ng-container *ngFor="let product of carton.products; let i = index;">
                          <div class="col" *ngIf="i%2 == 0">
                            {{product.barcode}}<br>
                          </div>
                          <div class="col" *ngIf="i%2 != 0">
                            {{product.barcode}}<br>
                          </div>
                        </ng-container>
                      </div>

                      <hr>

                      <div class="popover-footer">
                        <i><b>{{carton.creationDate * 1000 | date:'dd MMMM y à HH:mm'}}</b></i>
                      </div>
                    </div>
                  </ng-template>

                  <button type="button" class="btn btn-lg btn-outline-secondary text-center form-control numFSinput" popoverClass="popover-products" ngbPopover="listProducts" popoverTitle="popoverTitle" placement="bottom">
                    {{carton.serialNumber | cartonBarcode: retour.provider.id}}
                  </button>                
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <h4 class="card-subtitle mb-2 text-muted">Le scan n'a pas encore démarré.</h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
-->