import {AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LivraisonService } from '../services/livraison.service';
import { ProviderService } from '../services/provider.service';
import { FranchiseService } from '../services/franchise.service';
import { PaletteService } from '../services/palette.service';
import { Franchise } from '../model/franchise';
import { Address } from '../model/address';
import { LivraisonBarcodePipe } from '../pipes/livraison-barcode.pipe';
import { PaletteBarcodePipe } from '../pipes/palette-barcode.pipe';
import { CartonBarcodePipe } from '../pipes/carton-barcode.pipe';
import { PaletteModelePipe } from '../pipes/palette-modele.pipe';
import * as JsBarcode from 'jsbarcode';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ScanPalettesComponent } from '../scan-palettes/scan-palettes.component';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-livraison-details',
  templateUrl: './livraison-details.component.html',
  styleUrls: ['./livraison-details.component.css'],
  providers: [ScanPalettesComponent]
})
export class LivraisonDetailsComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  dateAjoutModelesPalette: string = '2020-10-21 12:18:59';

  searchDate: string = '';

  livraison: any;
  livraisonStock: any;
  lastLivraison: any;
  originalProvider: any;
  providers: any;
  franchises: any;
  palettes: any;
  cartons: any;
  products: any;
  paletteModeles: any;
  orderPalettes: any;
  adresses: any;
  adresse: any;

  activeAdresses: any[] = [];

  documents: any;
  allDocuments: any;

  error: any;
  errorHandled: any;

  isLivraisonReceived: boolean = false;
  isLivraisonPalettesOrderedReceived: boolean = false;
  isPalettesReceived: boolean = false;
  isCartonsReceived: boolean = false;
  isProductsReceived: boolean = false;

  detailLivraisonPdfBase64: any;
  bonLivraisonPdfBase64: any;
  bonTransportPdfBase64: any;
  bonCG: any;
  blobDetailLivraison: any;
  blobBonLivraison: any;
  blobBonTransport: any;
  blobCG: any;

  textInfoDevisTransportModal: string = "";
  textInfoCancelLivraisonModal: string = "";

  constructor(private livraisonService: LivraisonService,
              private paletteService: PaletteService,
              private providerService: ProviderService,
              private franchiseService: FranchiseService,
              private activatedRoute: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private modalService: NgbModal,
              private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];
    this.loadLivraison(id);
  }

  ngAfterViewChecked(): void {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.ref.detectChanges();
  }

  loadLivraison(id: number) {

    this.livraisonService.getLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('livraison loaded!')
        }
      );
  }

  /*loadLivraisonStock(id) {
    // console.log('loadLivraisonStock(' + id + ')');

    this.stocksService.getLivraisonStock(this.livraison.id, this.token)
      .subscribe(
        data => {
          this.handleLivraisonStockServerResponse(data);
        },
        error => {
          this.handleLivraisonStockError(error);
        }
    );
  }*/

  loadCheckedDocuments(id: number) {

    this.livraisonService.getCheckedDocuments(id, this.token)
      .subscribe(
        {
          next: data => this.handleCheckedDocumentsServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('checked document loaded!')
        }
      );
  }

  loadLastLivraison(franchise: any, searchDate: string) {

    this.livraisonService.searchLastLivraison(franchise, searchDate, this.token)
      .subscribe(
        {
          next: data => this.handleLastLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('last livraison loaded!')
        }
      );
  }

  loadPalettesFromLivraison(id: number) {
    this.livraisonService.getPalettesFromLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handlePalettesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palettes livraison loaded!')
        }
      );
  }

  loadCartonsFromPalette(id: number) {
    this.paletteService.getCartonsFromPalette(id, this.token)
      .subscribe(
        {
          next: data => this.handleCartonsServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('cartons palette loaded!')
        }
      )
  }

  loadCartonsFromLivraison(id: number) {
    this.livraisonService.getCartonsFromLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleCartonsServerResponse(data), 
          error: err => this.handleError(err),
          complete: () => console.log('cartons livraison loaded!')
        }
      );
  }

  loadProductsFromLivraison(id: number) {
    this.livraisonService.getProductsFromLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleProductsServerResponse(data), 
          error: err => this.handleError(err),
          complete: () => console.log('products livraison loaded!')
        }
      );
  }

  loadAllProviders() {
    this.providerService.getProviders(this.token)
      .subscribe(
        {
          next: data => this.handleProviderServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('providers loaded!')
        }
      );
  }

  loadAllFranchises() {
    this.franchiseService.getFranchises(this.token)
      .subscribe(
        {
          next: data => this.handleFranchisesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchises loaded!')
        }
      );
  }

  loadFranchise(id: number) {
    this.franchiseService.getFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchise loaded!')
        }
      );
  }

  loadLivraisonAdresse(id_address: number, id_franchise: number) {
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAddress(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  loadFacturationAdresse(id_address: number, id_franchise: number) {
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAddress(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  loadFranchiseAdresses(id: number) {
    this.franchiseService.getAllAdressesLivraisonByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAdressesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchise adresses loaded!') 
        }
      )
  }

  loadAllPaletteModeles() {
    this.paletteService.getPaletteModeles(this.token)
      .subscribe(
        {
          next: data => this.handlePaletteModelesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette model loaded!')
        }
      );
  }

  loadDetailLivraison(barcode: string) {
    this.livraisonService.getDocument(this.livraison.franchise.initiales, barcode, 0, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('document details loaded!')
      }
    );
  }

  loadLivraisonPalettesOrdered(id: number) {
    this.livraisonService.getLivraisonPalettesOrdered(id, this.token).subscribe(
      {
        next: data => this.handleLivraisonPalettesOrderedServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('palettes ordered livraison loaded!')
      }
    );
  }

  loadBonLivraison(barcode: string) {
    this.livraisonService.getDocument(this.livraison.franchise.initiales, barcode, 1, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('document livraison loaded!')
      }
    );
  }

  loadBonTransport(barcode: string) {
    this.livraisonService.getDocument(this.livraison.franchise.initiales, barcode, 2, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('document livraison loaded!')
      }
    );
  }

  loadCG(barcode: string) {
    this.livraisonService.getDocument(this.livraison.franchise.initiales, barcode, 3, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('document cg loaded!')
      }
    )
  }

  putOrderPalettes(data: any) {
    this.livraisonService.putOrderPalettes(this.livraison.id, data, this.token)
      .subscribe(
        {
          next: data => this.handlePutOrderPalettesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('order palettes loaded!')
        }
      );
  }

  deleteOrderPalettes(modelePalette: any) {
    this.livraisonService.deleteOrderPalettes(this.livraison.id, modelePalette, this.token)
      .subscribe(
        {
          next: data => this.handleDeleteOrderPalettesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('delete order palette loaded!')
        }
      );
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  selectDocument(event: any) {

    let typeDoc = event.target.id.replace('inlineCheckbox-','');
    this.documents[typeDoc] = event.currentTarget.checked;

    this.livraisonService.updateCheckedDocumentsLivraison(this.livraison.id, this.documents, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('document was updated!')
        }
      );
  }

  handleLivraisonServerResponse(response: any = {}) {
    console.log('response', response)
    if (response.success) {
      if (response.livraison) {
        this.livraison = response.livraison;
        console.log("this livraison", this.livraison)
        this.livraison.barcode = new LivraisonBarcodePipe()
          .transform(this.livraison.serialNumber, this.livraison.provider.id);

        this.palettes = []; // On force l'initialisation des palettes à 0.;

        this.originalProvider = Object.assign({}, this.livraison.provider);

        if (!this.livraison.franchise) {
          this.livraison.franchise = <Franchise>{
            id: 0,
            initiales: ''
          };
        } 
        if (!this.livraison.address) {
          this.livraison.address = <Address>{
            id: 0,
          }
          console.log('no address', this.livraison.address)
          this.loadFranchiseAdresses(this.livraison.franchise.id)
          console.log('apres call no adress')
        }

        this.isLivraisonReceived = true;

        /*if (this.livraison.franchise) {
          this.loadFranchise(this.livraison.franchise.initiales);
        }*/

        if (this.isAdmin) {
          this.loadCheckedDocuments(this.livraison.id);
        }

        if (this.livraison.status === 0) {
          if (this.providers === undefined) {
            this.loadAllProviders();
          }

          if (this.franchises === undefined) {
            this.loadAllFranchises();
          }

          if (this.paletteModeles === undefined) {
            this.loadAllPaletteModeles();
          }
        }

        this.loadLivraisonPalettesOrdered(this.livraison.id);

        this.searchDate = (this.livraison.shipmentDate === new Date().toISOString() ? new Date().toISOString() : new Date(this.livraison.shipmentDate).toISOString());

        this.loadPalettesFromLivraison(this.livraison.id);
        this.loadCartonsFromLivraison(this.livraison.id);
        this.loadProductsFromLivraison(this.livraison.id);

        if (this.livraison.franchise && this.livraison.franchise.id > 0) {
          this.loadLastLivraison(this.livraison.franchise.initiales, this.searchDate);
        }
      }
      console.log('LIVRAISON?', this.livraison)

      return this.livraison
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleLivraisonPalettesOrderedServerResponse(response: any = {}) {
    if (response.success) {
      if (response.orderPalettes) {
        if (this.livraison) {
          this.orderPalettes = response.orderPalettes;
          this.isLivraisonPalettesOrderedReceived = true;

          if (this.livraison.creationDate > this.dateAjoutModelesPalette) {
            this.recalculateNbPalettes();
          }
          /*if (this.livraison.status >= 1
            && this.livraison.status !== 6
            && this.isLivraisonReceived
            && this.isLivraisonPalettesOrderedReceived) {
              this.loadBonLivraison();
          }*/
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleLivraisonStockServerResponse(response: any = {}) {

    if (response.success) {
      if (response.livraisonStock) {
        this.livraisonStock = response.livraisonStock;
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleCheckedDocumentsServerResponse(response: any = {}) {
    if (response.success) {
      this.documents = response.documents;
      this.allDocuments = Object.keys(this.documents);
    } else {
      console.error(response.message);

      if (!response.documents) {

        this.livraisonService.createCheckedDocuments(this.livraison.id, this.token).subscribe(
          {
            next: () => this.loadCheckedDocuments(this.livraison.id),
            error: err => this.handleError(err),
            complete: () => console.log('checked document was created!')
          }
        );
      }
    }
  }

  handleLastLivraisonServerResponse(response: any = {}) {
    if (response.success) {
      this.lastLivraison = response.livraison;
    } else {
      console.error(response.message);
    }
  }

  handlePalettesServerResponse(response: any = {}) {
    if (response.success) {

      if (response.palettes) {
        if (this.livraison) {

          this.livraison.barcode = new LivraisonBarcodePipe()
            .transform(this.livraison.serialNumber, this.livraison.provider.id);

          this.palettes = response.palettes.sort((a: { id: number; }, b: { id: number; }) => {
            return a.id - b.id;
          });

          console.log('this.livraison', this.livraison);
          this.isPalettesReceived = true;

          this.livraison.palettes = this.palettes

          this.checkRemakeData();
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleCartonsServerResponse(response: any = {}) {
    console.log("CARTONS", response)
    if (response.success) {
      if (response.cartons) {
        this.cartons = response.cartons.sort((a: { id: number; }, b: { id: number; }) => {
          return a.id - b.id;
        });

        this.isCartonsReceived = true;

        this.checkRemakeData();
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProductsServerResponse(response: any = {}) {
    if (response.success) {
      if (response.products) {
        this.products = response.products.sort((a: { id: number; }, b: { id: number; }) => {
          return a.id - b.id;
        });

        this.isProductsReceived = true;

        this.checkRemakeData();
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleUpdateLivraisonServerResponse(response: any = {}) {

    if (response.success) {
      if (response.livraison) {
        this.livraison = response.livraison;
        this.livraison.palettes = this.palettes;
        console.log('livraison updated', this.livraison)
        this.loadCartonsFromLivraison(this.livraison.id)
        this.livraison.barcode = new LivraisonBarcodePipe()
          .transform(this.livraison.serialNumber, this.livraison.provider.id);

        this.originalProvider = Object.assign({}, this.livraison.provider);
        //this.generatePdfBonLivraison(this.livraison)
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProviderServerResponse(response: any = {}) {
    if (response.success) {
      this.providers = response.providers.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
      console.log("providers", this.providers)
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchisesServerResponse(response: any = {}) {
    if (response.success) {
      this.franchises = response.franchises.sort((a: { initiales: number; }, b: { initiales: number; }) => {
        return a.initiales - b.initiales;
      });
      console.log('this franchises', this.franchises)

      // this.franchises.push(<Franchise>{
      //   id: 0,
      //   initiales: ''
      // });
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchiseAddress(response: any = {}) {
    console.log('response handle franchise address', response)
    if(response.success) {
      console.log('response livraison address', response.address[0])
      this.livraison.address = response.address
    }
  }

  handleFranchiseServerResponse(response: any = {}) {
    console.log('RESPONSE', response)
    console.log('response.franchise', response.franchise)
    if (response.success) {
      this.loadFranchiseAdresses(response.franchise.franchiseId);
      this.livraison.franchise = response.franchise;
      this.livraison.franchise.livraison19T = response.franchise.livraison19T;
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchiseAdressesServerResponse(response: any = {}) {
    this.adresses = response.adresses;

    for(let i = 0; i <= this.adresses.length - 1; i++) {
      if(this.adresses[i].isActif === 1) {
        this.activeAdresses.push(this.adresses[i])
      }
    } 
    console.log('addresses', this.adresses)
  }

  handlePaletteModelesServerResponse(response: any = {}) {

    if (response.success) {
      this.paletteModeles = response.paletteModeles.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
      console.log('this.paletteModeles', this.paletteModeles)

    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handlePutOrderPalettesServerResponse(response: any = {}) {

    if (response.success) {
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleDeleteOrderPalettesServerResponse(response: any = {}) {

    if (response.success) {
      this.recalculateNbPalettes();
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleGetFileServerResponse(response: any = {}) {
    console.log("RESPONSE HANDLE FILE pls", response)
    if (!response.success) { // Les fichiers n'existent pas, on génère les PDF
      switch (response.type) {
        case 0 :
          this.generatePdfDetailLivraison();
          break;
        case 1 :
          this.generatePdfBonLivraison(this.livraison);
          break;
        case 2 :
          this.generatePdfBonTransport();
          break;
        case 3 :
          this.generateCG();
          break;
        default:
      }
    } else { // Le fichier existe, on ajoute le blob
      if (response.file) {
        const contentType = 'application/pdf';

        switch (response.type) {
          case 0 :
            this.detailLivraisonPdfBase64 = response.file;
            this.blobDetailLivraison = b64toBlob(this.detailLivraisonPdfBase64, contentType);
            break;
          case 1 :
            this.bonLivraisonPdfBase64 = response.file;
            this.blobBonLivraison = b64toBlob(this.bonLivraisonPdfBase64, contentType);
            break;
          case 2 :
            this.bonTransportPdfBase64 = response.file;
            this.blobBonTransport = b64toBlob(this.bonTransportPdfBase64, contentType);
            break;
          case 3 :
            this.bonCG = response.file
            this.blobCG = b64toBlob(this.bonCG, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
            break;
          default:
        }
      }
    }
  }

  handleAddFileServerResponse(response: any = {}) {
    if (response.success) {
      const contentType = 'application/pdf';
      switch (response.type) {
        case 0 :
          this.blobDetailLivraison = b64toBlob(this.detailLivraisonPdfBase64, contentType);
          break;
        case 1 :
          this.blobBonLivraison = b64toBlob(this.bonLivraisonPdfBase64, contentType);
          break;
        case 2 :
          this.blobBonTransport = b64toBlob(this.blobBonTransport, contentType);
          break;
        default:
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleError(error: any = {}) {
    console.error(error);
    this.error = error;
  }

  handleLivraisonStockError(response: any = {}) {
    console.error(response.error.message);

    if (response.error.success) {
      this.livraisonStock = response.error.livraisonStock;
    } else {
      console.error(response);
      this.error = response.error;
    }
  }

  paletteChanged(event: any) {
    var self = this;

    var value = event.target.value;
    var form = event.srcElement.form;
    var elementParent = event.srcElement.parentElement;
    var formBox = elementParent.children;
    var buttonBox = elementParent.parentElement.parentElement.lastChild;

    var buttonMinus = formBox[2].firstChild;
    var nbPaletteInput = formBox[3].firstChild;
    var buttonPlus = formBox[4].firstChild;

    for(var i = 0, len = form.length; i < len; i++) {

      if (form[i].getAttribute('id') === 'addPaletteButton') {

        switch (value) {
          case '1': // Cassettes
          case '2': // Personnalisée
          case '3': // 3 produits [P3P]
          case '4': // 4 produits [P4P]
          case '7': // 3 produits B [P3PB]
          case '8': // Baguettes [PBG]
          case '9': // Canettes [PCN]
          case '10': // Fusettes [PFU]
            buttonMinus.removeAttribute('disabled');
            nbPaletteInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          default:
            buttonMinus.setAttribute('disabled', '');
            nbPaletteInput.value = "0";
            nbPaletteInput.setAttribute('disabled', '');
            buttonPlus.setAttribute('disabled', '');
            buttonBox.style.visibility = 'hidden';
            return 'Inconnu';
        }

        this.getNbPaletteElement(formBox, function(elemNbPalette: any) {
          var nbPalettes = Number(elemNbPalette.value);

          self.toogleValidateButton(value, nbPalettes);
          /*if (Number.isNaN(Number(value)) && Number(elemNbPalette.value) > 0) {
            form[i].setAttribute('disabled', true);
          } else {
            form[i].removeAttribute('disabled');
          }*/
        });
      }
    }
    return;
  }

  nbPaletteInputed(event: any) {
    console.log("nbPaletteInputed event console 1")
    var self = this;

    if (event.data !== null) {
      if (isNaN(event.data)) {
        console.log("nbPaletteInputed event console 2")

        event.stopPropagation();
      }

      var activeFormRow: any = document.getElementById('active-form-row');
    console.log("nbPaletteInputed event console 3")
    console.log('nbPaletteInputed | activeFormRow', activeFormRow);

      var formBox = activeFormRow.children[0].children[0].children;
      console.log('nbPaletteInputed | formBox', formBox);
    console.log("nbPaletteInputed event console 4")

      var elemNbPalette = this.getNbPaletteElementX(formBox, false);
    console.log("nbPaletteInputed event console 5")

      var typePalette = this.getTypePaletteValueX(formBox, false);
    console.log("nbPaletteInputed event console 6")

      this.toogleValidateButton(typePalette, Number(elemNbPalette.value));
    }
  }

  getTypePaletteValueX(activeRow: any, isValidate: boolean) {
    var element = activeRow[1];
    var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
    return modelePaletteValue;
  };

  getTypePaletteValue(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length; i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'inputGroupSelectPalette') {
        var modelePaletteValue = parseInt(element.options[element.selectedIndex].value, 10);
        callback(modelePaletteValue);
      }
    }
  };

  getNbPaletteElementX(activeRow: any, isValidate: any) {
    var element = (isValidate) ? activeRow[2] : activeRow[3];
    var subElement = element.firstChild;
    return subElement;
  }

  getNbPaletteElement(activeRow: any, callback: any) {
    for(var i = 0; i< activeRow.length;i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'nbPalettes') {
        callback(element.children[0]);
      }
    }
  }

  minusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      if (nbPalettes > 0) {
        nbPalettes = Number(nbPalettes) - 1;
        elemNbPalette.value = nbPalettes;
      }

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  plusClicked(event: any) {
    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any) {
      var nbPalettes = elemNbPalette.value;
      nbPalettes = Number(nbPalettes) + 1;
      elemNbPalette.value = nbPalettes;

      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {
        self.toogleValidateButton(modelePaletteValue, nbPalettes);
      });
    });
  }

  toogleValidateButton(modelePaletteValue: any, nbPalettes: number) {
    console.log("toogleValidateButton ")

    var addPaletteButton: any = document.getElementById('addPaletteButton');

    if (!Number.isNaN(Number(modelePaletteValue)) && Number(nbPalettes) > 0) {
      console.log('ACTIVATE BUTTON');
      addPaletteButton.removeAttribute('disabled');
    } else {
      console.log('DISABLE BUTTON');
      addPaletteButton.setAttribute('disabled', '');
    }
  }

  validatePalette(event: any) {
    console.log("event validatePalette")
    var activeRow = event.srcElement.parentElement.parentElement.children[0].children[0].children;
    
    var self = this;

    this.getNbPaletteElement(activeRow, function(elemNbPalette: any = {}) {
      var nbPalettes = elemNbPalette.value;
      self.getTypePaletteValue(activeRow, function(modelePaletteValue: any) {

        var obj = { modelePalette: modelePaletteValue, nbPalettes: Number(nbPalettes) }
        
        var findedObj: any = self.orderPalettes.find((m: { modelePalette: any; }) => m.modelePalette === modelePaletteValue);

        if (typeof findedObj === "undefined") {
          self.orderPalettes.push(obj);
        } else {
          findedObj.nbPalettes += Number(nbPalettes);
        }

        self.putOrderPalettes(obj);
      });
    });
  }

  removePalette(event: any) {
    var parentElementActiveRow = event.srcElement.parentElement.parentElement;
    var activeRow = parentElementActiveRow.children[0].children[0].children;

    var elemNbPalette = this.getNbPaletteElementX(activeRow, true);
    var nbPalettes = elemNbPalette.value;

    var modelePaletteValue = this.getTypePaletteValueX(activeRow, true);

    var findedObj: any = this.orderPalettes.find((m: { modelePalette: number; }) => m.modelePalette === modelePaletteValue);
    findedObj.nbPalettes -= nbPalettes;

    if (findedObj.nbPalettes === 0) {
      this.orderPalettes = this.orderPalettes.filter((m: { modelePalette: number; }) => m.modelePalette !== modelePaletteValue);

      this.deleteOrderPalettes(findedObj.modelePalette);
    }

    parentElementActiveRow.remove();

    /*var dataToPatch = [
      { "op": "remove", "modelePalette": modelePaletteValue }
    ];

    this.patchDataPalette(dataToPatch);*/
  }

  recalculateNbPalettes() {
    this.livraison.nbPalettes = 0
  
    for(let p of this.orderPalettes) {
      this.livraison.nbPalettes += p.nbPalettes;
    }
  }

  checkRemakeData() {

    if (this.livraison) {

      if (this.livraison.status >= 2
        && this.livraison.status !== 6
        && this.isLivraisonReceived
        && this.isPalettesReceived
        && this.isCartonsReceived
        && this.isProductsReceived) {
        this.remakeData();
      }
    }
  }

  remakeData() {
    // console.log('remakeData');
    
    /**
     * SPEED LOOP
     * https://medium.com/@AamuLumi/voyage-to-the-most-efficient-loop-in-nodejs-and-a-bit-js-5961d4524c2e
     */

    let i = 0;
    const iMax = this.products.length;
    for (; i < iMax; i++) {
      const foundIndex = this.cartons.findIndex((x: { id: any; }) => x.id === this.products[i].idCarton);

      if (!this.cartons[foundIndex].hasOwnProperty('products')) {
        this.cartons[foundIndex].products = [];
      }

      this.cartons[foundIndex].barcode = new CartonBarcodePipe()
        .transform(this.cartons[foundIndex].serialNumber, this.cartons[foundIndex].provider.id);
      this.cartons[foundIndex].products.push(this.products[i]);
    }


    let j = 0;
    const jMax = this.cartons.length;
    for (; j < jMax; j++) {

      const foundIndex = this.palettes.findIndex((x: { id: any; }) => x.id === this.cartons[j].idPalette);
      let foundPalette = this.palettes[foundIndex];

      if (typeof foundPalette !== "undefined") {

        if (!foundPalette.hasOwnProperty('cartons')) {
          foundPalette.cartons = [];
        }

        foundPalette.barcode = new PaletteBarcodePipe()
          .transform(foundPalette.serialNumber, foundPalette.provider.id);
          foundPalette.cartons.push(this.cartons[j]);
      }
    }

    this.ref.detectChanges();

    this.loadBonLivraison(this.livraison.barcode);
    this.loadDetailLivraison(this.livraison.barcode);
    this.loadBonTransport(this.livraison.barcode);
    this.loadCG(this.livraison.barcode);
  }

  updateLivraison(event: any) {
    console.log('event', event)
    this.livraison = event.livraison;
    this.palettes = event.palettes;
    //window.document.location.reload();
    this.modifyLivraison()
  }

  onChangeProvider(newProvider: string) {
    const provider = this.providers.find((p: { id: string; }) => parseInt(p.id, 10) === parseInt(newProvider, 10));

    this.livraison.provider = Object.assign({}, provider);
    this.livraison.provider.oldProviderId = this.originalProvider.id;

    this.modifyLivraison();
  }

  onChangeAddress(newAddress: number) {

    const address = this.adresses.find((a: { id: number }) => a.id === newAddress);
    console.log('address', address)
    this.livraison.address = address
    if(this.livraison.address.accessibilite_19T) {
      this.loadLivraisonAdresse(this.livraison.address, this.livraison.franchise.id)
    } else {
      this.loadFacturationAdresse(this.livraison.address, this.livraison.franchise.id)
    }

    if (this.livraison.status === 4) {
      this.modifyAdresseLivraison()
    } else {
      this.modifyLivraison();
    }
  }

  onChangeFranchise(newFranchise: number) {

    this.activeAdresses.length = 0;

    const franchise = this.franchises.find((f: { id: number; }) => f.id === newFranchise);

    this.loadFranchise(this.livraison.franchise.id);

    this.livraison.franchise = franchise;
    this.livraison.franchise.livraison19T = franchise.livraison19T

    if (newFranchise) {
      this.loadLastLivraison(this.livraison.franchise.initiales, this.searchDate);
    } else {
      this.lastLivraison = null;
    }

    this.modifyLivraison();
  }

  modifyAdresseLivraison() {
    this.livraisonService.updateAdresseLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison address was updated!')
        }
      )
  }

  modifyLivraison() {

    console.log('livraison address selected', this.livraison.address);

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );
  }

  validateLivraison(event: any) {
    console.log('livraison validated', this.livraison)
    this.recalculateNbPalettes();
    this.livraison.status = 6;

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );
  }

  saveLivraison(event: any) {
    console.log('livraison saved', this.livraison)
    this.livraison.status = 2;
    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );

    this.paletteService.updatePalettes(this.palettes, this.token)
      .subscribe({
        next: data => this.handleLivraisonServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('palette was updated!')
      }
      );

    //this.generatePdfBonLivraison()
    this.generatePdfDetailLivraison()
    this.generatePdfBonTransport()
    this.generateCG()
  }

  handleSaveLivraisonServerResponse(response: any = {}) {
    if (response.success) {
      window.document.location.reload()
    }
  }

  validateControleLivraison() {
    this.livraison.status = 3;
    this.livraison.validateByASI = true;

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe({
        next: data => this.handleUpdateLivraisonServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('livraison was updated!')
      }
      );
  }

  sendLivraison(input: any) {
    this.livraison.status = 4;

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );

    this.palettes.forEach(function (palette: any) {
      palette.status = 3;
    });

    this.paletteService.updatePalettes(this.palettes, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette was updated!')
        }
      );

    /*this.stocksService.addLivraisonToStock(this.livraison.id, {}, this.token)
      .subscribe(
        data => {
          this.handleLivraisonStockServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );*/
  }

  /*addLivraisonToStock(input: any) {
    this.stocksService.addLivraisonToStock(this.livraison.id, {}, this.token)
      .subscribe(
        data => {
          this.handleLivraisonStockServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );
  }*/

  /*removePalette(event) {
    console.log('removePalette | event', event);

    var parentElementActiveRow = event.srcElement.parentElement.parentElement;
    var activeRow = parentElementActiveRow.children[0].children[0].children;
    
    console.log('removePalette | activeRow', activeRow);

    var elemNbPalette = this.getnbPaletteElementX(activeRow, true);
    var nbPalettes = elemNbPalette.value;
    console.log('removePalette | nbPalettes', nbPalettes);

    var modelePaletteValue = this.getmodelePaletteValueX(activeRow, true);
    console.log('removePalette | modelePaletteValue', modelePaletteValue);
      
    console.log("REMOVE DATA");

    var findedObj = this.orderPalettes.find(m => m.modelePalette === modelePaletteValue);
    findedObj.nbPalettes -= nbPalettes;
    console.log('findedObj', findedObj);
    console.log('this.orderPalettes', this.orderPalettes);

    if (findedObj.nbPalettes === 0) {
      this.orderPalettes = this.orderPalettes.filter(m => m.modelePalette !== modelePaletteValue);
      console.log('this.orderPalettes AFTER REMOVE', this.orderPalettes);

      this.deletePalette(findedObj.modelePalette);
    }

    parentElementActiveRow.remove();
  }*/

  showPdfDetailLivraison() {
    const fileURL = URL.createObjectURL(this.blobDetailLivraison);
    window.open(fileURL, '_blank');
  }

  showPdfBonLivraison() {
    const fileURL = URL.createObjectURL(this.blobBonLivraison);
    window.open(fileURL, '_blank');
  }

  showPdfBonTransport() {
    const fileURL = URL.createObjectURL(this.blobBonTransport);
    window.open(fileURL, '_blank');
  }

  showCG() {
    const fileURL = URL.createObjectURL(this.blobCG);
    window.open(fileURL, '_blank');
  }

  confirmSendDevisTransport(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'devisTransportConfirmModal-title' });
  }

  sendDevisTransport(infoModal: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraisonService.getDevisTransportLivraison(this.livraison.id, token).subscribe({
      next: data => {
        if ((data as any).success) {
          this.textInfoDevisTransportModal = "La demande a bien été envoyée.";
        } else {
          this.textInfoDevisTransportModal = 'Une <span class="text-danger">erreur</span> est survenue.';
        }

        this.modalService.open(infoModal, { ariaLabelledBy: 'devisTransportInfoModal-title' });
      },
      error: err => {
        console.error("ERROR IN FRONT", err);
        this.textInfoDevisTransportModal = 'Une <span class="text-danger">erreur</span> est survenue.';

        this.modalService.open(infoModal, { ariaLabelledBy: 'devisTransportInfoModal-title' });
      }
    });
  }

  confirmValidateDevis(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'validateDevisConfirmModal-title' });
  }

  validateDevis() {
    this.livraison.status = 1;
    console.log('devis validated', this.livraison)

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );
  }

  confirmCancelLivraison(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'cancelLivraisonConfirmModal-title' });
  }

  cancelLivraison(infoModal: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraisonService.deleteLivraison(this.livraison.id, token).subscribe({
      next: (data: any) => {
        if (data.success) {
          this.textInfoCancelLivraisonModal = "La livraison a bien été annulée.";
        } else {
          this.textInfoCancelLivraisonModal = 'Une <span class="text-danger">erreur</span> est survenue'
            + (data.message ? (' :<br>' + data.message) : '.');
        }

        this.modalService.open(infoModal, { ariaLabelledBy: 'cancelLivraisonInfoModal-title' }).result.finally(() => {
          location.reload();
        });
      },
      error: err => {
        console.error("ERROR IN FRONT", err);
        this.textInfoCancelLivraisonModal = 'Une <span class="text-danger">erreur</span> est survenue.';
        this.modalService.open(infoModal, { ariaLabelledBy: 'cancelLivraisonInfoModal-title' });
      }
    });
  }

  generatePdfDetailLivraison() {

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraison.palettes = this.palettes;

    this.livraisonService.createBonDetails(this.livraison, token).subscribe({
      next: data => this.toCheckBonDetailsPdf(data),
      error: err => console.error("ERROR IN FRONT", err),
      complete: () => console.log("details livraison created!")
    })
  }
  
  toCheckBonDetailsPdf(data: any) {
    this.loadDetailLivraison(data.bonDetails.barcode)
  }

  generatePdfBonLivraison(livraison: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraison.palettes = this.palettes;
    //console.log("THIS CARTONS", this.cartons)


    console.log('this.livraison in front before create bon livraison', this.livraison);
    console.log('this.livraison.palettes in front before create bon livraison', this.livraison.palettes);
    console.log('livraison in front before create bon livraison', livraison);

    this.livraisonService.createBonLivraison(livraison, token).subscribe({
      next: data => this.toCheckBonLivraisonPdf(data),
      error: err => console.error("ERROR IN FRONT", err),
      complete: () => console.log("bon livraison created!")
    })
  }
  
  toCheckBonLivraisonPdf(data: any) {
    this.loadBonLivraison(data.bonLivraison.barcode)
  }

  generatePdfBonTransport() {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.livraisonService.createBonTransport(this.livraison, token).subscribe({
      next: data => this.toCheckBonTransportPdf(data),
      error: err => console.error("ERROR IN FRONT", err),
      complete: () => console.log("bon livraison created!")
    })
  }

  toCheckBonTransportPdf(data: any) {
    this.loadBonTransport(data.bonTransport.barcode)
  }

  generateCG() {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.livraisonService.createCG(this.livraison, token).subscribe({
      next: data => this.toCheckCG(data),
      error: err => console.error("ERROR IN FRONT", err),
      complete: () => console.log("cg created!")
    })
  }

  toCheckCG(data: any) {
    console.log("data", data)
    this.loadCG(data.doc.barcode)
  }
}

const b64toBlob = (b64Data: any, contentType: string = '', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
};
