<div *ngIf="retour">

  <main role="main" class="container">
    <h3>Retour {{retour.serialNumber | retourBarcode: retour.provider.id}}</h3>



    Statut : {{retour.status | retourStatus}} <br>

    <!--<div class="row" *ngIf="(livraison.palettes && livraison.palettes?.length > 0) && (cartons && cartons?.length > 0) && (products && products?.length > 0)">
      <app-bordereau-livraison-pdf [token]="token" [livraison]="livraison" [cartons]="cartons" [products]="products"></app-bordereau-livraison-pdf>
    </div>-->
    <!--<app-bordereau-livraison-pdf [token]="token" [livraison]="livraison" [cartons]="cartons" [products]="products"></app-bordereau-livraison-pdf>-->

    <br>

    <ng-container *ngIf="isAdmin">
      <ng-container *ngIf="isRetourReceived && isCartonsReceived && isProductsReceived">
        <div class="d-grid gap-2">
          <button appNoDblClick (click)="showPdfDetailRetour()" class="btn btn-outline-danger btn-lg" type="button">
            <i class="bi bi-printer"></i>
            Imprimer le détail du Retour
          </button>
        </div>
      </ng-container>
    </ng-container>
  </main>

</div>
