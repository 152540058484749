import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { format, getISOWeek, startOfWeek, endOfWeek, eachDayOfInterval, subWeeks, addWeeks } from 'date-fns'
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { AuthService } from '../services/auth.service';
import { StatsService } from '../services/stats.service';
import fr from 'date-fns/locale/fr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  
  token: string = '';
  bcpToken: any = {};
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  provider: any = null;
  userIsLoggedin: boolean = false;

  stats: any = null;
  error: string = '';

  paletteCreated: any = null;
  errorMessage: string = '';

@ViewChild( BaseChartDirective ) chartProducts!: BaseChartDirective;
@ViewChild('nextWeekBtn') nextWeekBtn!: ElementRef;

  public barChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend: boolean = true;
  public barChartColors: String[] = ['rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(153, 102, 255, 0.5)'];
  public barChartData: any;
  public theDate: Date = new Date();
  public actualWeekNumber: Number = getISOWeek(new Date());
  public theWeek: Date[] = [];
  public theWeekISOString: String[] = [];
  public barChartOptions: ChartOptions = {};
  public productsMakedInWeek: [] = [];
  constructor(private authService: AuthService, private statsService: StatsService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      this.bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = this.bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'franchise') {
        this.isFranchise = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'centre') {
        this.isCentre = true;
      }
      this.provider = this.decodedToken.provider;

      if (this.isAdmin) {
        this.loadStats();
      } else if (this.isCentre) {
        this.loadStats();
      }

      this.loadDataChart();
    }
  }
  ngAfterViewInit(): void {
    this.toggleWeekButtons();
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  toggleWeekButtons() {
    // console.log("this.theDate", this.theDate)

    if (getISOWeek(this.theDate) < this.actualWeekNumber) {
      this.nextWeekBtn.nativeElement.style.display='block';
    } else {
      this.nextWeekBtn.nativeElement.style.display='none';
    }
  }

  loadStats() {
    this.statsService.getStatsTotal(this.token)
      .subscribe({
        next: data => this.displayPalettes(data),
        error: err => console.error(err),
        complete: () => console.log("stats are loaded!")
      })
  }

  loadDataChart() {
    this.productsMakedInWeek = [];
    let firstDayOfWeek = startOfWeek(this.theDate, { weekStartsOn: 1 });
    let lastDayOfWeek = endOfWeek(this.theDate, { weekStartsOn: 1 });
    
    this.theWeek = eachDayOfInterval(
      { start: firstDayOfWeek, end: lastDayOfWeek }
    ).slice(0, -2);

    this.barChartData = [
      { data: [0, 0, 0, 0, 0], label: 'Cassettes', backgroundColor: 'rgba(75, 192, 192, 0.5)', hoverBackgroundColor: 'rgba(127, 218, 218, 0.7)', borderColor: 'rgba(75, 192, 192, 1)', hoverBorderColor: "rgba(115, 197, 197, 1)", borderWidth: 1 },
      { data: [0, 0, 0, 0, 0], label: 'Baguettes', backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(80, 172, 233, 0.7)', borderColor: 'rgba(54, 162, 235, 1)', hoverBorderColor: 'rgba(74, 156, 211, 1)', borderWidth: 1 },
      { data: [0, 0, 0, 0, 0], label: 'Canettes', backgroundColor: 'rgba(255, 99, 132, 0.5)', hoverBackgroundColor: 'rgba(255, 115, 145, 0.7)', borderColor: 'rgba(255, 99, 132, 1)', hoverBorderColor: 'rgba(236, 107, 135, 1)', borderWidth: 1 },
      { data: [0, 0, 0, 0, 0], label: 'Fusettes', backgroundColor: 'rgba(153, 102, 255, 0.5)', hoverBackgroundColor: 'rgba(166, 122, 254, 0.7)', borderColor: 'rgba(153, 102, 255, 1)', hoverBorderColor: 'rgba(160, 120, 240, 1)', borderWidth: 1 }
    ];

    this.theWeekISOString = [];
    this.barChartLabels = [];
    this.barChartOptions = {
      responsive: true,
      scales: { x: {}, y: {} },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: `Fabrication de Produits de la semaine ${getISOWeek(this.theDate)}`
        },
        tooltip: {
          backgroundColor: 'rgba(255,255,255,0.9)',
          bodyColor: '#999',
          borderWidth: 1,
          caretPadding: 15,
          displayColors: false,
          enabled: true,
          intersect: true,
          mode: 'x',
          titleColor: '#999',
          titleMarginBottom: 10,
          padding: 15,
          callbacks : {
            title : function() {
              return '***** Fabrication *****';
            },
            beforeLabel: function(context) {
              return `${context.label}`;
            },
            label : function(context) {
              let textBody = `${context.parsed.y} ${context.dataset.label}`;
              return textBody;
            },
            afterLabel : function(context: any) {
              let nbProductByCarton = 1;
              switch (context.dataset.label.toUpperCase()) {
                case 'CASSETTES':
                  nbProductByCarton = 14;
                  break;
                case 'BAGUETTES':
                  nbProductByCarton = 24;
                  break;
                case 'CANETTES':
                  nbProductByCarton = 24;
                  break;
                case 'FUSETTES':
                  nbProductByCarton = 42;
                  break;
          
                default:
                  break;
              }
              let nbCartons = parseInt(context.formattedValue, 10)/nbProductByCarton;
              let nbCartonsStr = (nbCartons > 1) ? 'cartons' : 'carton';
              let textBody = `soit ${nbCartons} ${nbCartonsStr}.`;
              
              return textBody;
            },
          }
        }
      }
    };

    for (let d = 0; d < this.theWeek.length; d++) {
      const searchDate = this.theWeek[d];
			console.log('searchDate.toISOString()', searchDate.toISOString());
      this.theWeekISOString.push(searchDate.toISOString());
      this.barChartLabels.push(format(searchDate, 'eeee', {locale: fr})+" "+format(searchDate, "dd/MM/yyyy"));
      
      this.loadCountProductsMakedCombined(searchDate.toISOString());
      this.loadCountCartonsMaked(searchDate.toISOString());
    }
  }

  /*loadCountProductsMaked(typeProduct: any, searchDate: any) {
    this.statsService.getCountProductsMaked(typeProduct, searchDate, this.token)
      .subscribe({
        next: data => this.handleCountProductsMaked(data),
        error: err => console.error(err),
        complete: () => console.log("productsmaked are loaded!")
      });
  }*/

  loadCountProductsMakedCombined(searchDate: string) {    
    this.statsService.getCountProductsMakedCombined(searchDate, this.token)
      .subscribe({
        next: data => this.handleCountProductsMakedCombined(data),
        error: err => console.error(err),
        complete: () => console.log("products maked combined loaded!")
      });
  }

  loadCountCartonsMaked(searchDate: string) {
    this.statsService.getCountCartonsMaked(searchDate, this.token)
      .subscribe({
        next: data => this.handleCountCartonsMaked(data),
        error: err => console.error(err),
        complete: () => console.log('cartons maked loaded!')
      });
  }

  displayPalettes(response: any) {
    if (response.success) {
      this.stats = response.stats;
    } else {
      this.errorMessage = response.message;
    }
  }

  /*handleCountProductsMaked(response: any = {}) {

    if (response.success) {
      const searchDate = response.searchDate;
      const searchDayIndex = this.theWeekISOString.indexOf(searchDate);
      const typeProduct = response.typeProduct;
      const nbProductsMaked = response.nbProductsMaked;

      if (nbProductsMaked > 0) {

        switch (typeProduct) {
          case 'G':
          case 'K':
            let barChartDataCassettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Cassettes')[0].data;
            barChartDataCassettes[searchDayIndex] = nbProductsMaked;
            break;
          case 'B':
            let barChartDataBaguettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Baguettes')[0].data;
            barChartDataBaguettes[searchDayIndex] = nbProductsMaked;
            break;
          case 'C':
            let barChartDataCanettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Canettes')[0].data;
            barChartDataCanettes[searchDayIndex] = nbProductsMaked;
            break;
          case 'T':
          case 'F':
            let barChartDataFusettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Fusettes')[0].data;
            barChartDataFusettes[searchDayIndex] = nbProductsMaked;
            break;
        
          default:
            break;
        }

        this.chartProducts.ngOnChanges({});
      }
      
      for (let index = 0; index < this.barChartData.length; index++) {
        const barChart = this.barChartData[index].data;
        console.log('barChart['+index+']', barChart);
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }*/

  handleCountProductsMakedCombined(response: any = {}) {

    if (response.success) {
      const searchDate = response.searchDate;
      const searchDayIndex = this.theWeekISOString.indexOf(searchDate);
      const productsMaked = response.productsMaked;

      let barChartDataBaguettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Baguettes')[0].data;
      barChartDataBaguettes[searchDayIndex] = productsMaked.nbBaguettes;
      
      let barChartDataCanettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Canettes')[0].data;
      barChartDataCanettes[searchDayIndex] = productsMaked.nbCanettes;

      let barChartDataCassettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Cassettes')[0].data;
      barChartDataCassettes[searchDayIndex] = productsMaked.nbCassettes;
      
      let barChartDataFusettes = this.barChartData.filter((d: { label: string; }) => d.label === 'Fusettes')[0].data;
      barChartDataFusettes[searchDayIndex] = productsMaked.nbFusettes;

      this.chartProducts.ngOnChanges({});
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleCountCartonsMaked(response: any = {}) {

    if (response.success) {
      const searchDate = response.searchDate;
      const searchDayIndex = this.theWeekISOString.indexOf(searchDate);
      const typeProduct = response.typeProduct;
      const nbProductsMaked = response.nbProductsMaked;
      const nbCartonsMaked = response.nbCartonsMaked;
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }


  login(credentials: any) {
    this.authService.login(credentials)
     .subscribe({
        next: data => this.handleLoginSuccess(data),
        error: err => this.handleLoginFailure(err),
        complete: () => console.log('logged!')
      })
  }

  handleLoginSuccess(data: {}) {
    this.bcpToken = data;
    localStorage.setItem('bcp-token', JSON.stringify(data));

    location.reload();
  }

  handleLoginFailure(error: any) {
    console.error('failure: ', error);
  }

  previousWeek(input: any) {
    this.theDate = subWeeks(this.theDate, 1);
    this.toggleWeekButtons();
    this.loadDataChart();
  }

  nextWeek(input: any) {
    this.theDate = addWeeks(this.theDate, 1);
    this.toggleWeekButtons();
    this.loadDataChart();
  }
}
