import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {RetourService} from '../services/retour.service';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-retours-history',
  templateUrl: './retours-history.component.html',
  styleUrls: ['./retours-history.component.css']
})
export class RetoursHistoryComponent implements OnInit, OnDestroy {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: any = null;
  retours: any = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  retourCreated: any = null;
  errorMessage: string = '';
  parametersObservable: any;
  source: string = '';

  currentPage: number = 1;
  pageCount: number = 1;
  pages: number[] = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private retourService: RetourService,
    private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;

      this.parametersObservable = this.activatedRoute.params.subscribe((params = {}) => {
        console.log(params['source']);

        this.source = params['source'];

        // admin needs to see all ads
        if (this.isAdmin) {
          this.loadRetours();
        } else {
          this.loadRetours();
        }

        this.retourService.retoursSubject.subscribe(data => {
          this.retours = [data, ...this.retours];
        });
      });
    }
  }

  // Don't forget to unsubscribe from the Observable
  ngOnDestroy() {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadRetours(page = 1) {
    this.retourService.getRetoursHistory(page, this.token)
      .subscribe({
        next: (data) => this.displayRetours(data),
        error: (err) => console.error(err),
        complete: () => console.log("retours are loaded!")
      }
        // data => this.displayRetours(data),
        // err => console.error(err)
      );
  }

  displayRetours(response: any = {}) {
    if (response.success) {
      this.currentPage = response.currentPage;
      this.pageCount = response.pageCount;
      this.retours = response.retours;
      console.log("THIS RETOURS", this.retours)

      const pages = new Array(this.pageCount);
      for (let i = 1; i <= this.pageCount; i++) {
        pages[i - 1] = i;
      }

      this.pages = pages;
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }
}
