import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getStatsTotal(token: string) {
    // console.log('getStatsTotal');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/stats', { headers });
  }

  getCountProductsMaked(typeProduct: any, searchDate: string, token: string) {
    // console.log('getCountProductsMaked('+typeProduct+', '+searchDate+')');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/stats/products/${typeProduct}/${searchDate}`, { headers });
  }

  getCountCartonsMaked(searchDate: string, token: string) {
    // console.log('getCountCartonsMaked('+searchDate+')');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/stats/cartons/${searchDate}`, { headers });
  }

  getCountProductsMakedCombined(searchDate: string, token: string) {
    // console.log('getCountProductsMakedCombined('+searchDate+')');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/stats/products/${searchDate}`, { headers });
  }

  getCountProductsMakedCombinedBetweenDates(fromDate: string, toDate: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/products?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('getCountProductsMakedCombinedBetweenDates | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }

	getCountProductsMakedBetweenDates(fromDate: string, toDate: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/products?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('getCountProductsMakedBetweenDates | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }

  getCountCartonsMakedBetweenDates(fromDate: string, toDate: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/cartons?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('getCountCartonsMakedBetweenDates | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }

  getCountPalettesMakedBetweenDates(fromDate: string, toDate: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/palettes?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('getCountPalettesMakedBetweenDates | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }

	getCountProductsRetoursBetweenDates(fromDate: string, toDate: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/products-retours?fromDate=${fromDate}&toDate=${toDate}`;
    // console.log('getCountProductsRetoursBetweenDates | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }








	getYearsActivity(token: string) {
		const url = environment.BASE_URL + `/api/stats/years-activity`;
    // console.log('getYearsActivity | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }

	getActivityFromYear(year: number, typeFiltre: string, token: string) {
		const url = environment.BASE_URL + `/api/stats/years-activity/${year}?typeFiltre=${typeFiltre}`;
    // console.log('getActivityFromYear | url', url);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(url, { headers });
  }
}
