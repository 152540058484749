import { NgModule, LOCALE_ID} from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CartonAddFormComponent } from './carton-add-form/carton-add-form.component';
import { CartonDetailsComponent } from './carton-details/carton-details.component';
import { CartonListComponent } from './carton-list/carton-list.component';
import { HomeComponent } from './home/home.component';
import { LivraisonDetailsComponent } from './livraison-details/livraison-details.component';
import { LivraisonListComponent } from './livraison-list/livraison-list.component';
import { PaletteAddFormComponent } from './palette-add-form/palette-add-form.component';
import { PaletteDetailsComponent } from './palette-details/palette-details.component';
import { PaletteListComponent } from './palette-list/palette-list.component';
import { LivraisonsHistoryComponent } from './livraisons-history/livraisons-history.component';
import { LivraisonsAdressesListComponent } from './livraisons-adresses-list/livraisons-adresses-list.component';
import { LivraisonAsiListComponent } from './livraison-asi-list/livraison-asi-list.component';
import { LivraisonAsiDetailsComponent } from './livraison-asi-details/livraison-asi-details.component';
import { LivraisonsAsiHistoryComponent } from './livraisons-asi-history/livraisons-asi-history.component';
import { RetourListComponent } from './retour-list/retour-list.component';
import { RetourDetailsComponent } from './retour-details/retour-details.component';
import { ScanPalettesComponent } from './scan-palettes/scan-palettes.component';
import { FranchisesListComponent } from './franchises-list/franchises-list.component';
import { FranchisesDetailsComponent } from './franchises-details/franchises-details.component';
import { DemandesLivraisonListComponent } from './demandes-livraison-list/demandes-livraison-list.component';
import { DemandesLivraisonDetailsComponent } from './demandes-livraison-details/demandes-livraison-details.component';
import { RetoursHistoryComponent } from './retours-history/retours-history.component';
import { StatsComponent } from './stats/stats.component';
import { StatsBetweenDatesComponent } from './stats-between-dates/stats-between-dates.component';
import { StatsYearComponent } from './stats-year/stats-year.component';
import { FranchisesInactifsListComponent } from './franchises-inactifs-list/franchises-inactifs-list.component';
import { FranchiseLivraisonsHistoryComponent } from './franchise-livraisons-history/franchise-livraisons-history.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cartons', component: CartonListComponent },
  { path: 'carton/:id', component: CartonDetailsComponent },
  { path: 'carton/add', component: CartonAddFormComponent },
  { path: 'palettes', component: PaletteListComponent },
  { path: 'palette/:id', component: PaletteDetailsComponent },
  { path: 'palette/add', component: PaletteAddFormComponent },
  { path: 'livraisons', component: LivraisonListComponent },
  { path: 'livraison/:id', component: LivraisonDetailsComponent },
  { path: 'livraisons/historique', component: LivraisonsHistoryComponent },
  { path: 'livraisons/adresses', component: LivraisonsAdressesListComponent },
  { path: 'livraisons-asi', component: LivraisonAsiListComponent },
  { path: 'livraison-asi/:id', component: LivraisonAsiDetailsComponent },
  { path: 'livraisons-asi/historique', component: LivraisonsAsiHistoryComponent },
  { path: 'demandes-livraisons', component: DemandesLivraisonListComponent },
  { path: 'demande-livraison/:id', component: DemandesLivraisonDetailsComponent },
  { path: 'retours', component: RetourListComponent },
  { path: 'retours/historique', component: RetoursHistoryComponent },
  { path: 'retour/:id', component: RetourDetailsComponent },
  { path: 'franchises', component: FranchisesListComponent},
  { path: 'franchises/inactifs', component: FranchisesInactifsListComponent},
  { path: 'franchise/:id', component: FranchisesDetailsComponent},
  { path: 'franchise/:id/livraisons', component: FranchiseLivraisonsHistoryComponent},
  { path: 'stats', component: StatsComponent},
  { path: 'stats/between-dates', component: StatsBetweenDatesComponent},
  { path: 'stats/year', component: StatsYearComponent}
];

registerLocaleData(localeFr);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    ScanPalettesComponent,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: APP_BASE_HREF, useValue: '/' }
  ]
})
export class AppRoutingModule { }
