<div class="row">
  <div class="col-6">
    <h2>Création d'un franchisé et de sa société</h2>
  </div>
  <div class="col-6">
    <div class="form-group mb-3">
      <label for="formFile" class="form-label">Importer un franchisé et sa société</label>
      <input class="form-control" type="file"
          id="file"
          (change)="handleFileInput($any($event).target.files)">
    </div>
  </div>
</div>
<div *ngIf="this.fmessage" class="alert alert-success alert-dismissible fade show" role="alert">
  {{this.fmessage}}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
<form #createForm="ngForm" (ngSubmit)="validateFranchiseSocieteForm(formFranchise.value, formSociete.value)">
  <div class="accordion mb-3" id="accordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
          Informations du franchisé
					<i *ngIf="franchiseCreated" class="bi bi-check-lg ms-3"></i>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <form #formFranchise="ngForm" (ngSubmit)="validateFranchise(formFranchise.value)">
            <div class="form-group">
              <div class="row">						
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="nom" autocomplete="off" class="form-control" type="text" name="nom" placeholder="Nom du franchisé" ngModel required [disabled]="this.franchiseCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="nom" autocomplete="off" class="form-control" type="text" name="nom" placeholder="Nom du franchisé" [(ngModel)]="toCreateFranchise.franchise.nom" required [disabled]="this.franchiseCreated"><br/>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="prenom" autocomplete="off" class="form-control" type="text" name="prenom" placeholder="Prénom du franchisé" ngModel required [disabled]="this.franchiseCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="prenom" autocomplete="off" class="form-control" type="text" name="prenom" placeholder="Prénom du franchisé" [(ngModel)]="toCreateFranchise.franchise.prenom" required [disabled]="this.franchiseCreated"><br/>
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3 col-xl-1">
                    <input *ngIf="!toCreateFranchise" id="initiales" autocomplete="off" class="form-control" type="text" name="initiales" maxlength="10" placeholder="Initiales du franchisé" ngModel required [disabled]="this.franchiseCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="initiales" autocomplete="off" class="form-control" type="text" name="initiales" maxlength="10" placeholder="Initiales du franchisé" [(ngModel)]="toCreateFranchise.franchise.initiales" required [disabled]="this.franchiseCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <input *ngIf="!toCreateFranchise" id="email" autocomplete="off" class="form-control" type="text" name="email" placeholder="Adresse email" ngModel required [disabled]="this.franchiseCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="email" autocomplete="off" class="form-control" type="text" name="email" placeholder="Adresse email" [(ngModel)]="toCreateFranchise.franchise.mail" required [disabled]="this.franchiseCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                    <input *ngIf="!toCreateFranchise" id="numero" autocomplete="off" class="form-control" type="text" name="numero" maxlength="10" placeholder="Numéro de téléphone" ngModel required [disabled]="this.franchiseCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="numero" autocomplete="off" class="form-control" type="text" name="numero" maxlength="10" placeholder="Numéro de téléphone" [(ngModel)]="toCreateFranchise.franchise.portable" required [disabled]="this.franchiseCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="input-group">
                      <div class="input-group-text">Actif ?</div>
                      <select *ngIf="!toCreateFranchise" id="actif" name="actif" class="form-select" ngModel placeholder="Franchisé actif ?" [disabled]="this.franchiseCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                      <select *ngIf="toCreateFranchise" id="actif" name="actif" class="form-select" [(ngModel)]="toCreateFranchise.franchise.actif" placeholder="Franchisé actif ?" [disabled]="this.franchiseCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex flex-row-reverse bd-highlight"> 
                    <div class="d-grid gap-2">
                      <button class="btn btn-sm btn-primary btn-primary-bcp" type="submit" value="submit">Valider</button>
                    </div>
                    <i class="me-4">Vérifiez bien les coordonnées avant de valider, cette action est irréversible.</i>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="accordion-item" aria-disabled="true">
      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
        <button [ngClass]="franchiseCreated ? 'accordion-button collapsed' : 'accordion-button collapsed bg-secondary'" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
          Informations de la société
					<i *ngIf="societeCreated" class="bi bi-check-lg ms-3"></i>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body">
          <form #formSociete="ngForm" (ngSubmit)="validateSociete(formSociete.value)">
            <div class="form-group">
              <div class="row">						
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                    <input *ngIf="!toCreateFranchise" id="nom_societe" autocomplete="off" class="form-control" type="text" name="nom_societe" placeholder="Nom de la société" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="nom_societe" autocomplete="off" class="form-control" type="text" name="nom_societe" placeholder="Nom de la société" [(ngModel)]="toCreateFranchise.societe.nom" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="forme_juridique" autocomplete="off" class="form-control" type="text" name="forme_juridique" placeholder="Forme juridique" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="forme_juridique" autocomplete="off" class="form-control" type="text" name="forme_juridique" placeholder="Forme juridique" [(ngModel)]="toCreateFranchise.societe.forme_juridique" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-3 col-md-3 col-lg-3 col-xl-1">
                    <input *ngIf="!toCreateFranchise" id="abreviation_juridique" autocomplete="off" class="form-control" type="text" name="abreviation_juridique" placeholder="Abréviation juridique" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="abreviation_juridique" autocomplete="off" class="form-control" type="text" name="abreviation_juridique" placeholder="Abréviation juridique" [(ngModel)]="toCreateFranchise.societe.abreviation_juridique" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-10 col-md-10 col-lg-10 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="siren" autocomplete="off" class="form-control" type="text" name="siren" maxlength="11" placeholder="Siren" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="siren" autocomplete="off" class="form-control" type="text" name="siren" maxlength="11" placeholder="Siren" [(ngModel)]="toCreateFranchise.societe.siren" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input *ngIf="!toCreateFranchise" id="complement_siren" autocomplete="off" class="form-control" type="text" name="complement_siren" maxlength="11" placeholder="Complément Siret" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="complement_siren" autocomplete="off" class="form-control" type="text" name="complement_siren" maxlength="11" placeholder="Complément Siret" [(ngModel)]="toCreateFranchise.societe.complement_siret" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-4">
                    <input *ngIf="!toCreateFranchise" id="naf" autocomplete="off" class="form-control" type="text" name="naf" maxlength="11" placeholder="Code NAF" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="naf" autocomplete="off" class="form-control" type="text" name="naf" maxlength="11" placeholder="Code NAF" [(ngModel)]="toCreateFranchise.societe.naf" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="capital" autocomplete="off" class="form-control" type="text" name="capital" placeholder="Capital" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="capital" autocomplete="off" class="form-control" type="text" name="capital" placeholder="Capital" [(ngModel)]="toCreateFranchise.societe.capital" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="ville_RCS" autocomplete="off" class="form-control" type="text" name="ville_RCS" placeholder="Ville RCS" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="ville_RCS" autocomplete="off" class="form-control" type="text" name="ville_RCS" placeholder="Ville RCS" [(ngModel)]="toCreateFranchise.societe.ville_RCS" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <input *ngIf="!toCreateFranchise" id="TVA_intracom" autocomplete="off" class="form-control" type="text" name="TVA_intracom" placeholder="TVA intracom" ngModel required [disabled]="this.societeCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="TVA_intracom" autocomplete="off" class="form-control" type="text" name="TVA_intracom" placeholder="TVA intracom" [(ngModel)]="toCreateFranchise.societe.tva_intracom" required [disabled]="this.societeCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="input-group">
                      <div class="input-group-text">Actif ?</div>
                      <select *ngIf="!toCreateFranchise" id="actif" name="actif" class="form-select" ngModel placeholder="Franchisé actif ?" [disabled]="this.societeCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                      <select *ngIf="toCreateFranchise" id="actif" name="actif" class="form-select" [(ngModel)]="toCreateFranchise.societe.actif" placeholder="Franchisé actif ?" [disabled]="this.societeCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex flex-row-reverse bd-highlight mb-2">
                    <div class="d-grid gap-2">
                      <button class="btn btn-sm btn-primary btn-primary-bcp" type="submit" value="submit">Valider</button>
                      <!-- <button class="btn btn-lg btn-primary btn-primary-bcp" type="submit" [disabled]="loginForm.invalid">Se connecter</button> -->
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-reverse bd-highlight mt-2">
    <div class="d-grid gap-2">
      <div *ngIf="this.emessage" class="alert alert-success alert-dismissible fade show" role="alert">
        {{this.emessage}}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <button class="btn btn-lg btn-success ms-auto me-auto" type="submit" value="submit" *ngIf="franchiseCreated && societeCreated">Valider la création</button>
    </div>
  </div>
</form>

<h2>Adresse de facturation et de livraison</h2>
<div *ngIf="this.amessage" class="alert alert-success alert-dismissible fade show" role="alert">
  {{this.amessage}}
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
<form #createForm="ngForm" (ngSubmit)="validateAdresseFacturationLivraisonForm()">
  <div class="accordion" id="accordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
        <button [ngClass]="societeCreated ? 'accordion-button collapsed' : 'accordion-button collapsed bg-secondary'" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          Adresse de facturation de la société
					<i *ngIf="societeAddressFacturationCreated" class="bi bi-check-lg ms-3"></i>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <form #formSocieteAdresse="ngForm" (ngSubmit)="validateSocieteAdresseFacturation(formSocieteAdresse.value)">
            <div class="form-group">
              <div class="row">						
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" ngModel required [disabled]="this.societeAddressFacturationCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" [(ngModel)]="toCreateFranchise.adresses.facturation.adresse" required [disabled]="this.societeAddressFacturationCreated"><br/>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" ngModel required [disabled]="this.societeAddressFacturationCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" [(ngModel)]="toCreateFranchise.adresses.facturation.complement_adresse" required [disabled]="this.societeAddressFacturationCreated"><br/>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input *ngIf="!toCreateFranchise" id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" maxlength="6" placeholder="Code postal" ngModel required [disabled]="this.societeAddressFacturationCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" maxlength="6" placeholder="Code postal" [(ngModel)]="toCreateFranchise.adresses.facturation.code_postal" required [disabled]="this.societeAddressFacturationCreated"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <input *ngIf="!toCreateFranchise" id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" ngModel required [disabled]="this.societeAddressFacturationCreated"><br/>
                    <input *ngIf="toCreateFranchise" id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" [(ngModel)]="toCreateFranchise.adresses.facturation.ville" required [disabled]="this.societeAddressFacturationCreated"><br/>
                  </div>
                  <div class="d-flex flex-row-reverse bd-highlight mb-2">
                    <div class="d-grid gap-2">
                      <button class="btn btn-sm btn-primary btn-primary-bcp" type="submit" value="submit">Valider</button>
                      <!-- <button class="btn btn-lg btn-primary btn-primary-bcp" type="submit" [disabled]="loginForm.invalid">Se connecter</button> -->
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingFourth">
        <button [ngClass]="societeAddressFacturationCreated ? 'accordion-button collapsed' : 'accordion-button collapsed bg-secondary'" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFourth" aria-expanded="false" aria-controls="panelsStayOpen-collapseFifth">
          Adresse de livraison de la société : matériel, palette, support
					<i *ngIf="societeAddressLivraisonCreated" class="bi bi-check-lg ms-3"></i>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseFourth" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFourth">
        <div class="accordion-body">
          <form #formSocieteAdresseLivraison="ngForm" (ngSubmit)="validateSocieteAdresseLivraison(formSocieteAdresseLivraison.value, 1)">
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" [value]="sameadresse === true" (change)="useSameAddress($event)" id="defaultCheck1">
              <label class="form-check-label" for="defaultCheck1">
                Utiliser la même adresse
              </label>
            </div>
            <div class="form-group">
              <div class="row">                        
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" *ngIf="sameadresse === true; else notSameAddress" [(ngModel)]="societe_adresse_facturation.adresse" [disabled]="this.societeAddressLivraisonCreated" ><br/>
                    <ng-template #notSameAddress>
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.adresse" id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" required [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" ngModel required [disabled]="this.societeAddressLivraisonCreated">
                      <br/>
                    </ng-template>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" *ngIf="sameadresse === true; else notSameComp" [(ngModel)]="societe_adresse_facturation.complement_adresse" [disabled]="this.societeAddressLivraisonCreated"><br/>
                    <ng-template #notSameComp>
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.complement_adresse" id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" required [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" ngModel required [disabled]="this.societeAddressLivraisonCreated">
                      <br/>
                    </ng-template>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" maxlength="6" placeholder="Code postal" *ngIf="sameadresse === true; else notSameCp" [(ngModel)]="societe_adresse_facturation.code_postal" [disabled]="this.societeAddressLivraisonCreated"><br/>
                    <ng-template #notSameCp>
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.code_postal" id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" placeholder="Code postal" required [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" placeholder="Code postal" ngModel required [disabled]="this.societeAddressLivraisonCreated">
                      <br/>
                    </ng-template>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <input id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" *ngIf="sameadresse === true; else notSameVille" [(ngModel)]="societe_adresse_facturation.ville" [disabled]="this.societeAddressLivraisonCreated"><br/>
                    <ng-template #notSameVille>
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.ville" id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" required [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" ngModel required [disabled]="this.societeAddressLivraisonCreated">
                      <br/>
                    </ng-template>
                  </div>
                  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="input-group">
                      <div class="input-group-text">Accessibilité 19T ?</div>
                      <select *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.t19" id="accessibilite_19T" name="accessibilite_19T" class="form-select" required [disabled]="this.societeAddressLivraisonCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                      <select *ngIf="!toCreateFranchise" id="accessibilite_19T" name="accessibilite_19T" class="form-select" ngModel required [disabled]="this.societeAddressLivraisonCreated">
                        <option value=1>Oui</option>
                        <option value=0>Non</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="input-group">
                      <span class="input-group-text">Coordonnées</span>
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.latitude" type="number" class="form-control" id="latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00001" min="-90" max="90" [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" type="number" class="form-control" ngModel id="latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00001" min="-90" max="90" [disabled]="this.societeAddressLivraisonCreated">
                      
                      <input *ngIf="toCreateFranchise" [(ngModel)]="toCreateFranchise.adresses.livraison.longitude" type="number" class="form-control" id="longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00001" min="-180" max="180" [disabled]="this.societeAddressLivraisonCreated">
                      <input *ngIf="!toCreateFranchise" type="number" class="form-control" ngModel id="longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00001" min="-180" max="180" [disabled]="this.societeAddressLivraisonCreated">
                    </div>
                  </div>
                  <div class="d-flex flex-row-reverse bd-highlight mb-2">
                    <div class="d-grid gap-2">
                      <button class="btn btn-sm btn-primary btn-primary-bcp" type="submit" value="submit">Valider</button>
                      <!-- <button class="btn btn-lg btn-primary btn-primary-bcp" type="submit" [disabled]="loginForm.invalid">Se connecter</button> -->
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingFifth">
        <button [ngClass]="societeAddressLivraisonCreated ? 'accordion-button collapsed' : 'accordion-button collapsed bg-secondary'" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFifth" aria-expanded="false" aria-controls="panelsStayOpen-collapseFifth">
          Adresse de livraison de la société : brochures, etc.
					<i *ngIf="societeAddressLivraison2Created" class="bi bi-check-lg ms-3"></i>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseFifth" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFifth">
        <div class="accordion-body">
          <form #formSocieteAdresseLivraison2="ngForm" (ngSubmit)="validateSocieteAdresseLivraison(formSocieteAdresseLivraison2.value, 2)">
            <div class="form-group">
              <div class="row">                        
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input id="adresse" autocomplete="off" class="form-control" type="text" name="adresse" placeholder="Adresse de la société" ngModel required [disabled]="this.societeAddressLivraison2Created"><br/>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <input id="complement_adresse" autocomplete="off" class="form-control" type="text" name="complement_adresse" placeholder="Complément d'adresse" ngModel required [disabled]="this.societeAddressLivraison2Created"><br/>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <input id="code_postal" autocomplete="off" class="form-control" type="text" name="code_postal" placeholder="Code postal" ngModel required [disabled]="this.societeAddressLivraison2Created"><br/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <input id="ville" autocomplete="off" class="form-control" type="text" name="ville" placeholder="Ville" ngModel required [disabled]="this.societeAddressLivraison2Created"><br/>
                  </div>
                  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div class="input-group">
                      <span class="input-group-text">Coordonnées</span>
                      <input type="number" class="form-control" id="latitude" ngModel name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00001" min="-90" max="90" [disabled]="this.societeAddressLivraison2Created">
                      <input type="number" class="form-control" id="longitude" ngModel name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00001" min="-180" max="180" [disabled]="this.societeAddressLivraison2Created">
                    </div>
                  </div>
                  <div class="d-flex flex-row-reverse bd-highlight mb-2">
                    <div class="d-grid gap-2">
                      <button class="btn btn-sm btn-primary btn-primary-bcp" type="submit" value="submit">Valider</button>
                      <!-- <button class="btn btn-lg btn-primary btn-primary-bcp" type="submit" [disabled]="loginForm.invalid">Se connecter</button> -->
                    </div>
                  </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-reverse bd-highlight mt-2 mb-4">
    <div class="d-grid gap-2">
      <button class="btn btn-lg btn-success ms-auto me-auto" type="submit" value="submit" *ngIf="societeAddressFacturationCreated && societeAddressLivraisonCreated && formSocieteAdresseLivraison2">Valider la création</button>
    </div>
  </div>
</form>