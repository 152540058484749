<div class="container">
    <div *ngIf="userIsLoggedin">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <!-- <li class="nav-item" (click)="setActiveTab(1)">
          <a class="nav-link active" id="pills-cassette-tab" data-bs-toggle="pill" href="#pills-cassette" role="tab" aria-controls="pills-cassette" aria-selected="true">Cassette</a>
        </li>
        <li class="nav-item" (click)="setActiveTab(2)">
          <a class="nav-link" id="pills-baguette-tab" data-bs-toggle="pill" href="#pills-baguette" role="tab" aria-controls="pills-baguette" aria-selected="false">Baguette</a>
        </li>
        <li class="nav-item" (click)="setActiveTab(3)">
          <a class="nav-link" id="pills-canette-tab" data-bs-toggle="pill" href="#pills-canette" role="tab" aria-controls="pills-canette" aria-selected="false">Canette</a>
        </li>
        <li class="nav-item" (click)="setActiveTab(4)">
          <a class="nav-link" id="pills-fusette-tab" data-bs-toggle="pill" href="#pills-fusette" role="tab" aria-controls="pills-fusette" aria-selected="false">Fusette</a>
        </li> -->
      </ul>
  
      <br>
  
      <form [formGroup]="form" (ngSubmit)="createCarton(form.value)">
        <div class="col-sm-6">
          <input type="text"
                 name="serialNumber"
                 class="form-control"
                 placeholder="Cliquez-ici avant de scanner"
                 formControlName="title">
        </div>
  
        <app-scan-products [carton]="null"></app-scan-products>
  
        <div class="col-sm-6">
          <button type="submit" class="btn btn-success">Valider la création</button>
        </div>
  
      </form>
    </div>
  </div>
  
  
  
  <div class="panel-body" *ngIf="userIsLoggedin">
  
  </div>
  
  <div *ngIf="!userIsLoggedin">
    <h2>Création d'annonce réservée aux utilisateurs inscrits</h2>
    <div>
      Vous devez être connecté pour créer une annonce.
      <br>
      <a href="#" class="" routerLink="/login">Se connecter</a> <!--ou
      <a href="#" class="" routerLink="/register">Créer un compte</a>-->
    </div>
  </div>
  