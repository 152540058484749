import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'livraisonAsiStatus'
})
export class LivraisonAsiStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'Livraison prête à partir';
      case 2:
        return 'Partie en livraison';
      case 3:
        return 'Annulée';
      default:
        return 'Inconnu';
    }
  }

}
