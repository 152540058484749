<div style="z-index: 9999;" class="mt-5 position-fixed top-0 end-0 p-3 toast-container">
    <div *ngFor="let toast of toasts" class="{{ cssClass(toast)}} fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="me-auto">{{toast.title}}</strong>
            <small class="text-muted">{{toast.smallText}}</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
            {{toast.message}}
        </div>
    </div>
</div>
