import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Franchise} from '../model/franchise';
import {RetourService} from '../services/retour.service';
import {AuthService} from '../services/auth.service';
import {Provider} from '../model/provider';
import { Retour } from '../model/retour';

@Component({
  selector: 'app-retour-list',
  templateUrl: './retour-list.component.html',
  styleUrls: ['./retour-list.component.css']
})
export class RetourListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: any;
  provider: Provider = new Provider(0, '', 0);
  retours: Retour[] = [];
  retours0: Retour[] = [];
  retours1: Retour[] = [];
  retours2: Retour[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  retourCreated: any;
  errorMessage: string = '';
  source: string = '';
  parametersObservable: any;

  constructor(private router: Router,
    private retourService: RetourService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;

      // admin needs to see all ads
      if (this.isAdmin) {
        this.loadRetours();
      } else {
        this.loadRetours();
      }

      // this.retourService.retoursSubject.subscribe(data => {
      //   this.retours = [data, ...this.retours];
      // });
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadRetours() {
    this.retourService.getRetours(this.token)
      .subscribe(
        {
          next: data => this.displayRetours(data),
          error: err => console.error(err),
          complete: () => console.log('retours are loaded!')
        }
      );
  }

  displayRetours(response: any = {}) {
    if (response.success) {
      this.retours = response.retours;

      this.retours0 = this.retours.filter(retour => retour.status === 0);
      this.retours1 = this.retours.filter(retour => retour.status === 1);
      this.retours2 = this.retours.filter(retour => retour.status === 2);

      if (this.isAdmin) {
        const providersRetours = this.retours.map(retour => retour.provider);

        const uniqueRetoursArray = (data: any[]) => [...new Set(data.map((o: any) => JSON.stringify(o)))].map(s => JSON.parse(s.toString()));
        this.providers = uniqueRetoursArray(providersRetours).sort(function(a, b) {
          return a.id - b.id;
        });

        for (let p = 0; p < this.providers.length; p++) {
          this.providers[p]['retours0'] = this.retours0.filter(retour => retour.provider.id === this.providers[p].id);
          this.providers[p]['retours1'] = this.retours1.filter(retour => retour.provider.id === this.providers[p].id);
          this.providers[p]['retours2'] = this.retours2.filter(retour => retour.provider.id === this.providers[p].id);
        }
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  showHistory() {
    this.router.navigate([`/retours/historique`]);
  }

  createRetour() {
    const retour: Retour = <Retour><unknown>{
      id: Date.now(),
      provider: <Provider>{ id: this.provider.id },
      serialNumber: 0,
      status: 0,
      franchise: <Franchise>{ id: 0, initiales: '' },
      barcode: '',
      creationDate: new Date().toISOString(),
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') ||'').token;
    this.retourService.addRetour(retour, token).subscribe(
      {
        next: data => this.handleServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('retour was created!')
      }
    );
  }

  handleServerResponse(response: any = {}) {
    if (response.success) {
      this.retourCreated = response.retour;
      this.router.navigateByUrl(`/retour/${this.retourCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any = {}) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }
}
