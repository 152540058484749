import { Component, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FranchiseService } from '../services/franchise.service';
import { SocieteService } from '../services/societe.service';
import { FranchiseAddFormComponent } from '../franchise-add-form/franchise-add-form.component';
import { Franchise } from '../model/franchise';

@Component({
  selector: 'app-franchises-details',
  templateUrl: './franchises-details.component.html',
  styleUrls: ['./franchises-details.component.css'],
  providers: [FranchiseAddFormComponent]
})
export class FranchisesDetailsComponent implements OnInit {

  franchise_deja_cree: boolean = true;
  franchise: any;
  id: any;

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;
  isFacturationActive: boolean = false;

  initiales: string = '';
  franchise_updated: string = '';
  facturation_updated: string = '';
  livraison_updated: string = '';
  actif: number = 0;
  facturationId: number = 0;
  livraisonId: number = 0;
  franchise_id: number = 0;

  facturationAdresses: any;
  facturationActiveAdresses: any[] = [];
  facturationInactiveAdresses: any[] = [];

  livraisonAdresses: any;
  livraisonActiveAdresses: any[] = [];
  livraisonInactiveAdresses: any[] = [];

  selectedFacturationAddress: any;
  selectedLivraisonAddress: any;

  formNewAdresseFacturation: boolean = false;
  formNewAdresseLivraison: boolean = false;

  constructor(private franchiseService: FranchiseService,
                      private societeService: SocieteService,
                      private activatedRoute: ActivatedRoute,
                      private authService: AuthService) { }
  

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    this.id = this.activatedRoute.snapshot.params['id'];
    this.loadFranchise(this.id);
    this.loadAdressesFacturation(this.id);
    this.loadAdressesLivraison(this.id);

    if(this.id == 'create'){
      this.franchise_deja_cree = false;
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadFranchise(id: any) {
    this.franchiseService.getFranchise(id, this.token).subscribe(
      {
        next: data => this.displayFranchise(data),
        error: err => console.error(err),
        complete: () => console.log('franchisé loaded!')
      }
    )
  }

  loadAdressesFacturation(id: any) {
    this.franchiseService.getAllAdressesFacturationByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.displayAdressesFacturation(data),
          error: err => console.error(err),
          complete: () => console.log('franchisé facturation adresses loaded!')
        }
      )
  }

  loadAdressesLivraison(id: any) {
    this.franchiseService.getAllAdressesLivraisonByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.displayAdressesLivraison(data),
          error: err => console.error(err),
          complete: () => console.log('franchisé livraison adresses loaded!')
        }
      )
  }

  displayFranchise(response: any) {
    this.franchise = response.franchise;
    this.franchise_id = this.franchise.franchiseId;
    this.facturationId = this.franchise.facturationId;
    this.livraisonId = this.franchise.livraisonId;
    console.log('this.franchise', this.franchise)
  }

  onUpdateFranchise(id: any, value: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    console.log(value);

    this.franchiseService.updateFranchise(id, { ...value, isActif: this.franchise.isActif }, token).subscribe(
      {
        next: data => this.handleUpdateFranchise(data),
        error: err => console.error(err),
        complete: () => console.log('franchisé was updated!')
      }
    )
  }

  displayAdressesFacturation(response: any) {
    this.facturationAdresses = response.adresses

    for(let i = 0; i <= this.facturationAdresses.length - 1; i++) {
      if(this.facturationAdresses[i].isActif === 1) {
        this.facturationActiveAdresses.push(this.facturationAdresses[i])
      } else {
        this.facturationInactiveAdresses.push(this.facturationAdresses[i])
      }
    } 

    this.selectedFacturationAddress = this.facturationAdresses[0]
  }

  displayAdressesLivraison(response: any) {
    this.livraisonAdresses = response.adresses

    for(let i = 0; i <= this.livraisonAdresses.length - 1; i++) {
      if(this.livraisonAdresses[i].isActif === 1) {
        this.livraisonActiveAdresses.push(this.livraisonAdresses[i])
      } else {
        this.livraisonInactiveAdresses.push(this.livraisonAdresses[i])
      }
    } 

    this.selectedLivraisonAddress = this.livraisonAdresses[0]
  }

  handleUpdateFranchise(response: any) {
    this.franchise_updated = "Données mises à jour."
  }

  updateSocieteAdresseFacturation(id: any, value: any) {
    console.log("value", value);
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.societeService.updateSocieteAdresseFacturation(id, value, token).subscribe(
      {
        next: data => this.handleUpdateSocieteAdresseFacturation(data),
        error: err => console.error(err),
        complete: () => console.log('societe adress facturation was updated!')
      }
    )
  }

  handleUpdateSocieteAdresseFacturation(response: any) {
    this.facturation_updated = "Données mises à jour."
  }

  updateSocieteAdresseLivraison(id: any, value: any) {
    console.log("updateSocieteAdresseLivraison value", JSON.stringify(null, value, 2));
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.societeService.updateSocieteAdresseLivraison(id, value, token).subscribe(
      {
        next: data => this.handleUpdateSocieteAdresseLivraison(data),
        error: err => console.error(err),
        complete: () => console.log('societe adress livrison was updated!')
      }
    )
  }

  handleUpdateSocieteAdresseLivraison(response: any) {
    this.livraison_updated = "Données mises à jour."
  }

  newAdresseFacturation(event: any) {
    this.formNewAdresseFacturation = true;
  }

  addSocieteAdresseFacturation(value: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    value.dateAjout = new Date().toISOString().slice(0, 19).replace('T', ' ');
    value.nom = this.franchise.facturationNom;
    value.actif = 1;
    value.id_franchise = this.id;

    this.societeService.addSocieteAdresseFacturation(value, token).subscribe(
      {
        next: data => this.handleAddSocieteAdresseFacturation(data),
        error: err => console.error(err),
        complete: () => console.log('societe adress facturation was updated!')
      }
    )
  }

  handleAddSocieteAdresseFacturation(response: any) {
    window.location.reload();
    console.log("UPDATED SOCIETE ADRESS FACTURATION", response);
  }

  newAdresseLivraison(event: any) {
    this.formNewAdresseLivraison = true;
  }

  addSocieteAdresseLivraison(value: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    value.dateAjout = new Date().toISOString().slice(0, 19).replace('T', ' ');
    value.nom = this.franchise.facturationNom;
    value.actif = 1;
    value.id_franchise = this.id;

    this.societeService.addSocieteAdresseLivraison(value, token).subscribe(
      {
        next: data => this.handleAddSocieteAdresseLivraison(data),
        error: err => console.error(err),
        complete: () => console.log('societe adress livraison was updated!')
      }
    )
  }

  handleAddSocieteAdresseLivraison(response: any) {
    window.location.reload();
  }

  onChangeFacturationAdresse(newAddress: number) {
    console.log('newAddress', newAddress)
    const address = this.facturationAdresses.find((a: { id: number }) => a.id === newAddress);
    console.log('address', address)
    this.selectedFacturationAddress = address;
    // this.franchise.faddress = address
    console.log('this.id', this.id)
    this.loadAdresseFacturation(address.id, this.id)
  }

  loadAdresseFacturation(id_address: number, id_franchise: number) {
    this.franchiseService.getAdresseFacturationByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseFacturationAddress(data),
          error: err => console.log(err),
          complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  handleFranchiseFacturationAddress(response: any = {}) {
    console.log('response handle franchise address', response)
    if(response.success) {
      console.log('response livraison address', response.address[0])
      this.selectedFacturationAddress = response.address[0]
      console.log('this.franchise.address', this.selectedFacturationAddress)
      // this.loadFranchise(this.id)
    }
  }

  onChangeLivraisonActive(event: any) {
    console.log('test', event.target.checked)
    return event.target.checked
  }
  
  onChangeLivraisonAdresse(newAddress: number) {
    console.log('newAddress', newAddress)
    const address = this.livraisonAdresses.find((a: { id: number }) => a.id === newAddress);
    console.log('address', address)
    this.selectedLivraisonAddress = address;
    // this.franchise.faddress = address
    console.log('this.id', this.id)
    this.loadAdresseLivraison(address.id, this.id)
  }

  loadAdresseLivraison(id_address: number, id_franchise: number) {
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonAddress(data),
          error: err => console.log(err),
          complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  handleLivraisonAddress(response: any = {}) {
    console.log('response handle franchise address', response)
    if(response.success) {
      console.log('response livraison address', response.address[0])
      this.selectedLivraisonAddress = response.address[0]
      console.log('this.franchise.address', this.selectedLivraisonAddress)
      // this.loadFranchise(this.id)
    }
  }

  onChangeFacturationActive(event: any) {
    console.log('test', event.target.checked)
    return event.target.checked
  }

  enableOrDisableFranchise() {
    this.franchise.isActif = !this.franchise.isActif;

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    const value = {
      nom: this.franchise.nomPart,
      prenom: this.franchise.prenomPart,
      initiales: this.franchise.initiales,
      phone: this.franchise.TelPortPart,
      email: this.franchise.email,
      isActif: this.franchise.isActif
    };

    this.franchiseService.updateFranchise(this.franchise.id, value, token).subscribe(
      {
        next: data => this.handleUpdateFranchise(data),
        error: err => console.error(err),
        complete: () => console.log('franchisé was updated!')
      }
    )
  }
}
