<div class="container">

  <h2>Historique retours</h2>

  <table class="table table-hover table-bordered">
    <thead>
    <tr>
      <th scope="col">N° de Retour</th>
      <th scope="col">Franchisé</th>
      <th scope="col">Statut</th>
      <th scope="col">Date de création</th>
      <th scope="col">Date de création - retour centre</th>
      <!-- <th scope="col">Date de départ du Scan</th>
      <th scope="col">Date de fin du Scan</th> -->
      <th scope="col">Détail</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let retour of retours">
      <tr>
        <th scope="row">
          {{retour.serialNumber | retourBarcode: retour.providerId}}
        </th>
        <td>
          {{ retour.franchise.initiales }}
        </td>
        <td>{{retour.status | retourStatus}}</td>
        <td>{{retour.creationDate | date:'dd MMMM y à HH:mm'}}</td>
        <!-- <td>
          <ng-container *ngIf="retour.scanStartDate > 0 ; else no_scanStart_date">
            {{retour.scanStartDate | date:'dd MMMM y à HH:mm'}}
          </ng-container>
          <ng-template #no_scanStart_date>-</ng-template>
        </td>
        <td>
          <ng-container *ngIf="retour.scanFinishDate > 0 ; else no_scanFinish_date">
            {{retour.scanFinishDate | date:'dd MMMM y à HH:mm'}}
          </ng-container>
          <ng-template #no_scanFinish_date>-</ng-template>
        </td>-->
        <td>{{ (retour.centreCreationDate | date:'dd MMMM y à HH:mm') || "-" }}</td>
        <td> 
          <a class="btn btn-outline-primary" href="#" [routerLink]="['/retour', retour.id]">Voir le détail</a>
        </td>
      </tr>
      <!-- <tr>
        <th scope="row">{{retour.serialNumber | retourBarcode: retour.provider.id}}</th>

        <td>
          {{ retour.franchise.initiales }}

          <ng-container *ngIf="retour.franchise; else noFranchise">
            bonsoir
            {{retour.franchise.initiales}}
          </ng-container>
          <ng-template #noFranchise>
            -
          </ng-template>
        </td>

        <td>{{retour.status | retourStatus}}</td>
        <td>{{retour.creationDate | date:'dd MMMM y à HH:mm'}}</td>
        <td>
          <ng-container *ngIf="retour.scanStartDate > 0 ; else no_scanStart_date">
            {{retour.scanStartDate | date:'dd MMMM y à HH:mm'}}
          </ng-container>
          <ng-template #no_scanStart_date>-</ng-template>
        </td>
        <td>
          <ng-container *ngIf="retour.scanFinishDate > 0 ; else no_scanFinish_date">
            {{retour.scanFinishDate | date:'dd MMMM y à HH:mm'}}
          </ng-container>
          <ng-template #no_scanFinish_date>-</ng-template>
        </td>
        <td>
          <a class="btn btn-outline-primary" href="#" [routerLink]="['/retour', retour.id, source]">Voir le détail</a>
        </td>
      </tr> -->
    </ng-container>
    </tbody>
  </table>

  <nav aria-label="historique livraison pagination">
    <ul class="pagination justify-content-center">
      <li class="page-item {{ currentPage === 1 ? 'disabled' : '' }}">
        <button class="page-link" tabindex="-1" appNoDblClick (click)="loadRetours(currentPage - 1)">Précédente</button>
      </li>
      <ng-container *ngFor="let page of pages">
        <li class="page-item{{ page === currentPage ? ' active' : '' }}">
          <button class="page-link" appNoDblClick (click)="loadRetours(page)">{{ page }}</button>
        </li>
      </ng-container>
      <li class="page-item {{ currentPage === pageCount ? 'disabled' : ''}}">
        <button class="page-link" appNoDblClick (click)="loadRetours(currentPage + 1)">Suivante</button>
      </li>
    </ul>
  </nav>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</div>
