<div class="container">

    <main role="main" class="container">
  
      <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
        <div *ngIf="isAdmin" class="lh-100">
          <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createLivraison()">Préparer une Livraison</button>
        </div>
  
        <div class="lh-100">
          <button type="button" class="btn btn-lg btn-success" appNoDblClick (click)="showHistory()">Historique des Livraisons</button>
        </div>
  
        <div *ngIf="isAdmin" class="lh-100">
          <button type="button" class="btn btn-lg btn-warning" appNoDblClick (click)="showAllAdressesLivraison()">Voir les Adresses de Livraison</button>
        </div>
      </div>
  
      <ng-container *ngIf="isAdmin">
        <div class="my-3 p-3 bg-white rounded box-shadow">
          <h3 class="border-bottom border-gray pb-2 mb-0">En cours de création ({{ livraisons0.length }})</h3>
  
          <ng-container *ngIf="livraisons0.length > 0">
            <ng-container *ngIf="isAdmin; else noAdmin0">
              <br>
              <ng-container *ngFor="let p of providers">
                <ng-container *ngIf="p.livraisons0.length > 0">
                  <div class="card">
                    <div class="card-header">
                      {{p.nom}} ({{ p.livraisons0.length }})
                    </div>
                    <div class="card-body">
                      <div class="media row p-2">
                        <ng-container *ngFor="let livraison of p.livraisons0">
                          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                            <a class="btn btn-outline-secondary m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                              {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                              <ng-container *ngIf="livraison.franchise">
                                {{livraison.franchise.nomPart.toUpperCase()}}<br>
                                {{livraison.franchise.prenomPart}}<br>
                                [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                              </ng-container>
  
                              {{livraison.creationDate | date:'dd MMM y'}}
                            </a>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div><br>
                </ng-container>
              </ng-container>
            </ng-container>
  
            <ng-template #noAdmin0>
              <div class="media row p-2">
                <ng-container *ngFor="let livraison of livraisons0">
                  <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <a class="btn btn-outline-secondary m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                      {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                      <ng-container *ngIf="livraison.franchise">
                        {{livraison.franchise.nomPart.toUpperCase()}}<br>
                        {{livraison.franchise.prenomPart}}<br>
                        [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                      </ng-container>
  
                      {{livraison.creationDate | date:'dd MMM y'}}
                    </a>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
  
      <ng-container *ngIf="isAdmin">
        <div class="my-3 p-3 bg-white rounded box-shadow">
          <h3 class="border-bottom border-gray pb-2 mb-0">En attente de devis ({{ livraisons6.length }})</h3>
  
          <ng-container *ngIf="livraisons6.length > 0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.livraisons6.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.livraisons6.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row p-2">
                      <ng-container *ngFor="let livraison of p.livraisons6">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                          <a class="btn btn-outline-secondary m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                            {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>

                            <ng-container *ngIf="livraison.franchise">
                              {{livraison.franchise.nomPart.toUpperCase()}}<br>
                              {{livraison.franchise.prenomPart}}<br>
                              [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                            </ng-container>

                            {{livraison.creationDate | date:'dd MMM y'}}
                          </a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En préparation ({{ livraisons1.length }})</h3>
  
        <ng-container *ngIf="livraisons1.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.livraisons1.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.livraisons1.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row p-2">
                      <ng-container *ngFor="let livraison of p.livraisons1">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                          <a class="btn btn-outline-danger m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                            {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                            <ng-container *ngIf="livraison.franchise">
                              {{livraison.franchise.nomPart.toUpperCase()}}<br>
                              {{livraison.franchise.prenomPart}}<br>
                              [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                            </ng-container>
  
                            {{livraison.creationDate | date:'dd MMM y'}}
                          </a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            <div class="media row p-2">
              <ng-container *ngFor="let livraison of livraisons1">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="btn btn-outline-danger m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                    {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                    <ng-container *ngIf="livraison.franchise">
                      {{livraison.franchise.nomPart.toUpperCase()}}<br>
                      {{livraison.franchise.prenomPart}}<br>
                      [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                    </ng-container>
  
                    {{livraison.creationDate | date:'dd MMM y'}}
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En attente de validation par ASI ({{ livraisons2.length }})</h3>
  
        <ng-container *ngIf="livraisons2.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.livraisons2.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.livraisons2.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let livraison of p.livraisons2">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                          <a class="btn btn-outline-warning m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                            {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                            <ng-container *ngIf="livraison.franchise">
                              {{livraison.franchise.nomPart.toUpperCase()}}<br>
                              {{livraison.franchise.prenomPart}}<br>
                              [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                            </ng-container>
  
                            {{livraison.creationDate | date:'dd MMM y'}}
                          </a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            <div class="media row p-2">
              <ng-container *ngFor="let livraison of livraisons2">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="btn btn-outline-warning m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                    {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                    <ng-container *ngIf="livraison.franchise">
                      {{livraison.franchise.nomPart.toUpperCase()}}<br>
                      {{livraison.franchise.prenomPart}}<br>
                      [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                    </ng-container>
  
                    {{livraison.creationDate | date:'dd MMM y'}}
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">Livraison prête à partir ({{ livraisons3.length }})</h3>
  
        <ng-container *ngIf="livraisons3.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.livraisons3.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.livraisons3.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row p-2 ">
                      <ng-container *ngFor="let livraison of p.livraisons3">
                        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                          <a class="btn btn-outline-success m-2 p-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                            {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                            <ng-container *ngIf="livraison.franchise">
                              {{livraison.franchise.nomPart.toUpperCase()}}<br>
                              {{livraison.franchise.prenomPart}}<br>
                              [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                            </ng-container>
  
                            {{livraison.creationDate | date:'dd MMM y'}}
                          </a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            <div class="media row p-2">
              <ng-container *ngFor="let livraison of livraisons3">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                  <a class="btn btn-outline-success p-2 m-2 bloc" href="#" [routerLink]="['/livraison', livraison.id]">
                    {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>
  
                    <ng-container *ngIf="livraison.franchise">
                      {{livraison.franchise.nomPart.toUpperCase()}}<br>
                      {{livraison.franchise.prenomPart}}<br>
                      [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                    </ng-container>
  
                    {{livraison.creationDate | date:'dd MMM y'}}
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
  
    </main>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </div>
  