import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cartonType'
})
export class CartonTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value.toUpperCase()) {
      case 'G':
      case 'K':
        return 'Cassette';
      case 'B':
        return 'Baguette';
      case 'C':
        return 'Canette';
      case 'T':
      case 'F':
        return 'Fusette';
      default:
        return 'Inconnu';
    }
  }

}
