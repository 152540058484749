import {Component, EventEmitter, Injectable, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AlertService} from '../services/alert.service';
import {Livraison} from '../model/livraison';
import {Palette} from '../model/palette';
import {PaletteBarcodePipe} from '../pipes/palette-barcode.pipe';
import {PaletteService} from '../services/palette.service';
import {LivraisonBarcodePipe} from '../pipes/livraison-barcode.pipe';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import { PaletteModelePipe } from '../pipes/palette-modele.pipe';
import { LivraisonDetailsComponent } from '../livraison-details/livraison-details.component';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-scan-palettes',
  templateUrl: './scan-palettes.component.html',
  styleUrls: ['./scan-palettes.component.css']
})
export class ScanPalettesComponent implements OnInit {
  @Input() livraison: any;
  @Input() palettes: any;
  @Input() orderPalettes: any[] = [];
  @Input() token: string = '';
  @Output() updatedLivraison = new EventEmitter<any>();

  decodedToken: any = {};
  isAdmin: boolean = false;

  dateAjoutModelesPalette: string = new Date(1603275539).toISOString();
  
  palettesModeleK7: Palette[] = [];
  palettesModeleCustom: Palette[] = [];
  palettesModeleP3P: Palette[] = [];
  palettesModeleP4P: Palette[] = [];
  
  scannedBarcode: string = '';

  error: any;
  errorMessage: string = '';

  constructor(private paletteService: PaletteService,
              private alertService: AlertService,
              private toastService: ToastService,
              private authService: AuthService,
              private router: Router)
  { }

  ngOnInit() {
    if (this.token) {
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    if (this.livraison) {

      this.livraison.barcode = new LivraisonBarcodePipe().transform(this.livraison.serialNumber, this.livraison.provider.id);
      if (this.palettes) {
        console.log('this.palettes - scan-palettes', this.palettes);

        if (this.livraison.status > 0 && this.livraison.status !== 6) {
          this.palettesModeleK7 = this.palettes.filter((p: { modele: number; }) => p.modele === 1);
          this.palettesModeleCustom = this.palettes.filter((p: { modele: number; }) => p.modele === 2);
          this.palettesModeleP3P = this.palettes.filter((p: { modele: number; }) => p.modele === 3);
          this.palettesModeleP4P = this.palettes.filter((p: { modele: number; }) => p.modele === 4);
        }
      }
    } else {
      console.log('app-scan-palettes', 'NO PALETTE');
    }
  }

  // ALERT
  alertPrimary(message: string) {
    this.alertService.primary(message);
  }
  toastPrimary(message: string, title: string, smallText: string) {
    this.toastService.primary(message, title, smallText)
  }
  alertSecondary(message: string) {
    this.alertService.secondary(message);
  }
  toastSecondary(message: string, title: string, smallText: string) {
    this.toastService.secondary(message, title, smallText)
  }
  alertSuccess(message: string) {
    this.alertService.success(message);
  }
  toastSuccess(message: string, title: string, smallText: string) {
    this.toastService.success(message, title, smallText);
  }
  alertDanger(message: string) {
    this.alertService.danger(message);
  }
  toastDanger(message: string, title: string, smallText: string) {
    this.toastService.danger(message, title, smallText)
  }
  alertWarning(message: string) {
    this.alertService.warning(message);
  }
  toastWarning(message: string, title: string, smallText: string) {
    this.toastService.warning(message, title, smallText)
  }
  alertInfo(message: string) {
    this.alertService.info(message);
  }
  toastInfo(message: string, title: string, smallText: string) {
    this.toastService.info(message, title, smallText)
  }
  alertLight(message: string) {
    this.alertService.light(message);
  }
  toastLight(message: string, title: string, smallText: string) {
    this.toastService.light(message, title, smallText)
  }
  alertDark(message: string) {
    this.alertService.dark(message);
  }
  toastDark(message: string, title: string, smallText: string) {
    this.toastService.dark(message, title, smallText)
  }
  alertClear() {
    this.alertService.clear();
  }
  toastClear() {
    this.toastService.clear();
  }
  sendBarcodeNumber(barcodeStr: number) {

    this.paletteService.getPalette(barcodeStr, this.token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('palette loaded!')
        }
      );
  }

  handleServerResponse(response: any) {

    if (response.success) {
      if (response.palette && response.palette !== null) {
        this.updatePalette(response.palette);
      }
    } else {
      this.errorMessage = response.message;
      // this.alertDanger('La Palette ' + response.id + ' n\'existe pas dans la base de données.');
      this.toastDanger('La Palette ' + response.id + ' n\'existe pas dans la base de données.', 'Erreur', "Palette introuvable")
    }
  }

  handleError(error: { statusText: string; }) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }

  updatePalette(palette: Palette) {
    palette.barcode = new PaletteBarcodePipe().transform(palette.serialNumber, palette.provider.id);

    if (palette.status === 1) {
      // La Palette est apte à être palettisée.
      palette.status = 2; // Palettisé
      palette.idLivraison = this.livraison.id;

      let scannedPaletteModele = this.orderPalettes.filter(p => p.modelePalette === palette.modele);
      let scannedPaletteModeleName = new PaletteModelePipe().transform(palette.modele);

      if (scannedPaletteModele.length === 1) {
        let palettesModeleScanned = this.palettes.filter((p: { modele: number; }) => p.modele === palette.modele);
        let nbPalettesModeleLimit = scannedPaletteModele[0].nbPalettes;

        if (palettesModeleScanned.length < nbPalettesModeleLimit) {
          if (!this.palettes.find((c: { barcode: string; }) => c.barcode === palette.barcode)) {
            this.palettes.push(palette);
            this.updatedLivraison.emit({livraison: this.livraison, palettes: this.palettes} );
    
            this.palettesModeleK7 = this.palettes.filter((p: { modele: number; }) => p.modele === 1);
            this.palettesModeleCustom = this.palettes.filter((p: { modele: number; }) => p.modele === 2);
            this.palettesModeleP3P = this.palettes.filter((p: { modele: number; }) => p.modele === 3);
            this.palettesModeleP4P = this.palettes.filter((p: { modele: number; }) => p.modele === 4);
          } else {
            // this.alertWarning(palette.barcode + ' déjà présente dans la Livraison.');
            this.toastWarning(palette.barcode + ' déjà présente dans la Livraison.', 'Attention', "Palette déjà scannée.")
          }
        } else {
          // this.alertWarning('Les Palettes "' + scannedPaletteModeleName + '" ont atteint le nombre limite pour la Livraison.');
          this.toastWarning('Les Palettes "' + scannedPaletteModeleName + '" ont atteint le nombre limite pour la Livraison.', "Attention", "Nombre maximal de palettes atteint.")
        }
      } else {
        // this.alertWarning('Les Palettes de "' + scannedPaletteModeleName + '" ne sont pas ajoutables à cette Livraison.');
        this.toastWarning('Les Palettes de "' + scannedPaletteModeleName + '" ne sont pas ajoutables à cette Livraison.', "Attention", "Palette non compatibles.")
      }
      this.paletteService.updatePalette(palette.id, palette, this.token).subscribe(
        {
          next: data => console.log(data),
          error: err => this.handleError(err),
          complete: () => console.log('palette updated')
        }
      )
    } else {
      // this.alertWarning('La Palette ' + palette.barcode + ' n\'est pas encore prêt à être ajouté à une Livraison.');
      this.toastWarning('La Palette ' + palette.barcode + ' n\'est pas encore prêt à être ajouté à une Livraison.', 'Attention', 'Palette non prête.')
    }
  }

  /*@HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Shift' && event.key !== 'Enter') {

      this.scannedBarcode += event.key;

      if (this.scannedBarcode.length === 10) {
        // console.log('this.scannedBarcode', this.scannedBarcode);
        // console.log('this.scannedBarcode.length', this.scannedBarcode.length);

        // console.log('this.livraison.status', this.livraison.status);
        // console.log('this.palettes.length', this.palettes.length);
        // console.log('this.livraison.nbPalettes', this.livraison.nbPalettes);

        if (!this.isAdmin &&
          (this.livraison && this.palettes.length >= 0 && this.livraison.nbPalettes > 0
            && this.palettes.length <= this.livraison.nbPalettes - 1)
          || (this.livraison.status === 0 && this.livraison.nbPalettes === -1) ) {

          // console.log('this.inputBarcode', this.scannedBarcode);

          this.scannedBarcode = (isQwerty(this.scannedBarcode)) ? convertToAzerty(this.scannedBarcode) : this.scannedBarcode;
          this.scannedBarcode = this.scannedBarcode.toUpperCase();

          if (isCorrectPaletteSN(this.scannedBarcode)) {
            document.getElementById('scanStatus').innerHTML = 'Code-barre scanné : ' + this.scannedBarcode;

            this.sendBarcodeNumber(this.scannedBarcode);
          } else {
            // this.alertDanger('Le code-barre ne correspond pas à un code-barre d\'une Palette.');
            this.toastDanger('Le code-barre ne correspond pas à un code-barre d\'une Palette.', "Erreur", "Code-barre non lié à une palette");
          }
        } else {
          if (this.palettes.length === this.livraison.nbPalettes) {
            // this.alertWarning('La Livraison est déjà complète.');
            this.toastWarning('La Livraison est déjà complète.', "Attention", "Livraison complète");
          } else {
            // this.alertDanger('Vous n\'avez pas la permission de scanner des codes-barres');
            this.toastDanger('Vous n\'avez pas la permission de scanner des codes-barres', "Erreur", "Scan non permis");
          }
        }

        this.scannedBarcode = '';
      } else if (this.scannedBarcode.length > 10) {
        // this.alertDanger('Code-barre non reconnu.');
        this.toastDanger('Code-barre non reconnu.', "Erreur", "Code-barre non reconnu");
        this.scannedBarcode = '';
      }
    }
  }*/

  onKeyBarcode(input: any) {
    if (input.value.length === 10) {
      const barcodeRaw = input.value;

      const theBarcode = (isQwerty(barcodeRaw)) ? convertToAzerty(barcodeRaw) : barcodeRaw;
      input.value = theBarcode;

      if (isCorrectPaletteSN(theBarcode.toUpperCase())) {
				
				if (!this.isAdmin) {
					this.sendBarcodeNumber(theBarcode.toUpperCase());
				}
      } else {
        // this.alertDanger('Le code-barre ne correspond pas à un code-barre de Palette.');
        this.toastDanger('Le code-barre ne correspond pas à un code-barre de Palette.', 'Danger', 'Code-barre incompatible.')
      }

      input.value = '';
    } else if (input.value.length > 10) {
      // this.alertDanger('Code-barre non reconnu.');
      input.value = '';
      this.toastDanger('Code-barre non reconnu.', "Erreur", "Mauvais code-barre.")
    }
  }

  removePalette(event: any = {}) {

    const target = event.currentTarget;
    const targetBarcode = target.parentElement.previousElementSibling.value;

    const pElement = target.parentElement.parentElement;
    const pclassAttr = pElement.attributes.class;

    const listPalettes = this.palettes.filter(function(palette: { barcode: any; }) {
      return palette.barcode !== targetBarcode;
    });

    pElement.remove();

    this.palettes = listPalettes;

    this.updatedLivraison.emit({livraison: this.livraison, palettes: this.palettes} );
    
    this.palettesModeleK7 = this.palettes.filter((p: { modele: number; }) => p.modele === 1);
    this.palettesModeleCustom = this.palettes.filter((p: { modele: number; }) => p.modele === 2);
    this.palettesModeleP3P = this.palettes.filter((p: { modele: number; }) => p.modele === 3);
    this.palettesModeleP4P = this.palettes.filter((p: { modele: number; }) => p.modele === 4);
  }

  openPaletteDetail(event: any = {}, paletteId: number) {
    const target = event.currentTarget;

    this.router.navigate([`/palette/${paletteId}`]);
  }
}

function isCorrectPaletteSN(barcodeStr: string) {

  const result = /^(([PA]){2}\d{2}\d{6})$/.test(barcodeStr);
  return result;
}

function isQwerty(barcodeStr: string) {
  const re1 = '(.)';	// Any Single Character 1
  const re2 = '(\\d+)';	// Integer Number 1

  const p = new RegExp(re1 + re2, 'i');
  const m = p.exec(barcodeStr);
  return m === null;
}

function convertToAzerty(barcodeQwerty: string) {

  let convertStr = '';

  for (let i = 0; i < barcodeQwerty.length; i++) {
    switch (barcodeQwerty[i]) {
      case '&' :
        convertStr += '1';
        break;
      case 'é' :
        convertStr += '2';
        break;
      case '"' :
        convertStr += '3';
        break;
      case '\'' :
        convertStr += '4';
        break;
      case '(' :
        convertStr += '5';
        break;
      case '§' :
        convertStr += '6';
        break;
      case 'è' :
        convertStr += '7';
        break;
      case '!' :
        convertStr += '8';
        break;
      case 'ç' :
        convertStr += '9';
        break;
      case 'à' :
        convertStr += '0';
        break;
      case 'Q' :
        convertStr += 'A';
        break;
      case 'W' :
        convertStr += 'Z';
        break;
      case 'A' :
        convertStr += 'Q';
        break;
      case 'Z' :
        convertStr += 'W';
        break;
      case 'M' :
        convertStr += '?';
        break;
      case '?' :
        convertStr += 'M';
        break;
      default:
        convertStr += barcodeQwerty[i];
    }
  }
  return convertStr;
}
