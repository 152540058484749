<div class="container">
  <main role="main" class="container">
    <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
      <div class="lh-100">
        <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createDemandeLivraison()">Demander une Livraison</button>
      </div>
    </div>
    <div class="my-3 p-3 bg-white rounded shadow-sm">
      <h3 class="border-bottom border-gray pb-2 mb-0">Mes demandes en cours de création ({{ demandesLivraisonsInCourses.length }})</h3>
      <div class="media row p-2">
        <ng-container *ngFor="let demande of demandesLivraisonsInCourses">
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <a class="btn btn-outline-secondary ps-5 pe-5 mb-2" href="#" [routerLink]="['/demande-livraison', demande.id]">
              {{ demande.serialNumber | demandeLivraisonBarcode: demande.franchise.id }}<br><br>

              <!-- TODO ISADMIN -->
              <ng-container *ngIf="demande.franchise">
                {{demande.franchise.nomPart.toUpperCase()}}<br>
                {{demande.franchise.prenomPart}}<br>
                [{{demande.franchise.initiales.toUpperCase()}}]<br><br>
              </ng-container>

              {{demande.creationDatetime | date:'dd MMM y'}}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="my-3 p-3 bg-white rounded shadow-sm">
      <h3 class="border-bottom border-gray pb-2 mb-0">Mes demandes en attente ({{ demandesLivraisonsPending.length }})</h3>
      <div class="media row p-2">
        <ng-container *ngFor="let demande of demandesLivraisonsPending">
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <a class="btn btn-outline-warning ps-5 pe-5 mb-2" href="#" [routerLink]="['/demande-livraison', demande.id]">
              {{ demande.serialNumber | demandeLivraisonBarcode: demande.franchise.id }}<br><br>

              <!-- TODO ISADMIN -->
              <ng-container *ngIf="demande.franchise">
                {{demande.franchise.nomPart.toUpperCase()}}<br>
                {{demande.franchise.prenomPart}}<br>
                [{{demande.franchise.initiales.toUpperCase()}}]<br><br>
              </ng-container>

              {{demande.creationDatetime | date:'dd MMM y'}}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="my-3 p-3 bg-white rounded shadow-sm">
      <h3 class="border-bottom border-gray pb-2 mb-0">Mes demandes validés ({{ demandesLivraisonsValidated.length }})</h3>
      <div class="media row p-2">
        <ng-container *ngFor="let demande of demandesLivraisonsValidated">
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <a class="btn btn-outline-success ps-5 pe-5 mb-2" href="#" [routerLink]="['/demande-livraison', demande.id]">
              {{ demande.serialNumber | demandeLivraisonBarcode: demande.franchise.id }}<br><br>

              <!-- TODO ISADMIN -->
              <ng-container *ngIf="demande.franchise">
                {{demande.franchise.nomPart.toUpperCase()}}<br>
                {{demande.franchise.prenomPart}}<br>
                [{{demande.franchise.initiales.toUpperCase()}}]<br><br>
              </ng-container>

              {{demande.creationDatetime | date:'dd MMM y'}}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </main>
</div>
<!-- <div *ngIf="this.demandesLivraisons.length >= 1">demandes</div> -->
