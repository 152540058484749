import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FranchiseService } from '../services/franchise.service';

@Component({
  selector: 'app-livraisons-adresses-list',
  templateUrl: './livraisons-adresses-list.component.html',
  styleUrls: ['./livraisons-adresses-list.component.css']
})
export class LivraisonsAdressesListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;
  error: string = '';
  errorHandled: any;

  adressesLivraison: any[] = [];

  constructor(private router: Router, private authService: AuthService, private franchiseService: FranchiseService) { }

  ngOnInit(): void {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }

      if (this.isAdmin) {
        this.loadFranchisesAdressesLivraison();
      } else {
        this.router.navigate([`/`]);
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadFranchisesAdressesLivraison() {
    this.franchiseService.getFranchisesAdressesLivraison(this.token)
      .subscribe(
        {
          next: data => this.handleFranchisesAdressesLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('adresse livraison franchise loaded!')
        }
      );
  }

  handleFranchisesAdressesLivraisonServerResponse(response: any = {}) {

    if (response.success) {
      console.log("response", response.adresses)
      this.adressesLivraison = response.adresses;
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleError(error: string) {
    console.error(error);
    this.error = error;
  }
}
