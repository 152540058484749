<main role="main">
    <div class="primary-block p-3 mb-4 shadow rounded-3">
      <div class="container">
        <div *ngIf="carton">
          <h1 class="display-4">Carton {{carton.serialNumber | cartonBarcode: carton.provider.id}}</h1>
          <p class="lead">Créé le {{carton.creationDate | date:'dd MMMM y à HH:mm'}}</p>
  
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Statut</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{carton.status | cartonStatus}}</h6>
                </div>
              </div>
            </div>
  
            <ng-container *ngIf="carton.type">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Type</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{carton.type | cartonType}}</h6>
                  </div>
                </div>
              </div>
            </ng-container>
  
            <ng-container *ngIf="carton.idPalette && palette">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Palette</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      <a class="btn btn-secondary btn-sm" href="#" [routerLink]="['/palette', palette.id]" role="button">{{palette.serialNumber | paletteBarcode: palette.provider.id}}</a>
                    </h6>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
  
          <ng-container *ngIf="(!isAdmin && carton && carton.products?.length === carton.cartonSize && carton.status === 0)">
            <br>
            <div class="row">
              <div class="d-grid gap-2">
                <button appNoDblClick (click)="saveCarton($event)" class="btn btn-outline-success btn-lg" type="button">
                  <div class="d-grid gap-2"></div>
                	<i class="bi bi-save"></i>
                Finaliser le Carton
              </button>
              </div>
            </div>
          </ng-container>
          </div>
      </div>
    </div>
  
    <div *ngIf="carton">
      <app-scan-products [token]="token" [carton]="carton" (updatedCarton)="updateCarton($event)"></app-scan-products>
    </div>
  
    <div *ngIf="errorMessage">
      Erreur : {{ errorMessage }}
    </div>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </main>
  