import { Component, OnInit } from '@angular/core';
import {CartonService} from '../services/carton.service';
import {ProductService} from '../services/product.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {PaletteService} from '../services/palette.service';
import {Carton} from '../model/carton';
import { ScanProductsComponent } from '../scan-products/scan-products.component';

@Component({
  selector: 'app-carton-details',
  templateUrl: './carton-details.component.html',
  styleUrls: ['./carton-details.component.css'],
  providers: [ScanProductsComponent]
})
export class CartonDetailsComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  palette: any;
  carton: any;
  error: any;
  errorMessage: string = '';

  constructor(private cartonService: CartonService,
              private productService: ProductService,
              private paletteService: PaletteService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);

      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }
    
    const id = this.activatedRoute.snapshot.params['id'];
    this.loadCarton(id);
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadCarton(id: string) {
    this.cartonService.getCarton(id, this.token)
      .subscribe({
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('carton loaded!')
      });
  }

  loadPalette(id: number) {
    this.paletteService.getPalette(id, this.token)
      .subscribe({
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('palette loaded!')
      });
  }

  loadProductsFromCarton(idCarton: number) {
    this.cartonService.getProductsFromCarton(idCarton, this.token)
      .subscribe({
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('products from carton loaded')
      });
  }

  handleServerResponse(response: any) {
    if (response.success) {
      if (response.carton) {
        this.carton = response.carton;
        this.carton.products = [];

        this.loadProductsFromCarton(this.carton.id);

        if (this.carton.idPalette) {
          this.loadPalette(this.carton.idPalette);
        }
      }

      if (response.palette) {
        this.palette = response.palette;
        this.palette.cartons = [];
      }

      if (response.products) {
        if (this.carton) {
          this.carton.products = response.products.sort((a: { id: number; }, b: { id: number; }) => {
            return a.id - b.id;
          });

        }
      }

    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: { statusText: any; }) {
    this.error = error;
  }

  updateCarton(carton: Carton) {
    // (`Carton ${carton.id} [Type: ${carton.type}] updated with ${carton.products.length} products`);
    // console.log(JSON.stringify(carton));

    // console.log('carton', JSON.stringify(carton));
    // console.log('carton.products.length', carton.products.length);
    // console.log('carton.cartonSize', carton.cartonSize);
    // console.log('carton.status', carton.status);

    this.carton = carton;
  }

  saveCarton(input: any) {
    this.carton.status = 1;

    this.cartonService.updateCarton(parseInt(this.carton.id, 10), this.carton, this.token)
      .subscribe({
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('carton was updated!')
      });
    this.productService.addProducts(this.carton.products, this.token)
      .subscribe({
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('product was saved!')
      });
  }

  /*addBarcode(carton: Carton) {
    console.log(`Carton ${carton.id} [Type: ${carton.type}] updated with ${carton.products.length} products`);
    console.log(JSON.stringify(carton));

    this.carton = carton;
  }

  removeBarcode(carton: Carton) {
    console.log(`Carton ${carton.id} [Type: ${carton.type}] updated with ${carton.products.length} products`);
    console.log(JSON.stringify(carton));

    this.carton = carton;
  }*/

}
