import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'materielAsiType'
})
export class MaterielAsiTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value.toUpperCase()) {
      case '1':
        return 'Brochures';
      case '2':
        return 'Supports Cassettes';
      case '3':
        return 'Supports Baguettes';
      case '4':
        return 'Supports Canettes';
      case '5':
        return 'Supports Fusettes';
      default:
        return 'Inconnu';
    }
  }

}
