<main role="main">
    <div class="primary-block p-3 mb-4 shadow rounded-3">
      <div class="container">
        <div *ngIf="retour">
          <h1 class="display-4">Retour {{retour.serialNumber | retourBarcode: retour.provider.id}}</h1>
          <p *ngIf="retour.creationDate > '1970'" class="lead">Créée le {{retour.creationDatetime | date:'dd MMMM y à HH:mm'}}</p>
  
          <ng-container *ngIf="retour.status === 3">
              <p *ngIf="retour.scanStartDate > '1970'" class="lead">Début du scan le {{retour.scanStartDatetime | date:'dd MMMM y à HH:mm'}}</p>
              <p *ngIf="retour.scanFinishDate > '1970'" class="lead">Fin du scan le {{retour.scanFinishDatetime | date:'dd MMMM y à HH:mm'}}</p>
          </ng-container>
  
          <p *ngIf="retour.status === 4" class="lead">Partie en retour le {{retour.shipmentDatetime | date:'dd MMMM y à HH:mm'}}</p>
  
          <br>
  
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Statut</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{retour.status | retourStatus}}</h6>
                </div>
              </div>
            </div>
  
            <ng-container *ngIf="retour.franchise.initiales.length > 0; else noFranchise">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Franchisé</h5>
                    <ng-container *ngIf="isAdmin && retour.status === 0; else notAdmin">
                      <select [(ngModel)]="retour.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                        <option *ngFor="let f of franchises" [ngValue]="f.id">
                          <ng-container *ngIf="f.initiales.length > 0; else noFranchise">
                            {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                          </ng-container>
                          <ng-template #noFranchise>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">{{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]</h6>
                    </ng-template>
                  </div>
                </div>
              </div>

              <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Accessibilité 19T</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{retour.franchise.accessibilite_19T ? 'Oui' : 'Non'}}</h6>
                  </div>
                </div>
              </div> -->
            </ng-container>
            <ng-template #noFranchise>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Franchisé</h5>
                    <ng-container *ngIf="isAdmin && retour.status === 0; else notAdmin">
                      <select [(ngModel)]="retour.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                        <option *ngFor="let f of franchises" [ngValue]="f.id">
                          <ng-container *ngIf="f.initiales.length > 0; else noFranchise">
                            {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                          </ng-container>
                          <ng-template #noFranchise>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">{{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]</h6>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template> <!-- #noFranchise -->
  
            <ng-container *ngIf="retour.provider && (isAdmin || isCentre)">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Centre</h5>
  
                    <ng-container *ngIf="isAdmin && retour.status === 0; else notAdmin">
                      <select [(ngModel)]="retour.provider.id" (ngModelChange)="onChangeProvider($event)" name="providerSelect" class="form-select" id="providerSelect">
                        <option *ngFor="let p of providers" [ngValue]="p.id">{{p.nom}}</option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">{{retour.provider.nom}}</h6>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
  
          </div> <!-- row -->
  
          <br>
  
          <div *ngFor="let m of orderPalettes">
            <div class="col-md-11">
              <div class="input-group mb-3">
                <div >
                  <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
                </div>
                <select class="form-select" disabled>
                  <option selected value="{{m.modelePalette}}">
                    {{ m.modelePalette | paletteModele }}
                  </option>
                </select>
                <div class="nbPaletteBox" disabled>
                  <input autocomplete="false" class="form-control input-group-text nbPaletteValueBox" type="number" value="{{m.nbPalettes}}" readonly>
                </div>
              </div>
            </div>

            <div *ngIf="isAdmin && retour.status === 0" class="col-md-1" id="buttonsBox">
              <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton" style="visibility: visible;">
                Supprimer
              </button>
            </div>
          </div>

          <form *ngIf="isAdmin && retour.status === 0 && paletteModeles !== undefined" autocomplete="off">
            <div class="row" id="active-form-row">
              <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
                <div class="input-group mb-3">
                  <div >
                    <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
                  </div>
                  <select (change)="paletteChanged($event)" class="form-select" id="inputGroupSelectPalette">
                    <option selected>Sélectionner...</option>
                    <option *ngFor="let m of paletteModeles" value="{{m.id}}">{{m.nom}}</option>
                  </select>
                  <div  id="minusPalette">
                    <button (click)="minusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonMinusCircle" disabled>
                      <i class="bi bi-dash-circle"></i>
                    </button>
                  </div>
                  <div class="nbPaletteBox" id="nbPalettes">
                    <input autocomplete="new-password" (input)="nbPaletteInputed($event)" type="number" value="0" min="1" class="form-control input-group-text nbPaletteValueBox" id="nbPaletteValue" disabled>
                  </div>
                  <div  id="plusPalette">
                    <button (click)="plusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonPlusCircle" disabled>
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" id="buttonsBox">
                <button appNoDblClick (click)="validatePalette($event)" class="btn btn-outline-success me-3" type="button" id="addPaletteButton" disabled>
                  <!--<i class="bi bi-plus"></i>-->
                  Valider
                </button>
                <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton">
                  <!--<i class="bi bi-x-lg"></i>-->
                  Supprimer
                </button>
              </div>
            </div>
          </form>

          <ng-container>
            <div *ngIf="isAdmin && retour.status === 0 && retour.franchise && retour.franchise.id > 0 && retour.provider.id > 0 && retour.nbPalettes > 0">
              <div class="d-grid gap-2">
                <button appNoDblClick (click)="validateRetour()" class="btn btn-success btn-lg" type="button">
                  <i class="bi bi-save"></i>
                  Valider le Retour
                </button>
              </div>
              <br>
            </div>
          </ng-container>

          <ng-container *ngIf="retour.status !== 0">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="showPdfDemandeRetour()" class="btn btn-danger btn-lg" type="button">
                    <i class="bi bi-printer"></i>
                    Imprimer la Demande de Reprise
                  </button>
                </div>
              </div>
              <br>
          </ng-container>
    
          <ng-container *ngIf="retour.franchise && retour.provider.id > 0">
            <div *ngIf="retour.status === 1 && retour.franchise && products?.length > 0">
              <div class="d-grid gap-2">
                <button appNoDblClick (click)="saveRetour()" class="btn btn-success btn-lg" type="button">
                  <i class="bi bi-save"></i>
                  Finaliser le Retour
                </button>
              </div>
              <br>
            </div>
    
            <div *ngIf="retour.status === 2 && retour.franchise && products?.length > 0">
              <div class="d-grid gap-2">
                <button appNoDblClick (click)="sendRetour($event)" class="btn btn-success btn-lg" type="button">
                  <i class="bi bi-truck"></i>
                  Confirmer le Retour de {{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="isCentre && retour && (retour.status === 1 || retour.status === 2)">
            <hr>

            <app-scan-products-retour [token]="token" [retour]="retour" [centreProducts]="productsCentre" [retourDetailComponent]="this"></app-scan-products-retour>
          </ng-container>

          <ng-container *ngIf="(retour && retour.status > 0)">
            <hr>
  
            <app-scan-cartons-retour [token]="token" [retour]="retour" [productsFranchise]="productsFranchise" [productsCentre]="productsCentre"></app-scan-cartons-retour>
          </ng-container>
  
        </div>
  
      </div> <!-- container -->
    </div> 
  
    <div *ngIf="errorHandled">
      <div class="card text-white bg-danger mb-3 mx-auto" style="max-width: 18rem;">
        <div class="card-header">Erreur</div>
        <div class="card-body">
          <h5 class="card-title">{{ errorHandled.id }}</h5>
          <p class="card-text">
            {{ errorHandled.message }}
          </p>
        </div>
      </div>
    </div>
  
    <div *ngIf="error">
      <ng-container *ngIf="isAdmin">
        <br>
        <pre><code>{{ error | json }}</code></pre>
      </ng-container>
    </div>
  
    <canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
  
  </main>
  