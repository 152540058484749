<main role="main">
    <div class="jumbotron">
      <div class="container" *ngIf="isAdmin && adressesLivraison">
        <div class="table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th scope="col">Nom</th>
              <th scope="col">Prénom</th>
              <th scope="col">Franchisé</th>
              <th scope="col">Adresse</th>
              <th scope="col">Complément d'adresse</th>
              <th scope="col">Code Postal</th>
              <th scope="col">Ville</th>
              <th scope="col">Accessibilité 19T</th>
              <th scope="col">Date d'ajout</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let al of adressesLivraison">
              <tr>
                <td>{{al.nom}}</td>
                <td>{{al.prenom}}</td>
                <td>{{al.initiales}}</td>
                <td>{{al.adresse}}</td>
                <td>{{al.complement_adresse}}</td>
                <td>{{al.code_postale}}</td>
                <td>{{al.ville}}</td>
                <td class="text-center align-middle" *ngIf="al.accessibilite_19T == true; else elseBlock">
                  <i class="bi bi-check-lg"></i>
                </td>
                <ng-template #elseBlock>
                  <td class="text-center align-middle">
                    <i class="bi bi-x-lg"></i>
                  </td>
                </ng-template>
                <td>{{al.dateAjout | date: 'dd/MM/yyyy'}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
  
      </div>
    </div>
  
    <div *ngIf="errorHandled">
      <div class="card text-white bg-danger mb-3 mx-auto" style="max-width: 18rem;">
        <div class="card-header">Erreur</div>
        <div class="card-body">
          <h5 class="card-title">{{ errorHandled.id }}</h5>
          <p class="card-text">
            {{ errorHandled.message }}
          </p>
        </div>
      </div>
    </div>
  
    <div *ngIf="error">
      <ng-container *ngIf="isAdmin">
        <br>
        <pre><code>{{ error | json }}</code></pre>
      </ng-container>
    </div>
  
  </main>
  