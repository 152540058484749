import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DemandesLivraisonsService {

  demandesLivraisonsSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getDemandesLivraisons = (token: string) => {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/demandes-livraisons', { headers })
  }

  getDemandeLivraison = (id: number, token: string) => {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/demande-livraison/${id}`, { headers })
  }

  createDemandeLivraison = (data: any, token: string) => {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/demande-livraison', data, { headers })
      .pipe(
        map((res: any) => {
          this.demandesLivraisonsSubject.next(res);
          return res;
        })
      )
  }

  updateAdresseLivraison(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/demande-livraison/${id}/adresse`, data, { headers })
      .pipe(
        map((res: any) => {
          this.demandesLivraisonsSubject.next(res);
          return res;
        })
      );
  }

  updateDemande(id: number, data: any, token: string) {
    data.palettes = []; // TODO A revoir plus tard dans livraison-details pourquoi les Palettes sont remplies dans l'array
    console.log('updateLivraison => ', data);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/demande-livraison/${id}`, data, { headers })
      .pipe(
        map((res: any) => {
          this.demandesLivraisonsSubject.next(res);
          return res;
        })
      );
  }

  getDemandeLivraisonPalettesOrdered(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/demande-livraison/${id}/order/palettes`, { headers });
  }

  putOrderPalettes(id: string, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/demande-livraison/${id}/order/palettes/${data.modelePalette}`, data, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  deleteOrderPalettes(id: number, modelePalette: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/demande-livraison/${id}/order/palettes/${modelePalette}`, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
