<div class="form-floating">
	<select class="form-control" id="years-activity" name="years-activity" [(ngModel)]="yearSelected" (ngModelChange)="onChangeYearActivity()">
		<option *ngFor="let y of yearsActivity" [ngValue]="y">{{ y }}</option>
	</select>
	<label for="years-activity">Année d'activité</label>
</div>

<br>

<div *ngIf="weeksData">
	<table class="table table-bordered table-striped">
		<thead>
			<tr>
				<th scope="col">Semaines de {{ yearSelected }}</th>
				<th scope="col">Cassettes</th>
				<th scope="col">Canettes</th>
				<th scope="col">Baguettes</th>
				<th scope="col">Fusettes</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let week of weeksData">
				<tr>
					<th scope="row">
						Semaine N°{{ week.weekNumber }}<br>
						{{ week.rangeOfWeek.startOfWeek | date: 'dd/MM/yyyy' }} - 
						{{ week.rangeOfWeek.endOfWeek | date: 'dd/MM/yyyy' }}
					</th>

					<td>{{ week.cassette }}
						<ng-container *ngIf="week.cassette > 0">
							(≃ {{ (week.cassette / 672) | number:'1.0-2' }} palettes) 
							(≃ {{ (week.cassette / 14) | number:'1.0-2' }} cartons)
						</ng-container>
					</td>

					<td>{{ week.canette }}
						<ng-container *ngIf="week.canette > 0">
							(≃ {{ (week.canette / 24) | number:'1.0-2' }} cartons)
						</ng-container>
					</td>

					<td>{{ week.baguette }}
						<ng-container *ngIf="week.baguette > 0">
							(≃ {{ (week.baguette / 24) | number:'1.0-2' }} cartons)
						</ng-container>
					</td>

					<td>{{ week.fusette }}
						<ng-container *ngIf="week.fusette > 0">
							(≃ {{ (week.fusette / 42) | number:'1.0-2' }} cartons)
						</ng-container>
					</td>

				</tr>
			</ng-container>
		</tbody>
	</table>
</div>