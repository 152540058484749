<div class="container">

    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col">N° de Livraison</th>
        <th scope="col">Franchisé</th>
        <th scope="col">Statut</th>
        <th scope="col">Date de création</th>
        <th scope="col">Date de finalisation</th>
        <th scope="col">Date de départ en livraison</th>
        <th scope="col">Détail</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let livraison of livraisons">
        <tr>
          <th scope="row">{{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}</th>
  
          <td>
            <ng-container *ngIf="livraison.franchise; else noFranchise">
              {{livraison.franchise.initiales}}
            </ng-container>
            <ng-template #noFranchise>
              -
            </ng-template>
          </td>
  
          <td>{{livraison.status | livraisonAsiStatus}}</td>
          <td>{{livraison.creationDate | date:'dd MMMM y à HH:mm' : '+0200'}}</td>
          <td>
            <ng-container *ngIf="livraison.finalizationDate != ''; else no_date_finalization">
              {{livraison.finalizationDate | date:'dd MMMM y à HH:mm'}}
            </ng-container>
            <ng-template #no_date_finalization>
              -
            </ng-template>
          </td>
          <td>
            <ng-container *ngIf="livraison.shipmentDate != '' ; else no_date_shipment">
              {{livraison.shipmentDate | date:'dd MMMM y à HH:mm'}}
            </ng-container>
            <ng-template #no_date_shipment>-</ng-template>
          </td>
          <td>
            <a class="btn btn-outline-primary" href="#" [routerLink]="['/livraison-asi', livraison.id]">Voir le détail</a>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  
    <nav aria-label="historique livraison pagination">
      <ul class="pagination justify-content-center">
        <li class="page-item {{ currentPage === 1 ? 'disabled' : '' }}">
          <button class="page-link" tabindex="-1" appNoDblClick (click)="loadLivraisons(currentPage - 1)">Précédente</button>
        </li>
        <ng-container *ngFor="let page of pages">
          <li class="page-item{{ page === currentPage ? ' active' : '' }}">
            <button class="page-link" appNoDblClick (click)="loadLivraisons(page)">{{ page }}</button>
          </li>
        </ng-container>
        <li class="page-item {{ currentPage === pageCount ? 'disabled' : ''}}">
          <button class="page-link" appNoDblClick (click)="loadLivraisons(currentPage + 1)">Suivante</button>
        </li>
      </ul>
    </nav>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </div>
  