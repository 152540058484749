<div *ngIf="isAdmin">
  <h2>Profil de {{decodedToken.username}}</h2>

  <div *ngIf="decodedToken">
    <strong> Contenu du token</strong>
    <pre>
    {{ decodedToken | json }}
  </pre>
  </div>

  <div *ngIf="!decodedToken">
    Aucune donnée trouvée.
  </div>

  <!--<h3>{{adsTitle}}</h3>
  <div *ngFor="let job of jobs">
    <strong>{{job.title}}</strong>
    - publiée il y a {{job.publishdate | daysAgo}}
    - mise à jour il y a {{ job.lastupdate | daysAgo}}
    <div>
      {{job.description | truncate}}
    </div>
    <hr>
  </div>-->
</div>

<div *ngIf="!isAdmin">
  <h2>Profil de {{decodedToken.username}}</h2>

  <!--<h3>{{adsTitle}}</h3>
  <div *ngFor="let job of jobs">
    <strong>{{job.title}}</strong>
    - publiée il y a {{job.publishdate | daysAgo}}
    - mise à jour il y a {{ job.lastupdate | daysAgo}}
    <div>
      {{job.description | truncate : 10}}
    </div>
    <hr>
  </div>-->
</div>
