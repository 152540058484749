import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  title = 'BCP Logistique';
  
  token: string = '';
  bcpToken: any = {};
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  provider: any = null;
  userIsLoggedin: boolean = false;

  authService: AuthService;

  constructor(authService: AuthService, private router: Router, private location: Location, private ref: ChangeDetectorRef) {
    this.authService = authService; 
  }

  ngOnInit(): void {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      this.bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = this.bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      console.log("decodedToken", this.decodedToken)

      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'franchise') {
        this.isFranchise = true;
      }
      if (this.decodedToken && this.decodedToken.role.nom === 'centre') {
        this.isCentre = true;
      }
      this.provider = this.decodedToken.provider;

      this.ref.detectChanges();
    }

    const domainName = window.location.hostname;
    const navbar = document.getElementsByClassName('navbar')[0];

    switch (domainName) {
      case 'logistique.biocoldprocess.fr':
        break;
      case 'dev.logistique.biocoldprocess.fr': {
        this.title = this.title + ' [DEV]';
        navbar.classList.add('bg-red');
        navbar.classList.remove('bg-dark');
        break;
      }
      case 'dev-deploy.logistique.biocoldprocess.fr': {
        this.title = this.title + ' [DEV-DEPLOY]';
        navbar.classList.add('bg-orange');
        navbar.classList.remove('bg-dark');
        break;
      }
      case 'deploy.logistique.biocoldprocess.fr': {
        this.title = this.title + ' [DEPLOY]';
        navbar.classList.add('bg-green');
        navbar.classList.remove('bg-dark');
        break;
      }
      default:
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  logout() {
    this.authService.logout();
    location.reload();
  }

}
