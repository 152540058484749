export class Franchise {
  id: number;
  abreviation_juridique: string;
  nom: string;
  siren: number;
  TVA_intracom: string;
  prenomPart: string;
  nomPart: string;
  adresse: string;
  complement_adresse: string;
  codePostal: string;
  villeLivraison: string;
  ajoutAdresseDate: number;
  ajoutAdresseDatetime: string;
  initiales: string;
  mail_part: string;
  TelPortPart: string;
  accessibilite_19T: boolean;

  constructor(id: number, abreviation_juridique: string, nom: string, siren: number, TVA_intracom: string, prenomPart: string,
    nomPart: string, adresse: string, complement_adresse: string, codePostal: string, villeLivraison: string, ajoutAdresseDate: number, ajoutAdresseDatetime: string,
    initiales: string, mail_part: string, TelPortPart: string, accessibilite_19T: boolean) {
      this.id = id,
      this.abreviation_juridique = abreviation_juridique,
      this.nom = nom,
      this.siren = siren,
      this.TVA_intracom = TVA_intracom,
      this.prenomPart = prenomPart,
      this.nomPart = nomPart,
      this.adresse = adresse,
      this.complement_adresse = complement_adresse,
      this.codePostal = codePostal,
      this.villeLivraison = villeLivraison,
      this.ajoutAdresseDate = ajoutAdresseDate,
      this.ajoutAdresseDatetime = ajoutAdresseDatetime,
      this.initiales = initiales,
      this.mail_part = mail_part,
      this.TelPortPart = TelPortPart,
      this.accessibilite_19T = accessibilite_19T
  }
}
