import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import { DemandesLivraisonsService } from '../services/demandes-livraisons.service';
import { FranchiseService } from '../services/franchise.service';

@Component({
  selector: 'app-demandes-livraison-list',
  templateUrl: './demandes-livraison-list.component.html',
  styleUrls: ['./demandes-livraison-list.component.css']
})
export class DemandesLivraisonListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: any = null;
  userIsLoggedin: boolean = false;

  demandesLivraisons: [] = [];
  demandesLivraisonsInCourses: any[] = [];
  demandesLivraisonsPending: any[] = [];
  demandesLivraisonsValidated: any[] = [];
  demandeLivraisonCreated: any;
  franchise: any;

  constructor(private router: Router, private demandesLivraisonsService: DemandesLivraisonsService, private authService: AuthService, private franchiseService: FranchiseService) { }

  ngOnInit(): void {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      console.log('this.decodedToken', this.decodedToken)
      this.provider = this.decodedToken.provider;
      this.loadDemandesLivraisons();
      this.loadUserFranchise(this.decodedToken.idPart)
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadFranchise(id: number) {
    this.franchiseService.getFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchises(data),
          error: err => console.error(err),
          complete: () => console.log('franchises demandes livraisons loaded!')
        }
      )
  }

  loadDemandesLivraisons = () => {
    this.demandesLivraisonsService.getDemandesLivraisons(this.token)
      .subscribe(
        {
          next: data => this.displayDemandesLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('demandes livraisons loaded!')
        }
      )
  }

  displayDemandesLivraisons(response: any = {}) {
    if(response.success) {
      this.demandesLivraisons = response.demandes;
      console.log("DEMANDES", this.demandesLivraisons)

      this.demandesLivraisonsInCourses = this.demandesLivraisons.filter((demande: {statut: number}) => demande.statut === 0);
      this.demandesLivraisonsPending = this.demandesLivraisons.filter((demande: {statut: number}) => demande.statut === 1);
      this.demandesLivraisonsValidated = this.demandesLivraisons.filter((demande: {statut: number}) => demande.statut === 2);
    }
  }

  loadUserFranchise(idPart: string) {
    this.franchiseService.getFranchiseByInitiales(idPart, this.token)
      .subscribe(
        {
          next: (data: any) => this.handleFranchise(data),
          error: (err: any) => console.error(err),
          complete: () => console.log('livraison loaded!')
        }
      )
  }

  handleFranchise(response: any = {}) {
    if(response.success) {
      console.log("OK")
      this.franchise = response.franchise
      console.log("THIS FRANCHISE", this.franchise)
    }
  }

  handleFranchises(response: any = {}) {
    if(response.success) {
      console.log("OK")
      return response.franchise
      // this.franchise = response.franchise
      console.log("THIS FRANCHISES", this.franchise)
    }
  }

  createDemandeLivraison() {
    console.log("decodedToken", this.decodedToken)
    const demandeLivraison: any = {
      provider: { id: this.provider.id },
      serialNumber: Date.now().toString().substring(0,4),
      franchise: this.franchise,
      statut: 0,
      nbPalettes: 0,
      palettes: [],
      creationDatetime: new Date().toISOString().slice(0, 19).replace('T', ' ').toLocaleString(),
      validationDatetime: null
    }

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.demandesLivraisonsService.createDemandeLivraison(demandeLivraison, token)
      .subscribe(
        {
          next: data => this.handleServerResponse(data),
          error: err => console.error(err),
          complete: () => console.log('demande livraison created!')
        }
      )
  }

  handleServerResponse(response: { success: any; demandeLivraison: any; message: string; }) {
    console.log('handleServerResponse', response);
    if (response.success) {
      this.demandeLivraisonCreated = response.demandeLivraison;
      this.router.navigateByUrl(`/demande-livraison/${this.demandeLivraisonCreated.insertId}`);
    } else {
      console.log("ERROR");
    }
  }

}
