import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {FournituresService} from '../services/fournitures.service';

@Component({
  selector: 'app-fournitures',
  templateUrl: './fournitures.component.html',
  styleUrls: ['./fournitures.component.css']
})
export class FournituresComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: any = null;
  providers: any = [];
  fournitures: any = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  errorMessage: string = '';

  constructor(private router: Router, private fournituresService: FournituresService, private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      console.log('this.provider', this.provider);
      // admin needs to see all ads
      if (this.isAdmin) {
        this.loadFournitures();
      } else {
        this.loadFournitures();
      }

      /*this.fournituresService.fournituresSubject.subscribe(data => {
        this.fournitures = [data, ...this.fournitures];
      });*/
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadFournitures() {
    this.fournituresService.getFournitures(this.token)
      .subscribe({
        next: (data) => this.displayLivraisons(data),
        error: (err) => console.error(err),
        complete: () => console.log("fournitures loaded!")
      });
  }

  displayLivraisons(response: any = {}) {
    // console.log('response ', response);

    if (response.success) {
      console.log('fournitures ', response.fournitures);
      this.fournitures = response.fournitures;

      const providersFournitures = this.fournitures.map((fourniture: { provider: any; }) => fourniture.provider);
      console.log('providersFournitures', providersFournitures);

      const uniqueFournituresArray = (data: any[]) => [...new Set(data.map(o => JSON.stringify(o)))].map(s => JSON.parse(s.toString()));
      this.providers = uniqueFournituresArray(providersFournitures);
      console.log('this.providers', this.providers);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  modifyFourniture(input: any) {
    console.log('modifyFourniture', input);
    // this.router.navigate([`/fourniture/modifier`]);

    const materielType = input.srcElement.id.toString().split('-')[1];
    console.log('materielType', materielType);

    // const valueChrome = input.path[2].firstChild.value.toString();
    console.log('input.srcElement.parentElement.parentElement', input.srcElement.parentElement.parentElement);
    console.log('input.srcElement.parentElement.parentElement.children[0]', input.srcElement.parentElement.parentElement.children[0]);
    console.log('input.srcElement.parentElement.parentElement.children[0].value', input.srcElement.parentElement.parentElement.children[0].value);
    let value = input.srcElement.parentElement.parentElement.children[0].value.toString();
    console.log('raw value', value);

    if (value.length > 0) {
      value = parseInt(value, 10);
      console.log('int value', value);
    } else {
      console.log('La nombre saisie est incorrect.');
    }
  }

  passerCommande(input: any) {
    console.log('passerCommande', input);
    // this.router.navigate([`/fourniture/commander`]);

    const materielType = input.srcElement.id.toString().split('-')[1];
    console.log('materielType', materielType);

    // const valueChrome = input.path[2].firstChild.value.toString();
    let value = input.srcElement.parentElement.parentElement.children[0].value.toString();
    console.log('raw value', value);

    if (value.length > 0) {
      value = parseInt(value, 10);
      console.log('int value', value);
    } else {
      console.log('La nombre saisie est incorrect.');
    }
  }

  showCommandes() {
    this.router.navigate([`/fournitures/commandes`]);
  }
}
