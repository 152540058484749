import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  TOKEN_NAME = 'bcp-token';
  decodedToken = null;

  constructor(private http: HttpClient) {}

  login(credentials: any) {
     // console.log('login', JSON.stringify(credentials));
     // console.log(environment.BASE_URL);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json'
    });

    const options = { headers: headers };
    // console.log(options);
    return this.http.post(environment.BASE_URL + '/auth/login', credentials, options);
  }

  userIsLoggedIn() {
    return !!localStorage.getItem(this.TOKEN_NAME);
  }

  logout() {
    localStorage.removeItem(this.TOKEN_NAME);
    localStorage.removeItem('token');
  }

  register(credentials: any) {
    // console.log('register credentials: ', credentials);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json'
    });

    const options = { headers: headers };
    return this.http.post(environment.BASE_URL + '/auth/register', credentials, options);
  }

  decodeToken(token: string) {
    /*let jsonDecode1 = jwtDecode(token);
    console.log('jsonDecode1', jsonDecode1);
    console.log('jsonDecode1 json', JSON.stringify(jsonDecode1));

    let jsonDecode2 = JSON.parse(atob(token.split('.')[1]));
    console.log('jsonDecode2', jsonDecode2);
    console.log('jsonDecode2 json', JSON.stringify(jsonDecode1));*/

    // return jwtDecode(token);
    // return JSON.parse(atob(token.split('.')[1]));

		return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  }

  addBasicHeader() {
    return new HttpHeaders({
      'content-type': 'application/json; charset=utf-8',
      'accept': 'application/json'
    });
  }

  addAuthorizationHeader(token: string) {
    // console.log("TOKEN", token)
    let headers = new HttpHeaders({
      'content-type': 'application/json; charset=utf-8',
      'accept': 'application/json',
      'authorization': 'Bearer ' + token
    });
    // console.log("header autho", headers);
    return headers;
  }

  // addNAuthorizationHeader(token) {
  //   let headers = new HttpHeaders({
  //       'Content-Type':  'application/json; charset=utf-8',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //   });
  //   console.log("httpOptions", headers);
  //   return headers;
  // }

}
