<div class="container">
  <main role="main" class="container">

    <ng-container *ngIf="isAdmin">

      <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
        <div *ngIf="isAdmin" class="lh-100">
          <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createLivraison()">Préparer une Livraison ASI</button>
        </div>

        <div class="lh-100">
        <button type="button" class="btn btn-lg btn-success" appNoDblClick (click)="showHistory()">Historique des Livraisons ASI</button>
        </div>
      </div>

  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En cours de création ({{ livraisons0.length }})</h3>

        <ng-container *ngIf="livraisons0.length > 0">
          <div class="media row p-2">
            <ng-container *ngFor="let livraison of livraisons0">
              <div class="col-sm-auto col-md-auto col-lg-auto col-xl-auto">
                <a class="btn btn-outline-secondary m-2 p-2 bloc" href="#"
                  [routerLink]="['/livraison-asi', livraison.id]">
                  {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>

                  <ng-container *ngIf="livraison.franchise">
                    {{livraison.franchise.nomPart.toUpperCase()}}<br>
                    {{livraison.franchise.prenomPart}}<br>
                    [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                  </ng-container>

                  {{livraison.creationDate | date:'dd MMM y'}}
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">Livraison prête à partir ({{ livraisons1.length }})</h3>

        <ng-container *ngIf="livraisons1.length > 0">
          <div class="media row p-2">
            <ng-container *ngFor="let livraison of livraisons1">
              <div class="col-sm-auto col-md-auto col-lg-auto col-xl-auto">
                <a class="btn btn-outline-success m-2 p-2 bloc" href="#"
                  [routerLink]="['/livraison-asi', livraison.id]">
                  {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}<br><br>

                  <ng-container *ngIf="livraison.franchise">
                    {{livraison.franchise.nomPart.toUpperCase()}}<br>
                    {{livraison.franchise.prenomPart}}<br>
                    [{{livraison.franchise.initiales.toUpperCase()}}]<br><br>
                  </ng-container>

                  {{livraison.creationDate | date:'dd MMM y'}}
                </a>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    
    </ng-container>

  </main>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</div>