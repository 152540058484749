<div class="panel-body" *ngIf="userIsLoggedin">
    <form [formGroup]="form" (ngSubmit)="createPalette(form.value)">
      <div class="col-sm-6">
        <label for="description">description</label><br>
        <textarea name="description"cols="30" rows="10" formControlName="description"></textarea>
      </div>
  
      <div class="col-sm-6">
        <button type="submit" class="btn btn-success">Démarrer</button>
      </div>
  
    </form>
  </div>
  
  <div *ngIf="!userIsLoggedin">
    <h2>La création d'une Palette est réservée aux utilisateurs connnectés.</h2>
    <div>
      <a href="#" routerLink="/login" class="btn btn-secondary btn-lg" role="button" aria-disabled="true">Se connecter</a>
    </div>
  </div>
  