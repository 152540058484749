import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {PaletteService} from '../services/palette.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-palette-add-form',
  templateUrl: './palette-add-form.component.html',
  styleUrls: ['./palette-add-form.component.css']
})
export class PaletteAddFormComponent implements OnInit {

  form: UntypedFormGroup;
  userIsLoggedin: boolean = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private paletteService: PaletteService, private authService: AuthService, public f: UntypedFormGroup) {
    this.form = f
   }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: -1,
      type: '',
      franchise: '',
      creationDate: '',

    });

    this.checkUserIsLoggedin();
  }

  checkUserIsLoggedin() {
    if(this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  createPalette(data: any) {
    let palette = {
      creationDate: Date.now()
    };

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.paletteService.addPalette(palette, token).subscribe();
    this.router.navigateByUrl("/palettes");
  }

}