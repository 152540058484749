import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { LivraisonAsi } from '../model/livraison_asi';

@Injectable({
  providedIn: 'root'
})
export class LivraisonAsiService {

  initialLivraisons: LivraisonAsi[] = [];
  livraisons: LivraisonAsi[] = [];
  livraisonsSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  getLivraisons(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/livraisons-asi', { headers });
  }

  getLivraisonsHistory(page: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/livraisons-asi/history/' + page, { headers });
  }

  addLivraison(data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/livraison-asi', data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );

  }

  updateAdresseLivraison(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison-asi/${id}/adresse`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  updateLivraison(id: number, data: any, token: string) {    
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison-asi/${id}`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );

  }

  getLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison-asi/${id}`, { headers });
  }

  getLivraisonMateriel(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison-asi/${id}/materiel`, { headers });
  }

  /*postLivraisonMateriel(id, data, token) {
    console.log("postLivraisonMateriel ASI => ", data);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison-asi/${id}/materiel`, data, { headers })
      .pipe(
        map((res: any) => {
          console.log(res);
          return res;
        })
      );
  }*/

  putMateriel(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison-asi/${id}/materiel/${data.typeMateriel}`, data, { headers })
      .pipe(
        map((res: any) => {
          console.log(res);
          return res;
        })
      );
  }
  deleteMateriel(id: number, typeMateriel: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/livraison-asi/${id}/materiel/${typeMateriel}`, { headers })
      .pipe(
        map((res: any) => {
          console.log(res);
          return res;
        })
      );
  }

  /*patchLivraisonMateriel(id, data, token) {
    console.log("patchLivraisonMateriel ASI => ", data);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.patch(environment.BASE_URL + `/api/livraison-asi/${id}/materiel`, data, { headers })
      .pipe(
        map((res: any) => {
          console.log(res);
          return res;
        })
      );
  }*/

  getDocument(barcode: string, type: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison-asi/${barcode}/document/${type}`, { headers });
  }

  addDocument(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison-asi/${id}/document`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  createBonLivraison(livraison: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison-asi/bonLivraison`, livraison, { headers });
  }

  deleteLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/livraison-asi/${id}`, { headers });
  }
}
