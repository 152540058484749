<div class="container">

    <main role="main" class="container">
  
      <ng-container *ngIf="!isAdmin">
        <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
          <div class="lh-100">
            <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createCarton()">Créer un Carton</button>
          </div>
        </div>
      </ng-container>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En cours de création ({{ cartonStatus0.length }})</h3>
  
        <ng-container *ngIf="cartonStatus0.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.cartonStatus0.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.cartonStatus0.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row p-2">
                      <ng-container *ngFor="let carton of p.cartonStatus0">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            <div class="media row p-2">
              <ng-container *ngFor="let carton of cartonStatus0">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
  
      <div class="bg-white rounded box-shadow p-3 mb-4">
        <h3 class="border-bottom border-gray pb-2 mb-0">Prêt à être palettisé ({{ cartonStatus1.length }})</h3>
  
        <ng-container *ngIf="cartonStatus1.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.cartonStatus1.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.cartonStatus1.length }})
                  </div>
                  <div class="card-body">
                    
                    <ng-container *ngIf="p.cartonsCassettes && p.cartonsCassettes.length > 0">
                      <div class="media p-2">
                        <div class="card">
                          <div class="card-header">
                            Cassettes ({{ p.cartonsCassettes.length }})
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <ng-container *ngFor="let carton of p.cartonsCassettes">
                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                  <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div><br>
                      </div>
                    </ng-container>
  
                    <ng-container *ngIf="p.cartonsBaguettes && p.cartonsBaguettes.length > 0">
                      <div class="media p-2">
                        <div class="card">
                          <div class="card-header">
                            Baguettes ({{ p.cartonsBaguettes.length }})
                          </div>
                          <div class="card-body">
                            <div class="media row">
                              <ng-container *ngFor="let carton of p.cartonsBaguettes">
                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                  <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div><br>
                      </div>
                    </ng-container>
  
                    <ng-container *ngIf="p.cartonsCanettes && p.cartonsCanettes.length > 0">
                      <div class="media p-2">
                        <div class="card">
                          <div class="card-header">
                            Canettes ({{ p.cartonsCanettes.length }})
                          </div>
                          <div class="card-body">
                            <div class="media row">
                              <ng-container *ngFor="let carton of p.cartonsCanettes">
                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                  <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div><br>
                      </div>
                    </ng-container>
  
                    <ng-container *ngIf="p.cartonsFusettes && p.cartonsFusettes.length > 0">
                      <div class="media p-2">
                        <div class="card">
                          <div class="card-header">
                            Fusettes ({{ p.cartonsFusettes.length }})
                          </div>
                          <div class="card-body">
                            <div class="media row">
                              <ng-container *ngFor="let carton of p.cartonsFusettes">
                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                  <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div><br>
                      </div>
                    </ng-container>
  
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            
            <ng-container *ngIf="cartonsCassettes && cartonsCassettes.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Cassettes ({{ cartonsCassettes.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let carton of cartonsCassettes">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="cartonsBaguettes && cartonsBaguettes.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Baguettes ({{ cartonsBaguettes.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let carton of cartonsBaguettes">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-3 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="cartonsCanettes && cartonsCanettes.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Canettes ({{ cartonsCanettes.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let carton of cartonsCanettes">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="cartonsFusettes && cartonsFusettes.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Fusettes ({{ cartonsFusettes.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let carton of cartonsFusettes">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/carton', carton.id]">{{carton.serialNumber | cartonBarcode: carton.provider.id}}<br><br>{{carton.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
          </ng-template>
        </ng-container>
      </div>
  
  
    </main>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </div>
    