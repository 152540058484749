import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { weekNumber } from 'weeknumber';
import { AuthService } from '../services/auth.service';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-stats-year',
  templateUrl: './stats-year.component.html',
  styleUrls: ['./stats-year.component.css']
})
export class StatsYearComponent implements OnInit, AfterViewInit {
	token: string = '';
  bcpToken: any = {};
  decodedToken: any = {};
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCentre: boolean = false;
  provider: any = null;
  userIsLoggedin: boolean = false;

  error: string = '';

  errorMessage: string = '';

	yearsActivity: string[] = [];
	yearSelected: number = 0;

	weeksNumberInYear: number = 0;

	weeksData: any = [];

	currentYear: number = new Date().getFullYear();
	currentWeek: number = weekNumber(new Date())

	constructor(private authService: AuthService, private statsService: StatsService) {	}

	ngOnInit(): void {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      this.bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = this.bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      
      this.provider = this.decodedToken.provider;
    }
  }
	ngAfterViewInit(): void {
		this.getYearsActivity();
  }
	
  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }
	
  getYearsActivity() {
    this.statsService.getYearsActivity(this.token)
      .subscribe({
        next: data => this.handleYearsActivity(data),
        error: err => console.error(err),
        // complete: () => console.log("get years activity")
      });
  }

	handleYearsActivity(response: any = {}) {
    if (response.success) {
			// console.log(response)
			this.yearsActivity = response.yearsActivity;

			if (this.yearSelected == 0) {
				this.yearSelected = parseInt(this.yearsActivity[0], 10);
				// console.log('page init load | this.yearSelected', this.yearSelected)
				this.onChangeYearActivity()
			}
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }

	onChangeYearActivity() {
		// console.log('onChangeYearActivity | yearSelected', this.yearSelected);
		this.weeksNumberInYear = getWeeksInYear(this.yearSelected);
		// console.log('this.weeksNumberInYear', this.weeksNumberInYear);
		
		// let weeksNumberInYear2 = this.weeksNumberInYear;
		this.weeksData = [];

		for (let index = 1; index <= this.weeksNumberInYear; index++) {
			// console.log('index', index);
			let rangeOfWeek = getDateRangeOfWeek(index, this.yearSelected);
			// console.log('rangeOfWeek', rangeOfWeek);

			let week = {
				weekNumber: index,
				year: this.yearSelected,
				rangeOfWeek,
				cassette: 0,
				canette: 0,
				baguette: 0,
				fusette: 0
			};

			if (week.year == this.currentYear && week.weekNumber > this.currentWeek) {
				/*
				 * Exclusion des semaines à venir par rapport à la date d'aujourd'hui
				*/
			} else {
				this.weeksData.push(week);
			}
		}

		this.getActivityFromYear(this.yearSelected, 'cassette');
		this.getActivityFromYear(this.yearSelected, 'canette');
		this.getActivityFromYear(this.yearSelected, 'baguette');
		this.getActivityFromYear(this.yearSelected, 'fusette');
	}

	
  getActivityFromYear(year: number, typeFiltre: string) {
    this.statsService.getActivityFromYear(year, typeFiltre, this.token)
      .subscribe({
        next: data => this.handleActivityFromYear(data),
        error: err => console.error(err),
        // complete: () => console.log("get activity from year")
      });
  }

	handleActivityFromYear(response: any = {}) {
    if (response.success) {
			// console.log(response)
			let activityFromYearRaw = response.activity;
			// let yearResponse: number = parseInt(response.year, 10);
			let typeFiltre: string = response.typeFiltre;
			// console.log('yearResponse', yearResponse);
			// console.log('typeFiltre', typeFiltre);
			// console.log('response', response);

			activityFromYearRaw.forEach((statsWeek: any) => {
				// console.log(`statsWeek ${typeFiltre} before`, statsWeek);
				// let rangeOfWeek = getDateRangeOfWeek(statsWeek.week, yearResponse);
				// console.log('rangeOfWeek', rangeOfWeek);
				// statsWeek.rangeOfWeek = rangeOfWeek;
				// console.log('statsWeek after', statsWeek);

				let weekFinded = this.weeksData.find((w: { weekNumber: number; }) => w.weekNumber == statsWeek.week);
				// console.log('weekFinded', weekFinded);

				switch (typeFiltre) {
					case 'cassette':
						weekFinded.cassette = statsWeek.nbProducts;
						break;

					case 'canette':
						weekFinded.canette = statsWeek.nbProducts;
						break;
						
					case 'baguette':
						weekFinded.baguette = statsWeek.nbProducts;
						break;
						
					case 'fusette':
						weekFinded.fusette = statsWeek.nbProducts;
						break;
				
					default:
						break;
				}
			});

			// console.log('this.weeksData', this.weeksData);
    } else {
      this.errorMessage = response.message;
      console.error(this.errorMessage);
    }
  }
}

/**
 * Get the date and days within a week from week number.
 * eg: date range for 8th week in 2013 is 17th Feb to 23rd Feb. This 
 * code snippet will give you.
 *
 * It is not my code completely, Bit of modification from something
 * i found on net. Cant find it anymore so keeping a backup.
 * 
 * https://gist.github.com/Abhinav1217/5038863
 * 
 * @param  {[Integer]} weekNo [From week 1 to Week 52/53 based on the system date setting]
 * @return {[Date]}        [description]
 */
function getDateRangeOfWeek(weekNo: number, year: number) {
	// console.log('getDateRangeOfWeek('+weekNo+')');
	let d1 = new Date();
	d1.setFullYear(year);
	let numOfdaysPastSinceLastMonday = (d1.getDay()-1);
	// console.log('numOfdaysPastSinceLastMonday', numOfdaysPastSinceLastMonday);
	d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
	// let weekNoToday = d1.getWeek();
	// console.log('d1', d1);
	let weekNoToday = weekNumber(d1);
	// console.log('weekNoToday', weekNoToday);
	let weeksInTheFuture = (weekNo - weekNoToday);
	// console.log('weeksInTheFuture', weeksInTheFuture);
	d1.setDate(d1.getDate() + (7 * weeksInTheFuture));
	// let rangeIsFrom = (d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();
	// console.log('rangeIsFrom', rangeIsFrom);

	// console.log('d1', d1);
	let d2 = new Date(d1);
	// console.log('d2 before', d2);
	// d2.setFullYear(year);
	d2.setDate(d2.getDate() + 6);
	// console.log('d2 after', d2);
	// d2.setDate(d1.getDate() + 6);

	// let rangeIsTo = (d2.getMonth()+1) +"/" + d2.getDate() + "/" + d2.getFullYear() ;
	// console.log('rangeIsTo', rangeIsTo);

	let rangeOfWeek = {'startOfWeek': new Date(d1.getFullYear(), d1.getMonth(), d1.getDate()), 'endOfWeek': new Date(d2.getFullYear(), d2.getMonth(), d2.getDate())};
	// console.log('rangeOfWeek', rangeOfWeek);

	return rangeOfWeek;
};

function getWeeksInYear(year: number) {
	// console.log('getWeeksInYear | year', year);
	let firstDate = new Date(year, 0, 1);
	// console.log('firstDate', firstDate);
	let lastDate = new Date(year, 11, 31);
	// console.log('lastDate', lastDate);

	const msInWeek = 1000 * 60 * 60 * 24 * 7;

	let weeks = Math.round(Math.abs(lastDate.getTime() - firstDate.getTime()) / msInWeek);
	// console.log('weeks', weeks);

  return weeks;
};