import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaletteService {

  initialPalettes: [] = [];
  palettes: [] = [];
  palettesSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getPalettes(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/palettes', { headers });
  }

  addPalette(paletteData: any, token: string) {
    // console.log("addPalette => ", paletteData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/palette', paletteData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.palettesSubject.next(res);
          return res;
        })
      );
  }

  updatePalette(id: number, paletteData: any, token: string) {
    // console.log('updatePalette => ', paletteData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/palette/${id}`, paletteData, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.palettesSubject.next(res);
          return res;
        })
      );
  }

  updatePalettes(palettesArray: any, token: string) {
    console.log(palettesArray)
    // TODO A revoir plus tard dans livraison-details pourquoi les Cartons sont remplies dans l'array
    palettesArray.forEach(function(palette: { cartons: never[]; }) { palette.cartons = []; });

    // console.log('updatePalettes => ', palettesArray);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + '/api/palettes/', palettesArray, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.palettesSubject.next(res);
          return res;
        })
      );
  }

  getPalette(id: number, token: string) {
    // console.log('getPalette', id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/palette/${id}`, { headers });
  }

  getCartonsFromPalette(id: number, token: string) {
    // console.log('getCartonsFromPalette', id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/palette/${id}/cartons`, { headers });
  }

  getPaletteModeles(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/palettes/modeles', { headers });
  }

  getPaletteModeleDetails(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/palettes/modele/${id}`, { headers });
  }

}
