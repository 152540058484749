import { Component } from '@angular/core';
import { LivraisonService } from '../services/livraison.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-franchise-livraisons-history',
  templateUrl: './franchise-livraisons-history.component.html',
  styleUrls: ['./franchise-livraisons-history.component.css']
})
export class FranchiseLivraisonsHistoryComponent {
  token: any;
  decodedToken: any;

  id: any;

  currentPage: number = 1;

  pageCount: number = 1;
  pages: any[] = []; // Array used to loop in HTML

  livraisons: any[] = [];

  error: any;

  constructor(private livraisonService: LivraisonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if (!this.authService.userIsLoggedIn()) {
      this.router.navigateByUrl('/');
      return;
    }

    const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
    this.token = bcpToken.token;
    this.decodedToken = this.authService.decodeToken(this.token);

    if (!(this.decodedToken && this.decodedToken.role.nom === 'admin')) {
      this.router.navigateByUrl('/');
      return;
    }

    this.id = this.activatedRoute.snapshot.params['id'];
    this.loadLivraisons();
  }

  loadLivraisons(page: number = 1) {
    this.livraisonService.getFranchiseLivraisonsHistory(this.id, page, this.token)
      .subscribe(
        {
          next: data => this.displayLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('franchisé livraison adresses loaded!')
        }
      )
  }

  displayLivraisons(response: any = {}) {
    if (response.success) {
      this.currentPage = response.currentPage;
      this.pageCount = response.pageCount;
      this.livraisons = response.livraisons;

      const pages = new Array(this.pageCount);
      for (let i = 1; i <= this.pageCount; i++) {
        pages[i - 1] = i;
      }

      this.pages = pages;
    } else {
      console.error(response.message);
    }
  }
}
