<div class="container">

    <main role="main" class="container">
  
        <ng-container>
            <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
              <div class="lh-100">
                <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createFranchise()">Créer un franchisé</button>
                <a href="#" routerLink="/franchises/inactifs" class="btn btn-lg btn-warning ms-2">Voir les Franchisés désactivés</a>
              </div>
            </div>
          </ng-container>

        <div class="my-3 p-3 bg-white rounded box-shadow">
            <h3 class="border-bottom border-gray pb-2 mb-0">Liste des franchisés</h3>
    
            <ng-container>
                <ng-container>
                  <br>
                    <ng-container >
                        <div class="card">
                        
                            <div class="card-body">
                                <div class="media row p-2">
                                    <ng-container *ngFor="let franchise of franchises">
                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/franchise', franchise.id]">{{franchise.nomPart}}  {{franchise.prenomPart }} <br>[{{ franchise.initiales }}]</a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div><br>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

    </main>
  
    <div *ngIf="">
      <pre>error</pre>
    </div>
  </div>
  