<main role="main">
  <div class="rounded-3">
    <div class="primary-block p-3 mb-4 shadow rounded-3">
      <div class="container">
        <h1 class="display-4">Demande de livraison {{ demandeLivraison.serialNumber | demandeLivraisonBarcode: demandeLivraison.franchise.id}}</h1>
        <p *ngIf="demandeLivraison.creationDatetime > '1970-01-01'" class="lead">Créée le {{demandeLivraison.creationDatetime | date:'dd MMMM y à HH:mm'}}</p>
        <p *ngIf="demandeLivraison.validationDatetime > '1970-01-01'" class="lead">Validée par ASI le {{demandeLivraison.validationDatetime | date:'dd MMMM y à HH:mm'}}</p>
        
        <br>

        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card" style="width: 18rem; height: 7rem;">
              <div class="card-body">
                <h5 class="card-title">Statut</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{demandeLivraison.statut | demandeLivraisonStatus}}</h6>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card" style="width: 18rem; height: 7rem;">
              <div class="card-body">
                <h5 class="card-title">Nombre de Palettes</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{demandeLivraison.nbPalettes}}</h6>
              </div>
            </div>
          </div>

          <ng-container *ngIf="demandeLivraison.franchise.id > 0">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Franchisé</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{demandeLivraison.franchise.nom.toUpperCase()}} {{demandeLivraison.franchise.prenom}} [{{demandeLivraison.franchise.initiales.toUpperCase()}}]</h6>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="demandeLivraison.address.id > 0 && demandeLivraison.statut >= 1; else noAddress">
            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Adresse de livraison</h5> -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card" style="width: 18rem; height: 7rem;">
                      <div class="card-body">
                        <h5 class="card-title">Adresse de livraison</h5>
                        <ng-container>
                          <div class="row">
                            <div class="col-9">
                              <h6 class="card-subtitle mb-2 text-muted text-wrap small">
                                <p *ngIf="demandeLivraison.address.complement_adresse; else noComp">
                                  {{demandeLivraison.address.adresse}}, {{demandeLivraison.address.complement_adresse}} <br />
                                  {{demandeLivraison.address.code_postale}} {{demandeLivraison.address.ville}}
                                </p>
                                <ng-template #noComp>
                                  {{demandeLivraison.address.adresse}}<br />
                                  {{demandeLivraison.address.code_postale}} {{demandeLivraison.address.ville}}
                                </ng-template>
                              </h6>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                <!-- </div>
              </div>
            </div> -->
          </ng-container>
          <ng-template #noAddress>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Adresse de livraison</h5>
                  <!-- <p>allo</p> -->
                  <select [(ngModel)]="demandeLivraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                    <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                      <ng-container *ngIf="a.id > 0">
                        {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                      </ng-container>
                      <ng-template #noAddress>
                        <h6 class="card-subtitle text-muted">Ø</h6>
                      </ng-template>
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </ng-template>

        </div>

        <br>

        <div *ngFor="let m of orderPalettes">
          <div class="col-md-11">
            <div class="input-group mb-3">
              <div >
                <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
              </div>
              <select class="form-select" disabled>
                <option selected value="{{m.modelePalette}}">
                  {{ m.modelePalette | paletteModele }}
                </option>
              </select>
              <div class="nbPaletteBox" disabled>
                <input autocomplete="false" class="form-control input-group-text nbPaletteValueBox" type="number" value="{{m.nbPalettes}}" readonly>
              </div>
            </div>
          </div>
        
          <div *ngIf="demandeLivraison.statut === 0" class="col-md-1" id="buttonsBox">
            <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton" style="visibility: visible;">
              Supprimer
            </button>
          </div>
        </div>

        <br>
        
        <form *ngIf="demandeLivraison.statut === 0" autocomplete="off">
          <div class="row" id="active-form-row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
              <div class="input-group mb-3">
                <div >
                  <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
                </div>
                <select (change)="paletteChanged($event)" class="form-select" id="inputGroupSelectPalette">
                  <option selected>Sélectionner...</option>
                  <option *ngFor="let m of paletteModeles" value="{{m.id}}">{{m.nom}}</option>
                </select>
                <div  id="minusPalette">
                  <button (click)="minusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonMinusCircle" disabled>
                    <i class="bi bi-dash-circle"></i>
                  </button>
                </div>
                <div class="nbPaletteBox" id="nbPalettes">
                  <input autocomplete="new-password" (input)="nbPaletteInputed($event)" type="number" value="0" min="1" class="form-control input-group-text nbPaletteValueBox" id="nbPaletteValue" disabled>
                </div>
                <div  id="plusPalette">
                  <button (click)="plusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonPlusCircle" disabled>
                    <i class="bi bi-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" id="buttonsBox">
              <button appNoDblClick (click)="validatePalette($event)" class="btn btn-outline-success me-3" type="button" id="addPaletteButton" disabled>
                <i class="bi bi-plus"></i>
                Valider
              </button>
              <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton">
                <i class="bi bi-x-lg"></i>
                Supprimer
              </button>
            </div>
          </div>
        </form>

        <ng-container *ngIf="demandeLivraison.statut === 0 && demandeLivraison.franchise && demandeLivraison.franchise.id > 0 && demandeLivraison.nbPalettes > 0">
          <div class="row">
            <div class="d-grid gap-2">
              <button appNoDblClick (click)="validateLivraison($event)" class="btn btn-success btn-lg" type="button">
                <i class="bi bi-save"></i>
                Envoyer la Demande
              </button>
            </div>
          </div>
          <br>
        </ng-container>

        <ng-container *ngIf="isAdmin && demandeLivraison.statut === 1">
          <div class="row">
            <div class="d-grid gap-2">
              <button appNoDblClick (click)="validateLivraison($event)" class="btn btn-success btn-lg" type="button">
                <i class="bi bi-save"></i>
                Valider la Demande
              </button>
            </div>
          </div>
          <br>
        </ng-container>

      </div>
    </div>
  </div>
</main>
