import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  initialProducts = [];
  products = [];
  productsSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getProducts(token: string) {
    // console.log('getProducts');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/cartons', { headers });
  }

  addProduct(productData: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    productData.id = Date.now();
    return this.http.post(environment.BASE_URL + '/api/product', productData, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.productsSubject.next(productData);
          return res;
        })
      );

  }

  addProducts(productsArray: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/products', productsArray, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.productsSubject.next(productsArray);
          return res;
        })
      );

  }

  getProduct(id: string, token: string) {
    // console.log('getProduct', id);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/product/${id}`, { headers });
  }

}
