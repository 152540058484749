import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  initialProviders = [];
  providers = [];
  ProvidersSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getProviders(token: string) {
    // console.log('getProviders');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/providers', { headers });
  }

  getProvider(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${id}`, { headers });
  }
}
