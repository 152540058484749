import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paletteStatus'
})
export class PaletteStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'En cours de création';
      case 1:
        return 'En attente d\'attribution';
      case 2:
        return 'Prête pour la livraison';
      case 3:
        return 'En cours de livraison';
      case 4:
        return 'Annulée';
      default:
        return 'Inconnu';
    }
  }

}
