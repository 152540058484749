import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Retour } from '../model/retour';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductRetour } from '../model/product_retour';

@Injectable({
  providedIn: 'root'
})
export class RetourService {

  initialRetours = [];
  retours = [];
  retoursSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getRetours(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retours/`, { headers });
  }

  getRetoursHistory(page: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retours/history/` + page, { headers });
  }

  addRetour(data: Retour, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/retour/`, data, { headers })
      .pipe(
        map((res: any) => {
          this.retoursSubject.next(res);
          return res;
        })
      );

  }

  addProductRetourFranchise(id: number, source: string, data: ProductRetour, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);

    return this.http.post(environment.BASE_URL + `/api/retour/${id}/source/${source}/product`, data, {headers}).subscribe(
      data => console.log('success', data),
      error => console.log('oops', error)
    );
  }

  addAllProductsRetour(id: number, source: string, data: ProductRetour[], token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/retour/${id}/source/${source}/products`, data, { headers })
      .pipe(
        map((res: any) => {
          this.retoursSubject.next(res);
          return res;
        })
      );
  }

  deleteAllProductRetour(id: number, source: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/retour/${id}/source/${source}/products`, { headers })
      .pipe(
        map((res: any) => {
          this.retoursSubject.next(res);
          return res;
        })
      );
  }

  updateRetour(data: Retour, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/retour/${data.id}`, data, { headers })
      .pipe(
        map((res: any) => {
          this.retoursSubject.next(res);
          return res;
        })
      );

  }

  getRetour(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/retour/${id}`, { headers });
  }

  getRetourSource(id: number, source: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}`, { headers });
  }

  addRetourSource(id: number, source: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/retour/${id}/source/${source}`, {}, { headers })
      .pipe(
        map((res: any) => {
          this.retoursSubject.next(res);
          return res;
        })
      );
  }

  getCartonsFromRetour(id: number, source: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}/cartons`, { headers });
  }

  getProductsFromRetour(id: number, source: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}/products`, { headers });
  }

  getDocument(barcode: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/retour/${barcode}/document`, { headers });
  }

  createDemandeRetour(retour: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log("RETOUR BON SERVICE", retour)
    return this.http.post(environment.BASE_URL + `/api/retour/bonDemandeRetour`, retour, { headers });
  }

  getLivraisonPalettesOrdered(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/order/palettes`, { headers });
  }

  putOrderPalettes(id: string, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/retour/${id}/order/palettes/${data.modelePalette}`, data, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteOrderPalettes(id: number, modelePalette: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/retour/${id}/order/palettes/${modelePalette}`, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
