<main role="main">
  <div class="primary-block shadow rounded-3">
      <div class="container">
        <div *ngIf="livraison">
          <h1 class="display-4">Livraison {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}</h1>
          <p *ngIf="livraison.creationDate > '1970-01-01'" class="lead">Créée le {{livraison.creationDate | date:'dd MMMM y à HH:mm' : '+0200'}}</p>
          <p *ngIf="livraison.finalizationDate > '1970-01-01'" class="lead">Finalisée le {{livraison.finalizationDate | date:'dd MMMM y à HH:mm'}}</p>
          <p *ngIf="livraison.shipmentDate > '1970-01-01' && livraison.status === 2" class="lead">Envoyée au Franchisé le {{livraison.shipmentDate | date:'dd MMMM y à HH:mm'}}</p>
  
          <br>
  
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Statut</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{livraison.status | livraisonAsiStatus}}</h6>
                </div>
              </div>
            </div>

            <ng-container *ngIf="livraison.address.id > 0; else noAddress">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Adresse de livraison</h5>
                    <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                      <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="adresseSelect" class="form-select" id="adresseSelect">
                        <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                          <ng-container *ngIf="a.id > 0 && a.isActif === 1; else noAddress">
                            {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                          </ng-container>
                          <ng-template #noAddress>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">
                        <!-- SUPPRIMER en dessous -->
                        <div class="row" *ngIf="decodedToken.username === 'jcarrat'">
                          <div class="col-9">
                            <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                              <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                                <ng-container *ngIf="a.id > 0; else noAddress">
                                  {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}}
                                </ng-container>
                                <ng-template #noAddress>
                                  <h6 class="card-subtitle text-muted">Ø</h6>
                                </ng-template>
                              </option>
                            </select>
                          </div>
                          <div class="col-2">
                            <ng-container *ngIf="isAdmin && livraison.franchise && livraison.franchise.id > 0 && livraison.provider.id > 0">
                              <button appNoDblClick (click)="saveLivraison()" class="btn btn-success" type="button">
                                <i class="bi bi-save"></i>
                              </button>
                              <br>
                            </ng-container>
                          </div>
                      </div>
                        <!-- SUPPRIMER au dessus -->
                        <p *ngIf="livraison.address.complement_adresse; else noComp">
                          {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}}
                        </p>
                        <ng-template #noComp>
                          {{livraison.address.adresse}}<br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}}
                        </ng-template>
                        
                      </h6>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noAddress>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Adresse de livraison</h5>
                    <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                      <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                        <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                          <ng-container *ngIf="a.id > 0; else noAddress">
                            {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                          </ng-container>
                          <ng-template #noAddress>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <!-- SUPPRIMER -->
                    <ng-template #notAdmin>
                      <div class="row" *ngIf="decodedToken.username === 'jcarrat'">
                        <div class="col-9">
                          <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                            <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                              <ng-container *ngIf="a.id > 0; else noAddress">
                                {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}}
                              </ng-container>
                              <ng-template #noAddress>
                                <h6 class="card-subtitle text-muted">Ø</h6>
                              </ng-template>
                            </option>
                          </select>
                        </div>
                        <div class="col-2">
                          <ng-container *ngIf="isAdmin && livraison.franchise && livraison.franchise.id > 0 && livraison.provider.id > 0">
                            <button appNoDblClick (click)="saveLivraison()" class="btn btn-success" type="button">
                              <i class="bi bi-save"></i>
                            </button>
                            <br>
                          </ng-container>
                        </div>
                      <h6 class="card-subtitle mb-2 text-muted">
                        <p *ngIf="livraison.address.complement_adresse; else noComp">
                          {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}} <b *ngIf="livraison.address.accessibilite_19T != null"> | 19T: {{livraison.address.accessibilite_19T ? 'Oui': 'Non'}}</b>
                        </p>
                        <ng-template #noComp>
                          <!-- {{livraison.address.adresse}}<br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}} <b *ngIf="livraison.address.accessibilite_19T != null"> | 19T: {{livraison.address.accessibilite_19T ? 'Oui': 'Non'}}</b> -->
                        </ng-template>
                      </h6>
                      </div>
                    </ng-template>
                    <!-- SUPPRIMER -->
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-container *ngIf="livraison && livraison.franchise && livraison.franchise.initiales.length > 0; else noFranchise">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Franchisé</h5>
                    <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                      <select [(ngModel)]="livraison.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                        <option *ngFor="let f of franchises" [ngValue]="f.id">
                          <ng-container *ngIf="f.id > 0; else noFranchise">
                            {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                          </ng-container>
                          <ng-template #noFranchise>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">{{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]</h6>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noFranchise>
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Franchisé</h5>
                    <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                      <select [(ngModel)]="livraison.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                        <option *ngFor="let f of franchises" [ngValue]="f.id">
                          <ng-container *ngIf="f.id > 0; else noFranchise">
                            {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                          </ng-container>
                          <ng-template #noFranchise>
                            <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                          </ng-template>
                        </option>
                      </select>
                    </ng-container>
                    <ng-template #notAdmin>
                      <h6 class="card-subtitle mb-2 text-muted">{{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]</h6>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template> <!-- #noFranchise -->
  
          </div> <!-- row -->

          <br>

          <div *ngFor="let m of materials" class="row">
            <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
              <div class="input-group mb-3">
                <div >
                  <label class="input-group-text" for="inputGroupSelectMateriel">Matériel</label>
                </div>
                <select class="form-select" disabled>
                  <option selected value="{{m.typeMateriel}}">
                    {{ m.typeMateriel | materielAsiType }}
                  </option>
                </select>
                <div class="nbMaterialBox" disabled>
                  <input autocomplete="false" class="form-control input-group-text nbMaterialValueBox" type="number" value="{{m.nbMateriel}}" readonly>
                </div>
                <div  id="typeConditionnementBox" style="visibility: visible;">
                  <span class="input-group-text" id="typeConditionnement">{{ m.conditionnement | materielAsiConditionnement }}</span>
                </div>
              </div>
            </div>
          
            <div *ngIf="livraison.status === 0" class="col-sm-6 col-md-6 col-lg-4 col-xl-3" id="buttonsBox">
              <button appNoDblClick (click)="removeMaterial($event)" class="btn btn-outline-danger" type="button" id="removeMaterialButton" style="visibility: visible;">
                Supprimer
              </button>
            </div>
          </div>
          
          <br>

          <form *ngIf="livraison.status === 0" autocomplete="off">
            <div class="row" id="active-form-row">
              <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
                <div class="input-group mb-3">
                  <div >
                    <label class="input-group-text" for="inputGroupSelectMateriel">Matériel</label>
                  </div>
                  <select (change)="materialChanged($event)" class="form-select" id="inputGroupSelectMateriel">
                    <option selected>Sélectionner...</option>
                    <option value="1">Brochures</option>
                    <option value="2">Supports Cassettes</option>
                    <option value="3">Supports Baguettes</option>
                    <option value="4">Supports Canettes</option>
                    <option value="5">Supports Fusettes</option>
                  </select>
                  <div  id="minusMaterial">
                    <button (click)="minusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonMinusCircle" disabled>
                      <i class="bi bi-dash-circle"></i>
                    </button>
                  </div>
                  <div class="nbMaterialBox" id="nbMaterial">
                    <!--<span class="input-group-text nbMaterialValueBox" id="nbMaterialValue">0</span>-->
                    <input autocomplete="new-password" (input)="nbMaterialInputed($event)" type="number" value="0" min="1" class="form-control input-group-text nbMaterialValueBox" id="nbMaterialValue" disabled>
                  </div>
                  <div  id="plusMaterial">
                    <button (click)="plusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonPlusCircle" disabled>
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                  
                  <div  id="typeConditionnementBox">
                    <span id="typeConditionnement" class="input-group-text">Carton</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" id="buttonsBox">
                <button appNoDblClick (click)="validateMaterial($event)" class="btn btn-outline-success me-2" type="button" id="addMaterialButton" disabled>
                  <!--<i class="bi bi-plus"></i>-->
                  Valider
                </button>
                <button appNoDblClick (click)="removeMaterial($event)" class="btn btn-outline-danger" type="button" id="removeMaterialButton">
                  <!--<i class="bi bi-x-lg"></i>-->
                  Supprimer
                </button>
              </div>
            </div>
          </form>

          <br>
  
          <!-- <ng-container *ngIf="livraison.status === 0 && livraison.franchise && livraison.franchise.id > 0 && materials.length > 0">
            <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="generatePdfBonLivraison()" class="btn btn-danger btn-lg" type="button">
                    <i class="bi bi-printer"></i>
                    Générer le bon de Livraison
                  </button>
                </div>
            </div>
            <br>
          </ng-container> -->

          <br>
  
          <ng-container *ngIf="livraison.franchise">

            <ng-container *ngIf="livraison.status === 0 && livraison.franchise.id > 0 && materials.length > 0">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="saveLivraison()" class="btn btn-success btn-lg" type="button">
                    <i class="bi bi-save"></i>
                    Finaliser la Livraison
                  </button>
                </div>
              </div>
              <br>
            </ng-container>
  
            <ng-container *ngIf="livraison.status >= 1 && livraison.status !== 3">
              <ng-container *ngIf="blobBonLivraison">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showPdfBonLivraison()" class="btn btn-danger btn-lg" type="button">
                      <i class="bi bi-printer"></i>
                      Imprimer la Demande de Livraison
                    </button>
                  </div>
                </div>
                <br>
              </ng-container>
            </ng-container>
  
            <ng-container *ngIf="isAdmin">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="confirmCancelLivraison(cancelLivraisonConfirmModal)" class="btn btn-danger btn-lg" type="button" [disabled]="livraison.status >= 2">
                    <i class="bi bi-x-lg"></i>
                    Annuler la livraison
                  </button>
                </div>
              </div>
              <br>
            </ng-container>

            <ng-template #cancelLivraisonConfirmModal let-modal>
              <div class="modal-header">
                <h4 class="modal-title w-100 text-center" id="cancelLivraisonConfirmModal-title">CONFIRMATION</h4>
              </div>
              <div class="modal-body">
                <form>
                  <div class="mb-3">
                    <p class="fw-bolder">Souhaitez-vous VRAIMENT <span class="text-danger">ANNULER CETTE LIVRAISON ?</span></p>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary fw-bolder" ngbAutofocus (click)="modal.dismiss()">NON, SORTIR</button>
                <button type="button" class="btn btn-danger fw-bolder" (click)="modal.close(); cancelLivraison(cancelLivraisonInfoModal)">
                  <i class="bi bi-exclamation-triangle-fill"></i>
                  <i class="bi bi-exclamation-triangle-fill"></i>
                  ANNULER LA LIVRAISON
                  <i class="bi bi-exclamation-triangle-fill"></i>
                  <i class="bi bi-exclamation-triangle-fill"></i>
                </button>
              </div>
            </ng-template>

            <ng-template #cancelLivraisonInfoModal let-modal>
              <div class="modal-header">
                <h4 class="modal-title w-100 text-center" id="cancelLivraisonInfoModal-title">INFO - ANNULATION LIVRAISON</h4>
                
              </div>
              <div class="modal-body">
                <form>
                  <div class="mb-3" [innerHTML]="textInfoCancelLivraisonModal">
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary fw-bolder" ngbAutofocus (click)="modal.close();">OK</button>
              </div>
            </ng-template>
  
            <ng-container *ngIf="livraison.status === 1 && materials.length > 0">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="sendInLivraison()" class="btn btn-success btn-lg" type="button">
                    <i class="bi bi-truck"></i>
                    Envoyer en Livraison à {{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]
                  </button>
                </div>
              </div>
              <br>
            </ng-container>

          </ng-container> <!-- <ng-container *ngIf="livraison.franchise && livraison.provider.id > 0"> -->
  
        </div>
  
      </div> <!-- container -->
    </div> <!-- jumbotron -->
  
    <div *ngIf="errorHandled">
      <div class="card text-white bg-danger mb-3 mx-auto" style="max-width: 18rem;">
        <div class="card-header">Erreur</div>
        <div class="card-body">
          <h5 class="card-title">{{ errorHandled.id }}</h5>
          <p class="card-text">
            {{ errorHandled.message }}
          </p>
        </div>
      </div>
    </div>
  
    <div *ngIf="error">
      <ng-container *ngIf="isAdmin">
        <br>
        <pre><code>{{ error | json }}</code></pre>
      </ng-container>
    </div>
  
    <canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
  
  </main>
  