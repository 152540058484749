import { Component, OnInit } from '@angular/core';
import { LivraisonAsiService } from '../services/livraison-asi.service';
import { AuthService } from '../services/auth.service';
import { Livraison } from '../model/livraison';
import { LivraisonAsi } from '../model/livraison_asi';

@Component({
  selector: 'app-livraisons-asi-history',
  templateUrl: './livraisons-asi-history.component.html',
  styleUrls: ['./livraisons-asi-history.component.css']
})
export class LivraisonsAsiHistoryComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: number = -1;
  livraisons: LivraisonAsi[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  livraisonCreated: any;
  errorMessage: string = '';

  currentPage: number = 1;
  pageCount: number = 1;
  pages: number[] = [];

  constructor(private livraisonService: LivraisonAsiService, private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      if (this.isAdmin) {
        this.loadLivraisons();
      } else {
        this.loadLivraisons();
      }

      // this.livraisonService.livraisonsSubject.subscribe(data => {
      //   this.livraisons = [data, ...this.livraisons];
      // });
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadLivraisons(page = 1) {
    this.livraisonService.getLivraisonsHistory(page, this.token)
      .subscribe(
        {
          next: data => this.displayLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('livraisons history loaded!')
        }
      );
  }

  displayLivraisons(response: any = {}) {
    if (response.success) {
      this.currentPage = response.currentPage;
      this.pageCount = response.pageCount;
      this.livraisons = response.livraisons;

      const pages = new Array(this.pageCount);
      for (let i = 1; i <= this.pageCount; i++) {
        pages[i - 1] = i;
      }

      this.pages = pages;
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }
}
