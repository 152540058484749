<div class="container">

  <div class="mb-5 p-4 shadow border border-black rounded-2 d-flex align-items-center">
    <h2 *ngIf="livraisons[0]" class="m-0 flex-grow-1">
      Franchisé : {{livraisons[0].franchiseInitiales}} - {{livraisons[0].franchiseNom.toUpperCase()}} {{livraisons[0].franchisePrenom}}
    </h2>

    <a href="#" routerLink=".." class="btn btn-secondary"><i class="bi bi-arrow-left-circle me-2"></i>Retour</a>
  </div>

  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">N° de Livraison</th>
      <th scope="col">Statut</th>
      <th scope="col">Date de création</th>
      <th scope="col">Date de départ en livraison</th>
      <th scope="col">Détail</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let livraison of livraisons">
      <tr>
        <th scope="row">{{livraison.serialNumber | livraisonBarcode: livraison.provider}}</th>

        <td>{{livraison.status | livraisonStatus}}</td>
        <td>{{livraison.creationDatetime | date:'dd MMMM y à HH:mm' : '+0400'}}</td>

        <td>
          <ng-container *ngIf="livraison.shipmentDatetime; else no_date">
            {{livraison.shipmentDatetime | date:'dd MMMM y à HH:mm' : '+0400'}}
          </ng-container>
          <ng-template #no_date>-</ng-template>
        </td>
        
        <td class="text-center align-middle">
          <a class="btn btn-outline-primary" href="#" [routerLink]="['/livraison', livraison.id]">Voir le détail</a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>

  <nav aria-label="historique livraison pagination">
    <ul class="pagination justify-content-center">
      <li class="page-item {{ currentPage === 1 ? 'disabled' : '' }}">
        <button class="page-link" tabindex="-1" appNoDblClick (click)="loadLivraisons(currentPage - 1)">Précédente</button>
      </li>
      <ng-container *ngFor="let page of pages">
        <li class="page-item{{ page === currentPage ? ' active' : '' }}">
          <button class="page-link" appNoDblClick (click)="loadLivraisons(page)">{{ page }}</button>
        </li>
      </ng-container>
      <li class="page-item {{ currentPage === pageCount ? 'disabled' : ''}}">
        <button class="page-link" appNoDblClick (click)="loadLivraisons(currentPage + 1)">Suivante</button>
      </li>
    </ul>
  </nav>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</div>
