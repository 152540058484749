<div class="container">

  <main role="main" class="container">

    <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-gray-dark rounded box-shadow">
      <!--<div *ngIf="isAdmin" class="lh-100">
        <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="modifyFourniture()">Modifier les Fournitures</button>
      </div>-->

      <div class="lh-100">
        <button type="button" class="btn btn-lg btn-success" appNoDblClick (click)="showCommandes()">Historique des Commandes</button>
      </div>
    </div>

    <div class="my-3 p-3 bg-white rounded box-shadow">
      <h3 class="border-bottom border-gray pb-2 mb-0">Fourniture de matériel</h3>

      <br>

      <ng-container *ngIf="isAdmin">
        <ng-container *ngFor="let p of providers">
          <div class="card">
            <div class="card-header">
              {{p.nom}}
            </div>
            <div class="card-body">
              <div class="media row">
                <ng-container *ngFor="let fourniture of fournitures">
                  <ng-container *ngIf="fourniture.provider.id === p.id">

                    <ng-container *ngIf="(fourniture.type === 'G' || fourniture.type === 'K')">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Cassettes" aria-describedby="button-fourniture-cassettes" title="fourniture-cassettes">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-G">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </ng-container>

                    <ng-container *ngIf="fourniture.type === 'B'">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Baguettes" aria-describedby="button-fourniture-baguettes" title="fourniture-baguettes">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-B">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </ng-container>

                    <ng-container *ngIf="fourniture.type === 'C'">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Canettes" aria-describedby="button-fourniture-canettes" title="fourniture-canettes">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-C">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </ng-container>

                    <ng-container *ngIf="(fourniture.type === 'T' || fourniture.type === 'F')">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Fusettes" aria-describedby="button-fourniture-fusettes" title="fourniture-fusettes">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-T">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="fourniture.type === 'E'">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">220 étiquettes par rouleau</h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Etiquettes" aria-describedby="button-fourniture-etiquettes" title="fourniture-etiquettes">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-E">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </ng-container>

                    <ng-container *ngIf="fourniture.type === 'CA'">
                      <div class="col-sm me-2 pt-3">
                        <div class="card" style="width: 18rem;">
                          <div class="card-body">
                            <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                            <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                            <div class="input-group mb-3">
                              <label>
                                <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Cartons" aria-describedby="button-fourniture-cartons" title="fourniture-cartons">
                              </label>
                              <div >
                                <button appNoDblClick (click)="modifyFourniture($event)" class="btn btn-outline-danger" type="button" id="modify-CA">Modifier</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </ng-container>

                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div><br>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isAdmin">
        <div class="media row">
          <ng-container *ngFor="let fourniture of fournitures">

            <ng-container *ngIf="fourniture.provider.id === provider.id">
              <ng-container *ngIf="(fourniture.type === 'G' || fourniture.type === 'K')">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Cassettes" aria-describedby="button-fourniture-cassettes" title="fourniture-cassettes" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-G">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br>
              </ng-container>

              <ng-container *ngIf="fourniture.type === 'B'">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Baguettes" aria-describedby="button-fourniture-baguettes" title="fourniture-baguettes" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-B">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br>
              </ng-container>

              <ng-container *ngIf="fourniture.type === 'C'">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Canettes" aria-describedby="button-fourniture-canettes" title="fourniture-canettes" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-C">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br>
              </ng-container>

              <ng-container *ngIf="(fourniture.type === 'T' || fourniture.type === 'F')">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Fusettes" aria-describedby="button-fourniture-fusettes" title="fourniture-fusettes" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-T">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="fourniture.type === 'E'">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted">220 étiquettes par rouleau</h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Etiquettes" aria-describedby="button-fourniture-etiquettes" title="fourniture-etiquettes" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-E">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br>
              </ng-container>

              <ng-container *ngIf="fourniture.type === 'CA'">
                <div class="col-sm me-2 pt-3">
                  <div class="card" style="width: 18rem;">
                    <div class="card-body">
                      <h5 class="card-title">{{fourniture.type | materielType}}</h5>
                      <h6 class="card-subtitle mb-2 text-muted"><br></h6>
                      <div class="input-group mb-3">
                        <label>
                          <input type="text" class="form-control" value="{{fourniture.number}}" aria-label="Fourniture Cartons" aria-describedby="button-fourniture-cartons" title="fourniture-cartons" disabled>
                        </label>
                        <div >
                          <button appNoDblClick (click)="passerCommande($event)" class="btn btn-outline-success" type="button" id="commande-CA">Commander</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><br>
              </ng-container>

            </ng-container>
          </ng-container>
        </div>
      </ng-container>

    </div>

  </main>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</div>
