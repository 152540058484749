import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LivraisonAsi } from '../model/livraison_asi';
import { LivraisonAsiService } from '../services/livraison-asi.service';
import { ProviderService } from '../services/provider.service';
import { FranchiseService } from '../services/franchise.service';
import { Franchise } from '../model/franchise';
import { Address } from '../model/address';
import { Provider } from '../model/provider';
import { LivraisonBarcodePipe } from '../pipes/livraison-barcode.pipe';
import { MaterielAsiTypePipe } from '../pipes/materiel-asi-type.pipe';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { join } from 'path';
import { ScanPalettesComponent } from '../scan-palettes/scan-palettes.component';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-livraison-asi-details',
  templateUrl: './livraison-asi-details.component.html',
  styleUrls: ['./livraison-asi-details.component.css'],
  providers: [ScanPalettesComponent]
})
export class LivraisonAsiDetailsComponent implements OnInit, AfterViewChecked {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  livraison: LivraisonAsi = {
    id: 0,
    provider: new Provider(0, '', 0),
    serialNumber: 0,
    status: 0,
    franchise: 0,
    address: 0,
    barcode: '',
    creationDate: '',
    finalizationDate: '',
    shipmentDate: '',
    materials: []
  };
  originalProvider!: Provider;
  providers: Provider[] = [];
  franchises: Franchise[] = [];
  error: string = '';
  errorHandled: any;

  adresses: any;
  adresse: any;

  activeAdresses: any[] = [];

  materials: any;

  isLivraisonReceived: boolean = false;
  isLivraisonMaterielReceived: boolean = false;

  bonLivraisonPdfBase64: any;
  blobBonLivraison: any;

  textInfoCancelLivraisonModal: string = "";

  constructor(private livraisonService: LivraisonAsiService,
              private providerService: ProviderService,
              private franchiseService: FranchiseService,
              private activatedRoute: ActivatedRoute,
              private ref: ChangeDetectorRef,
              private modalService: NgbModal,
              private authService: AuthService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      // console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    const id = this.activatedRoute.snapshot.params['id'];
    console.log('BONSOIR')
    console.log('ID', id)
    this.loadLivraison(id);
  }

  ngAfterViewChecked(): void {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.ref.detectChanges();
  }

  loadLivraison(id: number) {
    this.livraisonService.getLivraison(id, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison loaded!')
        }
      );
  }

  loadAllProviders() {
    this.providerService.getProviders(this.token)
      .subscribe(
        {
          next: data => this.handleProviderServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('providers loaded!')
        }
      );
  }

  loadAllFranchises() {
    this.franchiseService.getFranchises(this.token)
      .subscribe(
        {
          next: data => this.handleFranchisesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchises loaded!')
        }
      );
  }

  loadFranchise(id: number) {
    this.franchiseService.getFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchise loaded!')
        }
      );
  }

  onChangeAddress(newAddress: number) {

    const address = this.adresses.find((a: { id: number }) => a.id === newAddress);
    console.log('address',address)

    this.livraison.address = address
    console.log('this.livraison.address',this.livraison.address)
    // if(this.livraison.address.accessibilite_19T === 0) {
    this.loadLivraisonAdresse(this.livraison.address.id, this.livraison.franchise.id)
    // }

    if (this.livraison.status === 2) {
      this.modifyAdresseLivraison()
    } else {
      this.modifyLivraison();
    }
  }

  loadFranchiseAdresses(id: number) {
    this.franchiseService.getAllAdressesLivraisonByFranchise(id, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAdressesServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('franchise adresses loaded!') 
        }
      )
  }

  handleFranchiseAdressesServerResponse(response: any = {}) {
    console.log('response.adresses', response.adresses)
    this.adresses = response.adresses;

    for(let i = 0; i <= this.adresses.length - 1; i++) {
      if(this.adresses[i].isActif === 1) {
        this.activeAdresses.push(this.adresses[i])
      }
    } 
    // console.log('addresses', this.adresses)
  }

  loadLivraisonAdresse(id_address: number, id_franchise: number) {
    this.franchiseService.getAdresseLivraisonByFranchise(id_address, id_franchise, this.token)
      .subscribe(
        {
          next: data => this.handleFranchiseAddress(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison adresse loaded')
        }
      )
  }

  handleFranchiseAddress(response: any = {}) {
    console.log('response handle franchise address', response)
    if(response.success) {
      console.log('response livraison address', response.address[0])
      this.livraison.address = response.address
    }
  }

  loadLivraisonMateriel(id: number) {
    this.livraisonService.getLivraisonMateriel(id, this.token).subscribe(
      {
        next: data => this.handleLivraisonMaterielServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('livraison materiel loaded!')
      }
    );
  }

  loadBonLivraison() {
    this.livraisonService.getDocument(this.livraison.barcode, 1, this.token).subscribe(
      {
        next: data => this.handleGetFileServerResponse(data),
        error: err => this.handleError(err),
        complete: () => console.log('document loaded!')
      }
    );
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  handleLivraisonServerResponse(response: any = {}) {
    console.log('RESPONSE READ', response)
    if (response.success) {
      if (response.livraison) {
        this.livraison = response.livraison;
        console.log("LIVRAISON", response.livraison)
        this.livraison.barcode = new LivraisonBarcodePipe()
          .transform(this.livraison.serialNumber, this.livraison.provider.id);

        this.originalProvider = Object.assign({}, this.livraison.provider);

        if (!this.livraison.franchise) {
          this.livraison.franchise = <Franchise>{
            id: 0,
            initiales: ''
          };
        }
        if (!this.livraison.address) {
          this.livraison.address = <Address>{
            id: 0,
          }
          console.log('no address', this.livraison.address)
          this.loadFranchiseAdresses(this.livraison.franchise.id)
        }

        this.isLivraisonReceived = true;

        if (this.livraison.status === 0) {
          if (this.providers.length === 0) {
            this.loadAllProviders();
          }
          if (this.franchises.length === 0) {
            this.loadAllFranchises();
          }
        }

        this.loadLivraisonMateriel(this.livraison.id);
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleLivraisonMaterielServerResponse(response: any = {}) {
    if (response.success) {
      if (response.materiel) {
        if (this.livraison) {
          this.livraison.barcode = new LivraisonBarcodePipe()
            .transform(this.livraison.serialNumber, this.livraison.provider.id);

          this.materials = response.materiel;

          this.isLivraisonMaterielReceived = true;

          if (this.livraison.status >= 1
            && this.isLivraisonReceived
            && this.isLivraisonMaterielReceived) {
              this.loadBonLivraison();
          }
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleUpdateLivraisonServerResponse(response: any = {}) {
    if (response.success) {
      if (response.livraison) {
        this.livraison = response.livraison;
        this.livraison.barcode = new LivraisonBarcodePipe()
          .transform(this.livraison.serialNumber, this.livraison.provider.id);

        this.originalProvider = Object.assign({}, this.livraison.provider);
        if (this.livraison.status >= 1) {
          this.loadBonLivraison();
        }
      }
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleProviderServerResponse(response: any = {}) {

    if (response.success) {
      this.providers = response.providers.sort((a: { id: number; }, b: { id: number; }) => {
        return a.id - b.id;
      });
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchisesServerResponse(response: any = {}) {

    if (response.success) {
      this.franchises = response.franchises.sort((a: { initiales: number; }, b: { initiales: number; }) => {
        return a.initiales - b.initiales;
      });

      this.franchises.push(<Franchise>{
        id: 0,
        initiales: ''
      });

    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleFranchiseServerResponse(response: any = {}) {

    if (response.success) {
      console.log('response', response)
      this.loadFranchiseAdresses(response.franchise.franchiseId)
      this.livraison.franchise = response.franchise;

    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  /*handlePatchDataMaterialServerResponse(response) {
    console.log('handlePatchDataMaterialServerResponse ', response);

    if (response.success) {
      // console.log('IL Y A DES PROVIDERS !');
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }*/

  handlePutMaterialServerResponse(response: any = {}) {

    if (response.success) {
      // console.log('IL Y A DES PROVIDERS !');
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleDeleteMaterialServerResponse(response: any = {}) {

    if (response.success) {
      // console.log('IL Y A DES PROVIDERS !');
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleGetFileServerResponse(response: any = {}) {

    if (!response.success) { // Les fichiers n'existent pas, on génère les PDF
      console.log('Le fichier n\'existe pas, on génère le PDF');  
      this.generatePdfBonLivraison();
    } else { // Le fichier existe, on ajoute le blob
      console.log('Le fichier existe');  

      if (response.file) {
        const contentType = 'application/pdf';

        this.bonLivraisonPdfBase64 = response.file;
        this.blobBonLivraison = b64toBlob(this.bonLivraisonPdfBase64, contentType);
      }
    }
  }

  handleAddFileServerResponse(response: any = {}) {
    if (response.success) {
      const contentType = 'application/pdf';

      this.blobBonLivraison = b64toBlob(this.bonLivraisonPdfBase64, contentType);
    } else {
      this.errorHandled = response;
      console.error(this.errorHandled.message);
    }
  }

  handleError(error: string) {
    console.log('error loading livraison asi')
    console.error(error);
    this.error = error;
  }

  putMateriel(data: { typeMateriel: any; conditionnement: string; nbMateriel: number; }) {
    this.livraisonService.putMateriel(this.livraison.id, data, this.token)
      .subscribe(
        {
          next: data => this.handlePutMaterialServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('material was updated!')
        }
      );
  }

  deleteMateriel(typeMateriel: number) {
    this.livraisonService.deleteMateriel(this.livraison.id, typeMateriel, this.token)
      .subscribe(
        {
          next: data => this.handleDeleteMaterialServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('material was removed!')
        }
      );
  }

  /*patchDataMaterial(data) {
    this.livraisonService.patchLivraisonMateriel(this.livraison.id, data, this.token)
      .subscribe(
        data => {
          this.handlePatchDataMaterialServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );
  }*/

  onChangeFranchise(newFranchise: number) {
    this.activeAdresses.length = 0;
    const franchise: Franchise = this.franchises.find(f => f.id === newFranchise) || new Franchise(0, '', '', 0, '', '', '', '', '', '', '', 0, '', '', '', '', false);
    console.log('franchise on change', franchise)

    this.livraison.franchise = franchise;

    this.loadFranchise(this.livraison.franchise.id);
    this.modifyLivraison();
  }

  modifyAdresseLivraison() {
    this.livraisonService.updateAdresseLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison address was updated!')
        }
      )
  }

  modifyLivraison() {
    console.log('this.livraison', this.livraison)
    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated!')
        }
      );
  }

  materialChanged(event: any) {
    var self = this;

    var value = event.target.value;
    var form = event.srcElement.form;
    var elementParent = event.srcElement.parentElement;
    var formBox = elementParent.children;
    var buttonBox = elementParent.parentElement.parentElement.lastChild;

    var buttonMinus = formBox[2].firstChild;
    var nbMaterialInput = formBox[3].firstChild;
    var buttonPlus = formBox[4].firstChild;
    var typeConditionnementBox = formBox[5];
    var typeConditionnement = typeConditionnementBox.firstChild;

    console.log("FORM LENGTH", form.length);
    for(var i = 0, len = form.length; i < len; i++) {

      if (form[i].getAttribute('id') === 'addMaterialButton') {
        switch (value) {
          case '1': // Brochures
            typeConditionnement.innerHTML = "Carton";
            typeConditionnementBox.style.visibility = 'visible';
            buttonMinus.removeAttribute('disabled');
            nbMaterialInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          case '2': // Supports Cassettes
            typeConditionnement.innerHTML = "Carton";
            typeConditionnementBox.style.visibility = 'visible';
            buttonMinus.removeAttribute('disabled');
            nbMaterialInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          case '3': // Supports Baguettes
            typeConditionnement.innerHTML = "Carton";
            // typeConditionnement.innerHTML = "À l'unité";
            typeConditionnementBox.style.visibility = 'visible';
            buttonMinus.removeAttribute('disabled');
            nbMaterialInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          case '4': // Supports Canettes
            typeConditionnement.innerHTML = "Carton";
            // typeConditionnement.innerHTML = "À l'unité";
            typeConditionnementBox.style.visibility = 'visible';
            buttonMinus.removeAttribute('disabled');
            nbMaterialInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          case '5': // Supports Fusettes
            typeConditionnement.innerHTML = "Carton";
            // typeConditionnement.innerHTML = "À l'unité";
            typeConditionnementBox.style.visibility = 'visible';
            buttonMinus.removeAttribute('disabled');
            nbMaterialInput.removeAttribute('disabled');
            buttonPlus.removeAttribute('disabled');
            buttonBox.style.visibility = 'visible';
            break;
          default:
            typeConditionnement.innerHTML = "";
            typeConditionnementBox.style.visibility = 'hidden';
            buttonMinus.setAttribute('disabled', '');
            nbMaterialInput.value = "0";
            nbMaterialInput.setAttribute('disabled', '');
            buttonPlus.setAttribute('disabled', '');
            buttonBox.style.visibility = 'hidden';
            return 'Inconnu';
        }

        this.getNbMaterialElement(formBox, function(elemNbMaterial: { value: any; }) {
          var nbMaterial = Number(elemNbMaterial.value);
          console.log("materialChanged | nbMaterial", nbMaterial);

          self.toogleValidateButton(value, nbMaterial);
          /*if (Number.isNaN(Number(value)) && Number(elemNbMaterial.value) > 0) {
            form[i].setAttribute('disabled', true);
          } else {
            form[i].removeAttribute('disabled');
          }*/
        });
      }
    }
    return;
  }

  nbMaterialInputed(event: any) {
    var self = this;

    if (event.data !== null) {
      console.log('nbMaterialInputed | event', event);
      console.log('nbMaterialInputed | event.data', event.data);
  
      if (isNaN(event.data)) {
        console.log("Ce n'est pas un nombre !");
        event.stopPropagation();
      }

      var activeFormRow: any = document.getElementById('active-form-row');
      var formBox = activeFormRow.children[0].children[0].children;
      var buttonBox = activeFormRow.lastChild;

      var elemNbMaterial = this.getNbMaterialElementX(formBox, false);
      var typeMaterial = this.getTypeMaterialValueX(formBox, false);
      this.toogleValidateButton(typeMaterial, Number(elemNbMaterial.value));
    }
  }

  getTypeMaterialValueX(activeRow: any[] | HTMLCollection, isValidate: boolean) {
    var element = activeRow[1];
    var typeMaterialValue = element.options[element.selectedIndex].value;

    return typeMaterialValue;
  };

  getTypeMaterialValue(activeRow: string | any[], callback: { (typeMaterialValue: any): void; (typeMaterialValue: any): void; (typeMaterialValue: any): void; (arg0: any): void; }) {

    for(var i = 0; i< activeRow.length; i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'inputGroupSelectMateriel') {
        var typeMaterialValue = element.options[element.selectedIndex].value;
        callback(typeMaterialValue);
      }
    }
  };

  getNbMaterialElementX(activeRow: any[] | HTMLCollection, isValidate: boolean) {

    var element = (isValidate) ? activeRow[2] : activeRow[3];
    var subElement = element.firstChild;

    return subElement;
  }

  getNbMaterialElement(activeRow: string | any[], callback: { (elemNbMaterial: any): void; (elemNbMaterial: any): void; (elemNbMaterial: any): void; (elemNbMaterial: any): void; (arg0: any): void; }) {

    for(var i = 0; i< activeRow.length;i++) {
      var element = activeRow[i];

      if (element.getAttribute('id') === 'nbMaterial') {
        console.log()
        callback(element.children[0]);
      }
    }
  }

  minusClicked(event: any) {

    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbMaterialElement(activeRow, function(elemNbMaterial: { value: any; }) {
      var nbMaterial = elemNbMaterial.value;
      if (nbMaterial > 0) {
        nbMaterial = Number(nbMaterial) - 1;
        elemNbMaterial.value = nbMaterial;
      }

      self.getTypeMaterialValue(activeRow, function(typeMaterialValue: any) {
        self.toogleValidateButton(typeMaterialValue, nbMaterial);
      });
    });
  }

  plusClicked(event: any) {

    var activeRow = event.srcElement.parentElement.parentElement.children;
    var self = this;

    this.getNbMaterialElement(activeRow, function(elemNbMaterial: { value: any; }) {
      var nbMaterial = elemNbMaterial.value;
      nbMaterial = Number(nbMaterial) + 1;
      elemNbMaterial.value = nbMaterial;

      self.getTypeMaterialValue(activeRow, function(typeMaterialValue: any) {
        self.toogleValidateButton(typeMaterialValue, nbMaterial);
      });
    });
  }

  toogleValidateButton(typeMaterialValue: any, nbMaterial: number) {
    var addMaterialButton: any = document.getElementById('addMaterialButton');

    if (!Number.isNaN(Number(typeMaterialValue)) && Number(nbMaterial) > 0) {
      addMaterialButton.removeAttribute('disabled');
    } else {
      addMaterialButton.setAttribute('disabled', '');
    }
  }

  /*disableValidateButton(activeRow) {
    activeRow.children[1].children[0].setAttribute('disabled', '');
    console.log('disableValidateButton | addMaterialButton AFTER', activeRow.children[1].children[0]);
  }*/

  validateMaterial(event: any) {

    var activeRow = event.srcElement.parentElement.parentElement.children[0].children[0].children;
    
    var self = this;

    this.getNbMaterialElement(activeRow, function(elemNbMaterial: { value: any; }) {
      var nbMaterial = elemNbMaterial.value;

      self.getTypeMaterialValue(activeRow, function(typeMaterialValue: any) {
        var typeConditionnement;

        switch (typeMaterialValue) {
          case '1': // Brochures
            typeConditionnement = 'C';
            break;
          case '2': // Supports Cassettes
            typeConditionnement = 'C';
            break;
          case '3': // Supports Baguettes
            typeConditionnement = 'C';
            // typeConditionnement = 'U';
            break;
          case '4': // Supports Canettes
            typeConditionnement = 'C';
            // typeConditionnement = 'U';
            break;
          case '5': // Supports Fusettes
            typeConditionnement = 'C';
            // typeConditionnement = 'U';
            break;
          default:
            typeConditionnement = '-';
        }

        var obj = { typeMateriel: typeMaterialValue, conditionnement: typeConditionnement, nbMateriel: Number(nbMaterial) }
        
        var findedObj = self.materials.find((m: { typeMateriel: any; }) => m.typeMateriel === typeMaterialValue);
        if (typeof findedObj === "undefined") {
          self.materials.push(obj);
        } else {
          findedObj.nbMateriel += Number(nbMaterial);
        }
        self.putMateriel(obj);
      });
    });
  }

  removeMaterial(event: any) {

    var parentElementActiveRow = event.srcElement.parentElement.parentElement;
    var activeRow = parentElementActiveRow.children[0].children[0].children;
    
    var elemNbMaterial = this.getNbMaterialElementX(activeRow, true);
    var nbMaterial = elemNbMaterial.value;

    var typeMaterialValue = this.getTypeMaterialValueX(activeRow, true);
      
    var findedMat = this.materials.find((m: { typeMateriel: any; }) => m.typeMateriel === typeMaterialValue);
    findedMat.nbMateriel -= nbMaterial;

    if (findedMat.nbMateriel === 0) {
      this.materials = this.materials.filter((m: { typeMateriel: any; }) => m.typeMateriel !== typeMaterialValue);
      console.log('this.materials AFTER REMOVE', this.materials);

      this.deleteMateriel(findedMat.typeMateriel);
    }

    parentElementActiveRow.remove();

    /*var dataToPatch = [
      { "op": "remove", "typeMateriel": typeMaterialValue }
    ];

    this.patchDataMaterial(dataToPatch);*/
  }



  disableRowElements(activeRow: { children: { children: any; }[]; }) {

    var inputForm = activeRow.children[0].children[0].children;
    var inputGroupSelectMateriel = inputForm.item(1);
    inputGroupSelectMateriel.setAttribute('disabled', '');
    inputGroupSelectMateriel.removeAttribute('id');

    var nbMaterial = inputForm.item(3);
    nbMaterial.children[0].removeAttribute('id');
    nbMaterial.setAttribute('disabled', '');
    nbMaterial.removeAttribute('id');

    for (let item of inputForm) {
      if (item.id === "minusMaterial") {
        item.remove();
      } else if (item.id === "plusMaterial") {
        item.remove();
      }
    }

    var buttons = activeRow.children[1].children;
    buttons[1].style.visibility = 'visible';
    buttons[0].remove();
  }

  saveLivraison() {
    console.log('this.livraison', this.livraison)
    this.livraison.status = 1;

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleUpdateLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated and saved!')
        }
      );
      
  }

  sendInLivraison() {
    this.livraison.status = 2;
    this.livraison.shipmentDate = new Date().toISOString();

    this.livraisonService.updateLivraison(this.livraison.id, this.livraison, this.token)
      .subscribe(
        {
          next: data => this.handleLivraisonServerResponse(data),
          error: err => this.handleError(err),
          complete: () => console.log('livraison was updated and sended!')
        }
      );
  }

  showPdfBonLivraison() {

    const fileURL = URL.createObjectURL(this.blobBonLivraison);
    window.open(fileURL, '_blank');
  }

  generatePdfBonLivraison() {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraison.materials = this.materials

    this.livraisonService.createBonLivraison(this.livraison, token).subscribe({
      next: () => this.toCheckBonLivraisonPdf(),
      error: err => console.error("ERROR IN FRONT", err),
      complete: () => console.log("bon livraison created!")
    })
  }

  toCheckBonLivraisonPdf() {
    this.loadBonLivraison()
  }

  confirmCancelLivraison(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'cancelLivraisonConfirmModal-title' });
  }

  cancelLivraison(infoModal: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraisonService.deleteLivraison(this.livraison.id, token).subscribe({
      next: (data: any) => {
        if (data.success) {
          this.textInfoCancelLivraisonModal = "La livraison a bien été annulée.";
        } else {
          this.textInfoCancelLivraisonModal = 'Une <span class="text-danger">erreur</span> est survenue'
            + (data.message ? (' :<br>' + data.message) : '.');
        }

        this.modalService.open(infoModal, { ariaLabelledBy: 'cancelLivraisonInfoModal-title' }).result.finally(() => {
          location.reload();
        });
      },
      error: err => {
        console.error("ERROR IN FRONT", err);
        this.textInfoCancelLivraisonModal = 'Une <span class="text-danger">erreur</span> est survenue.';
        this.modalService.open(infoModal, { ariaLabelledBy: 'cancelLivraisonInfoModal-title' });
      }
    });
  }
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
};
