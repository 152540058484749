<div *ngIf="retour">
  <br>
  <div class="panel panel-default p-3 mb-4 shadow rounded-3">
    <div class="panel-heading"><h3>{{retour.barcode}}</h3></div>
    <div class="panel-body">
      Statut : {{retour.status | retourStatus}} <br><br>

      <ng-container *ngIf="isAdmin; else notAdmin">
        <ng-container *ngIf="retour.status === 0; else notPreparated">
          <div >
            <label for="providerSelect">Centre : </label>
            <select [(ngModel)]="retour.provider.id" (ngModelChange)="onChangeProvider($event)" name="providerSelect" class="form-control" id="providerSelect">
              <option *ngFor="let p of providers" [ngValue]="p.id">{{p.nom}}</option>
            </select>
          </div>

          <ng-container *ngIf="retour.provider.id > 0">
            <div >
              <label for="franchiseSelect">Franchisé : </label>
              <select [(ngModel)]="retour.franchise.initiales" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-control" id="franchiseSelect">
                <option *ngFor="let f of franchises" [ngValue]="f.initiales.toUpperCase()">
                  <ng-container *ngIf="f.initiales.length > 0; else noFranchise">
                    {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                  </ng-container>
                  <ng-template #noFranchise>
                    -
                  </ng-template>
                </option>
              </select>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #notPreparated>
          <ng-container *ngIf="retour.provider">
            Centre : {{retour.provider.nom}}
          </ng-container>
          <br>
          <ng-container *ngIf="retour.franchise.initiales.length > 0; else noFranchise">
            Franchisé : {{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]
          </ng-container>
          <ng-template #noFranchise>
            Franchisé : -
          </ng-template>
        </ng-template>
      </ng-container>
      <ng-template #notAdmin>
        <ng-container *ngIf="retour.franchise.initiales.length > 0; else noFranchise">
          Franchisé : {{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]
        </ng-container>
        <ng-template #noFranchise>
          Franchisé : -
        </ng-template>
      </ng-template>

      <hr>

      <div *ngIf="retour.creationDate != 0">Créé le {{retour.creationDate | date:'dd MMMM y à HH:mm' : '+0200'}}</div>

      <br>

      <ng-container *ngIf="retour.status === 3">
        Début du scan le {{retour.scanStartDate | date:'dd MMMM y à HH:mm'}}
        Fin du scan le {{retour.scanFinishDate | date:'dd MMMM y à HH:mm'}}
        <br>
      </ng-container>

      <ng-container>
        <div *ngIf="isAdmin && retour.status === 0 && retour.franchise && retour.franchise.id > 0 && retour.provider.id > 0">
          <div class="d-grid gap-2">
            <button appNoDblClick (click)="validateRetour()" class="btn btn-outline-success btn-lg" type="button">
            <i class="bi bi-save"></i>
            Valider le Retour
          </button>
          </div>
          <br>
        </div>
      </ng-container>

      <ng-container *ngIf="retour.franchise && retour.provider.id > 0">
        <div *ngIf="retour.status === 1 && retour.franchise && products?.length > 0">
          <div class="d-grid gap-2">
            <button appNoDblClick (click)="saveRetour()" class="btn btn-outline-success btn-lg" type="button">
              <i class="bi bi-save"></i>
              Finaliser le Retour
            </button>
          </div>
          <br>
        </div>

        <div *ngIf="retour.status === 2 && retour.franchise && products?.length > 0">
          <div class="d-grid gap-2">
            <button appNoDblClick (click)="sendRetour($event)" class="btn btn-outline-success btn-lg" type="button">
              <i class="bi bi-truck"></i>
              Confirmer le Retour de {{retour.franchise.nomPart.toUpperCase()}} {{retour.franchise.prenomPart}} [{{retour.franchise.initiales.toUpperCase()}}]
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!--<app-scan-products-retour [token]="token" [retour]="retour" [cartons]="cartons" (updatedCarton)="updateCarton($event)"></app-scan-products-retour>-->
  <!--<app-retour-details-cartons-list [retour]="retour" [cartons]="cartons"></app-retour-details-cartons-list>-->
</div>

<div *ngIf="errorMessage">
  Erreur : {{ errorMessage }}
</div>

<div *ngIf="error">
  <pre>{{ error | json }}</pre>
</div>

<canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
