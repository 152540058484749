import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductRetourService {
  productsRetourSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  addProductRetour(productData: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);

    return this.http.post(environment.BASE_URL + '/api/product-retour', productData, { headers })
      .pipe(
        map((res: any) => {
          this.productsRetourSubject.next(productData);
          return res;
        })
      );
  }

  deleteProductRetour(retourId: any, product: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/retour/${retourId}/product/${product.id}`, { headers, body: product })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
