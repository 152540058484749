<main role="main">
  <div class="rounded-3">
    <div class="primary-block p-3 mb-4 shadow rounded-3">
      <div class="container">
        <div *ngIf="palette">
          <h1 class="display-4">Palette {{palette.serialNumber | paletteBarcode: palette.provider.id}}</h1>
          <p class="lead">Créée le {{palette.creationDate | date:'dd MMMM y à HH:mm' : '+0200'}}</p>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Modèle</h5>

                  <ng-container *ngIf="palette.status === 0; else notInCreation">
                    <select [(ngModel)]="palette.modele" (ngModelChange)="onChangePaletteModele($event)" name="paletteModeleSelect" class="form-select" id="paletteModeleSelect">
                      <option *ngFor="let m of paletteModeles" [ngValue]="m.id">{{m.nom}}</option>
                    </select>
                  </ng-container>
                  <ng-template #notInCreation>
                    <h6 class="card-subtitle mb-2 text-muted">{{palette.modele | paletteModele}}</h6>
                  </ng-template>
                </div>
              </div>
            </div>
            
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="card" style="width: 18rem; height: 7rem;">
                <div class="card-body">
                  <h5 class="card-title">Statut</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{palette.status | paletteStatus}}</h6>
                </div>
              </div>
            </div>

            <ng-container *ngIf="palette.idLivraison && livraison">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Livraison</h5>
                    <h6 class="card-subtitle mb-2 text-muted">
                      <a class="btn btn-secondary btn-sm" href="#" [routerLink]="['/livraison', livraison.id]" role="button">{{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}</a>
                    </h6>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="livraison?.franchise">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Franchisé</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{livraison.franchise.prenomPart}} {{livraison.franchise.nomPart}} </h6>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          </div> <!-- row -->

          <br>

          <ng-container *ngIf="isPalettesReceived && isCartonsReceived && !isAdmin">
            <ng-container *ngIf="(palette && palette.cartons?.length === paletteSize && paletteSize > 0 && palette.status === 0)">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="savePalette($event)" class="btn btn-success btn-lg" type="button">
                    <i class="bi bi-save"></i>
                    Finaliser la Palette
                  </button>
                </div>
              </div>
              <br>
            </ng-container>
          </ng-container>

        </div>

      </div>
  </div>

  <ng-container *ngIf="isPalettesReceived && isCartonsReceived">
    <app-scan-cartons [token]="token" [palette]="palette" [paletteModeleDetails]="paletteModeleDetails" [paletteSize]="paletteSize" (updatedPalette)="updatePalette($event)"></app-scan-cartons>
  </ng-container>

  <div *ngIf="errorMessage">
    Erreur : {{ errorMessage }}
  </div>

  <div *ngIf="error">
    <pre>{{ error | json }}</pre>
  </div>
</main>
