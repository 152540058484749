import { Component, OnInit } from '@angular/core';
import { Toast, ToastType } from '../model/toast';
import { ToastService } from '../services/toast.service';

// http://jasonwatmore.com/post/2017/06/25/angular-2-4-alert-toaster-notifications

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  toasts: Toast[] = [];

  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
    this.toastService.getToast().subscribe((toast: Toast) => {
      if (!toast) {   
        // clear toasts when an empty toast is received
        this.toasts = [];
        return;
      }

      // add toast to array
      this.toasts.push(toast);

      // remove toast after 5 seconds
      setTimeout(() => this.removeToast(toast), 3500);
    });
  }

  removeToast(toast: Toast) {
    // console.log('removeToast | toast', toast);
    this.toasts = this.toasts.filter(x => x !== toast);
    // console.log('removeToast | this.toasts', this.toasts);
  }

  cssClass(toast: Toast) {
    if (!toast) {
      return;
    }

    // return css class based on toast type
    switch (toast.type) {
      case ToastType.Primary:
        return 'toast bg-primary';
      case ToastType.Secondary:
        return 'toast bg-secondary';
      case ToastType.Success:
        return 'toast bg-success text-white';
      case ToastType.Danger:
        return 'toast bg-danger';
      case ToastType.Warning:
        return 'toast bg-warning';
      case ToastType.Info:
        return 'toast bg-info';
      case ToastType.Light:
        return 'toast bg-light';
      case ToastType.Dark:
        return 'toast bg-dark';
      default:
        return;
    }
  }

}