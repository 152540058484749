export class Provider {
  id: number;
  nom: string;
  oldProviderId: number;

  constructor(id: number, nom: string, oldProviderId: number) {
    this.id = id,
    this.nom = nom,
    this.oldProviderId = oldProviderId
  }
}
