import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import {CartonService} from '../services/carton.service';

@Component({
  selector: 'app-carton-add-form',
  templateUrl: './carton-add-form.component.html',
  styleUrls: ['./carton-add-form.component.css']
})
export class CartonAddFormComponent implements OnInit {

  form: UntypedFormGroup;
  userIsLoggedin: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private cartonService: CartonService, private authService: AuthService, public f: UntypedFormGroup) {
    this.form = f
   }

  ngOnInit() {
    this.form = this.formBuilder.group({
      serialNumber: '',
      status: 0,
      type: '',
      scanDate: new Date().toISOString()
    });

    this.checkUserIsLoggedin();
  }

  checkUserIsLoggedin() {
    if(this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  createCarton(cartonData: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.cartonService.addCarton(this.form.value, token).subscribe();
    this.form.reset();
  }

}
