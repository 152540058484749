import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable , Subject } from 'rxjs';
import { Toast, ToastType } from '../model/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private subject = new Subject<Toast>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear toast messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear toast messages
          // this.clear();
        }
      }
    });
  }

  getToast(): Observable<any> {
    return this.subject.asObservable();
  }

  primary(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Primary, message, title, smallText, keepAfterRouteChange);
  }

  secondary(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Secondary, message, title, smallText, keepAfterRouteChange);
  }

  success(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Success, message, title, smallText, keepAfterRouteChange);
  }

  danger(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Danger, message, title, smallText, keepAfterRouteChange);
  }

  warning(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Warning, message, title, smallText, keepAfterRouteChange);
  }

  info(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Info, message, title, smallText, keepAfterRouteChange);
  }

  light(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Light, message, title, smallText, keepAfterRouteChange);
  }

  dark(message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.toast(ToastType.Dark, message, title, smallText, keepAfterRouteChange);
  }

  toast(type: ToastType, message: string, title: string, smallText: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Toast>{ type: type, message: message, title: title, smallText: smallText });
  }

  clear() {
    // clear toats
    // console.group('toast.service | clear()');
    // this.subject.next(null);
  }
}
