<main role="main">
  <div class="jumbotron">
    <div class="container">
      <div *ngIf="inventaire">
        <h1 class="display-4">Inventaire {{inventaire.franchise}}</h1>
        
        
          <div *ngIf="(inventaire.cartons?.length > 0)">
            <br>

            <div class="fs-group row">
              <div *ngFor="let carton of inventaire.cartons" class="input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2">

                <ng-template #popoverTitle>
                  <ng-container *ngIf="carton.products?.length <= 1">
                    {{carton.products.length}} {{carton.type | cartonType}}
                  </ng-container>
                  <ng-container *ngIf="carton.products?.length > 1">
                    {{carton.products.length}} {{carton.type | cartonType}}s
                  </ng-container>
                </ng-template>
                
                <ng-template #listProducts>
                  <div class="container">
                    <div class="row">
                      <ng-container *ngFor="let product of carton.products; let i = index;">
                        <div class="col" *ngIf="i%2 == 0">
                          {{product.barcode}}<br>
                        </div>
                        <div class="col" *ngIf="i%2 != 0">
                          {{product.barcode}}<br>
                        </div>
                      </ng-container>
                    </div>

                    <hr>

                    <div class="popover-footer">
                      <i><b>{{carton.creationDate * 1000 | date:'dd MMMM y à HH:mm'}}</b></i>
                    </div>
                  </div>
                </ng-template>

                <button type="button" class="btn btn-lg btn-outline-secondary text-center form-control numFSinput" popoverClass="popover-products" ngbPopover="listProducts" popoverTitle="popoverTitle" placement="bottom">
                  {{carton.serialNumber}}
                </button>
              </div>
            </div>
          </div>

      </div>

    </div> <!-- container -->
  </div> <!-- jumbotron -->

  <div *ngIf="errorHandled">
    <div class="card text-white bg-danger mb-3 mx-auto" style="max-width: 18rem;">
      <div class="card-header">Erreur</div>
      <div class="card-body">
        <h5 class="card-title">{{ errorHandled.id }}</h5>
        <p class="card-text">
          {{ errorHandled.message }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="error">
    <ng-container *ngIf="isAdmin">
      <br>
      <pre><code>{{ error | json }}</code></pre>
    </ng-container>
  </div>

  <canvas id="barcode" style="visibility:hidden; display: none;"></canvas>

</main>
