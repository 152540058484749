<ng-container *ngIf="isCentre">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Scan du code-barre</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Veuillez scanner le code-barre du Produit</h6>
                    <p class="card-text" #scanStatus id="scanStatus">En attente du scan</p>
                </div>
            </div>
        </div>
    </div>

    <br>
</ng-container>

<div class="row">
    <div class="col">
        <div *ngIf="(retour && centreProducts.length > 0)">

            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <h5 class="card-title">Gestion des scans</h5>
                        <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-scans" aria-expanded="false" aria-controls="collapse-scans">
                            Voir/Cacher
                        </button>
                    </div>
                    <div class="fs-group row collapse" id="collapse-scans">
                        <div *ngFor="let product of centreProducts"
                            class="d-inline-flex align-items-center w-auto input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                            <input value="{{product.barcode}}" type="text" maxlength="8"
                                class="text-center form-control numFSinput input-product" aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm" style="text-transform:uppercase"
                                autocomplete="off" readonly="" title="resultInput">

                            <ng-container *ngIf="!isAdmin">
                                <button appNoDblClick (click)="removeProductRetour(product)"
                                    class="btn btn-outline-secondary fs-delete" type="button">
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

<canvas id="barcode" style="visibility:hidden; display: none;"></canvas>