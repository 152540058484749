import {ChangeDetectorRef, Component, OnInit, OnDestroy} from '@angular/core';
import {FranchiseService} from '../services/franchise.service';
import {RetourService} from '../services/retour.service';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {RetourBarcodePipe} from '../pipes/retour-barcode.pipe';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import {Retour} from '../model/retour';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-history-retours-details',
  templateUrl: './history-retours-details.component.html',
  styleUrls: ['./history-retours-details.component.css']
})
export class HistoryRetoursDetailsComponent implements OnInit, OnDestroy {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  provider: any;
  userIsLoggedin: boolean = false;

  retour: any;
  // franchise: Franchise = null;
  // cartons = [];
  products: any = [];
  error: any = null;
  errorMessage: string = '';
  parametersObservable: any;
  source: any;

  isRetourReceived: boolean = false;
  // isFranchiseReceived = false;
  isCartonsReceived: boolean = false;
  isProductsReceived: boolean = false;

  constructor(private retourService: RetourService,
              private franchiseService: FranchiseService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private ref: ChangeDetectorRef) { }

  /**
   * ChangeDetectorRef
   * https://stackoverflow.com/questions/38445670/angular-2-view-will-not-update-after-variable-change-in-subscribe
   */

  ngOnInit() {

    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      console.log(this.decodedToken);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }

      const id = this.activatedRoute.snapshot.params['id'];

      this.parametersObservable = this.activatedRoute.params.subscribe((params = {}) => {
        console.log(params['source']);
        this.source = params['source'];
        this.loadRetour(id);
        // this.loadCartonsFromRetour(id);
        this.loadProductsFromRetour(id);
      });
    }
  }

  // Don't forget to unsubscribe from the Observable
  ngOnDestroy() {
    if (this.parametersObservable != null) {
      this.parametersObservable.unsubscribe();
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadRetour(id: number) {
    this.retourService.getRetour(id, this.token)
      .subscribe({
        next: (data) => this.handleServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log('retour loaded!')
      });
  }

  /*loadCartonsFromRetour(id) {
    this.retourService.getCartonsFromRetour(id, this.token)
      .subscribe(
        data => {
          this.handleLivraisonServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );
  }*/

  loadProductsFromRetour(id: string) {
    this.retourService.getProductsFromRetour(this.source, id, this.token)
      .subscribe({
        next: (data) => this.handleServerResponse(data),
        error: (err) => this.handleError(err),
        complete: () => console.log('products retour loaded!')
      });
  }

  /*loadFranchise(idFranchise) {
    this.franchiseService.getFranchise(idFranchise, this.token)
      .subscribe(
        data => {
          this.handleLivraisonServerResponse(data);
        },
        error => {
          this.handleError(error);
        }
      );
  }*/

  handleServerResponse(response: any = {}) {
    console.log('handleServerResponse ', response);

    if (response.success) {
      if (response.retour) {
        this.retour = response.retour;
        this.retour.barcode = new RetourBarcodePipe().transform(this.retour.serialNumber, this.retour.provider.id);

        console.log('this.retour', this.retour);

        // this.retour.palettes = []; // On force l'initialisation des palettes à 0.

        // this.loadPalettesFromRetour(this.retour.id);
        // this.loadCartonsFromRetour(this.retour.id);
        // this.loadProductsFromRetour(this.retour.id);
        this.isRetourReceived = true;

        // this.loadFranchise(this.retour.franchise);
      }

      /*if (response.cartons) {
        this.cartons = response.cartons.sort((a, b) => {
          return a.id - b.id;
        });

        console.log('this.cartons', this.cartons);
        this.isCartonsReceived = true;
      }*/

      if (response.products) {
        this.products = response.products.sort((a: { id: number; }, b: { id: number; }) => {
          return a.id - b.id;
        });

        console.log('this.products', this.products);
        this.isProductsReceived = true;
      }

      /*if (this.isRetourReceived) {
        if (response.franchise) {
          this.franchise = response.franchise;

          console.log('this.franchise', this.franchise);
          this.isFranchiseReceived = true;
        }
      }*/

      // console.log(this.retour);
      if (this.isRetourReceived
        && this.isCartonsReceived
        && this.isProductsReceived) {
        // this.remakeData();
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: { statusText: any; }) {
    console.log('handleError', error.statusText);
    this.error = error;
  }

  /*remakeData() {
    let i = 0;
    const iMax = this.products.length;
    for (; i < iMax; i++) {
      const foundIndex = this.cartons.findIndex(x => x.id === this.products[i].idCartonRetour);
      console.log('CARTON ' + this.cartons[foundIndex].id);

      if (!this.cartons[foundIndex].hasOwnProperty('products')) {
        console.log('ADD products array to CARTON ' + this.cartons[foundIndex].id);
        this.cartons[foundIndex].products = [];
      }

      this.cartons[foundIndex].products.push(this.products[i]);
    }

    this.ref.detectChanges();
  }*/

  /*generateContentPdfDetailRetour() {
    const content = [];

    let columns = [];

    content.push({ text: 'Retour de matériel de ' +
        this.retour.franchise.abr_jur + ' ' + this.retour.franchise.nom_soc,
      fontSize: 12,
      alignment: 'center' });
    content.push({ text: 'représentée par ' + this.retour.franchise.prenomPart + ' ' + this.retour.franchise.nomPart.toUpperCase(),
      fontSize: 12,
      alignment: 'center' });
    content.push({ text: ' ', fontSize: 1 });

    let numCol = 0;
    let i = 0;
    const iMax = this.cartons.length;
    for (; i < iMax; i++) { // LOOP in CARTONS
      console.log('CARTON ' + (i + 1) + '/' + iMax);
      // console.log("CARTON "+i+"/"+iMax);
      const carton = this.cartons[i];
      console.log('CARTON ' + carton.id);
      console.log(carton);

      if (numCol === 0) {
        columns = [];
      }

      const cartonItemObj = {
        width: 'auto',
        table: {
          headerRows: 1,
          widths: [ 'auto' ],
          body: [
            [ { text: '', fontSize: 8 } ],
            [ { stack: [ ], fontSize: 8 } ]
          ]
        }
      };

      let cartonItem = Object.assign({}, cartonItemObj);
      // cartonItem.table.body[0][0]['text'] = carton.barcode;
      cartonItem.table.body[0][0]['text'] = ' ';
      cartonItem.table.body[1][0]['stack'] = carton.products.map(p => p.barcode);
      console.log('cartonItem.table.body[0][0][\'text\']', cartonItem.table.body[0][0]['text']);
      console.log('cartonItem.table.body[1][0][\'stack\']', cartonItem.table.body[1][0]['stack']);
      console.log('cartonItem', cartonItem);

      if (i === iMax - 1) { // On est arrivé au dernier carton de la Palette : On doit mettre le champ de signature.
        columns.push(cartonItem);
        cartonItem = null;

        let signChamp = {
          width: 'auto',
          table: {
            headerRows: 1,
            widths: [ 179 ],
            body: [
              [ { text: 'Date, Cachet et Signature de ' + this.retour.provider.nom, fontSize: 8 } ],
              [ { stack: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '], fontSize: 8 } ]
            ]
          }
        };

        columns.push(signChamp);
        signChamp = null;

        content.push({ columns, columnGap: 10 });
        columns = null;
      } else {
        columns.push(cartonItem);
        cartonItem = null;

        if (numCol < 9) {
          if (numCol === 8) {
            content.push({ columns, columnGap: 10 });
            columns = null;
            content.push({ text: ' ', fontSize: 2 });
            numCol = 0;
          } else {
            numCol++;
          }
        }
      }
    }

    return content;
  }*/

  generateContentPdfDetailRetour() {
    const content: any = [];

    /*const palette = this.retour.palettes[j];
    console.log('PALETTE ' + palette.id);
    console.log(palette);*/

    let columns: any = [];

    content.push({ text: 'Retour de matériel de ' +
        this.retour.franchise.abr_jur + ' ' + this.retour.franchise.nom_soc,
      fontSize: 12,
      alignment: 'center' });
    content.push({ text: 'représentée par ' + this.retour.franchise.prenomPart + ' ' + this.retour.franchise.nomPart.toUpperCase(),
      fontSize: 12,
      alignment: 'center' });
    content.push({ text: ' ', fontSize: 1 });

    let numCol = 0;
    let i = 0;
    const iMax = this.products.length;
    for (; i < iMax; i++) { // LOOP in PRODUCTS
      console.log('PRODUCTS ' + (i + 1) + '/' + iMax);
      // console.log("CARTON "+i+"/"+iMax);
      const product = this.products[i];
      console.log('PRODUCT ' + product.id + ' - ' + product.barcode);
      console.log(product);

      if (numCol === 0) {
        columns = [];
      }

      const cartonItemObj = {
        width: 'auto',
        table: {
          headerRows: 1,
          widths: [ 'auto' ],
          body: [
            [ { text: '', fontSize: 8 } ],
            [ { stack: [ ], fontSize: 8 } ]
          ]
        }
      };

      let cartonItem: any = cartonItemObj;
      // cartonItem.table.body[0][0]['text'] = carton.barcode;
      cartonItem.table.body[0][0]['text'] = ' ';
      // cartonItem.table.body[1][0]['stack'] = carton.products.map(p => p.barcode);
      console.log('cartonItem.table.body[0][0][\'text\']', cartonItem.table.body[0][0]['text']);
      console.log('cartonItem.table.body[1][0][\'stack\']', cartonItem.table.body[1][0]['stack']);
      console.log('cartonItem', cartonItem);

      if (i === iMax - 1) { // On est arrivé au dernier carton de la Palette : On doit mettre le champ de signature.
        columns.push(cartonItem);
        cartonItem = null;

        let signChamp: any = {
          width: 'auto',
          table: {
            headerRows: 1,
            widths: [ 179 ],
            body: [
              [ { text: 'Date, Cachet et Signature de ' + this.retour.provider.nom, fontSize: 8 } ],
              [ { stack: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' '], fontSize: 8 } ]
            ]
          }
        };

        /*if (jMax > 1 && j !== (jMax - 1) ) { // Ajout de page s'il y a + 1 seule Palette et que l'on est pas à la dernière Palette.
          console.log('On ajoute une page : (' + (j + 1) + '/' + (jMax - 1));

          signChamp['pageBreak'] = 'after';
        }*/

        columns.push(signChamp);
        signChamp = null;

        content.push({ columns, columnGap: 10 });
        columns = null;

        /*if (jMax > 1 && j !== (jMax - 1) ) {
          content.push({ text: ' ', fontSize: 2 });
        }*/
      } else {
        columns.push(cartonItem);
        cartonItem = null;

        if (numCol < 9) {
          if (numCol === 8) {
            content.push({ columns, columnGap: 10 });
            columns = null;
            content.push({ text: ' ', fontSize: 2 });
            numCol = 0;
          } else {
            numCol++;
          }
        }
      }
    }

    return content;
  }

  showPdfDetailRetour() {
    // http://pdfmake.org/playground.html
    // playground requires you to assign document definition to a variable called dd

    // let pdfMakeContent = this.generateContentPdfDetailRetour();
    // console.log('pdfMakeContent', pdfMakeContent);

    const docDefinition: any = {
      // a string or { width: number, height: number }
      pageSize: 'A4',

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 5, 15, 5, 15 ],

      info: {
        title: 'Détail_Retour_' + this.retour.barcode,
        author: 'ALPA SYSTEMS INTERNATIONAL',
        subject: 'Détail Retour ' + this.retour.barcode,
        keywords: 'retour bordereau bon détails traçabilité palette carton cassette biocold process asi alpa systems international',
      },

      // header: 'Bordereau de Retour '+this.retour.barcode,
      header: {
        columns: [
          { text: 'Détail du Retour ' + this.retour.barcode, alignment: 'left' },
          { text: this.retour.provider.nom.toLocaleUpperCase(), alignment: 'right' }
          // { text: new Date(this.retour.creationDate * 1000).toLocaleDateString(), alignment: 'right' }
        ]
      },

      footer: function(currentPage: { toString: () => string; }, pageCount: { toString: () => any; }) {
        return {
          columns: [
            { text: 'BIOCOLD PROCESS©', alignment: 'left' },
            {
              text: [
                { text: 'Page ' + currentPage.toString(), italics: true, fontSize: 10 },
                { text: '/', italics: true, fontSize: 10 },
                { text: pageCount.toString(), italics: true, fontSize: 10 }
              ],
              alignment: 'center',
            },
            { text: 'ALPA SYSTEMS INTERNATIONAL', alignment: 'right' }
          ],
          margin: [10, 0]
        };
      },

      content: this.generateContentPdfDetailRetour()

    };

    // open the PDF in a new window
    pdfMake.createPdf(docDefinition).open();

    // print the PDF
    // pdfMake.createPdf(docDefinition).print();

    // download the PDF
    // pdfMake.createPdf(docDefinition).download('Bordereau_Retour_'+this.retour.barcode+'.pdf');
  }
}

