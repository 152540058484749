<ng-container *ngIf="(carton && carton.products?.length === carton.cartonSize && carton.status === 1)">
    <div class="row">
      <div class="col">
        <div class="d-grid gap-2">
          <button appNoDblClick (click)="printBarcodeStickerPdf()" class="btn btn-primary btn-lg" type="button">
            <i class="bi bi-printer"></i>
            Imprimer l'étiquette du Carton
          </button>
        </div>
      </div>
    </div>
  
    <br>
  </ng-container>
  
  <ng-container *ngIf="!isAdmin && (carton && (carton.status === 0) && (carton.products?.length >= 0) && (carton.cartonSize > 0) && (carton.products?.length <= (carton.cartonSize-1)) || (carton.status === 0 && carton.cartonSize === -1) )">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Scan du code-barre</h5>
            <h6 class="card-subtitle mb-2 text-muted">Veuillez scanner le code-barre du Produit</h6>
            <p class="card-text" #scanStatus id="scanStatus">En attente du scan</p>
          </div>
        </div>
      </div>
    </div>
  
    <br>
  </ng-container>
  
  <div class="row">
    <div class="col">
      <div *ngIf="(carton && carton.products?.length > 0)">
  
        <div class="card">
          <div class="card-body">
            <ng-container *ngIf="carton.products.length === 1">
              <h3>{{carton.type | cartonType}}</h3>
            </ng-container>
            <ng-container *ngIf="carton.products.length > 1">
              <h3>{{carton.type | cartonType}}s</h3>
            </ng-container>
            <h4 class="card-subtitle mb-2 text-muted">{{ carton.products.length || '0' }} sur {{carton.cartonSize}}</h4>
  
            <br>
  
            <div class="fs-group row">
              <div *ngFor="let product of carton.products" class="d-inline-flex align-items-center w-auto input-group input-group-sm mb-3 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                <input value="{{product.barcode}}" type="text" maxlength="8" class="text-center form-control numFSinput input-product" aria-label="Small" aria-describedby="inputGroup-sizing-sm" style="text-transform:uppercase" autocomplete="off" readonly="" title="resultInput">
  
                <ng-container *ngIf="!isAdmin">
                  <div *ngIf="carton.status === 0">
                    <button appNoDblClick (click)="removeProduct($event)" class="btn btn-outline-secondary fs-delete" type="button">
											<i class="bi bi-x-lg"></i>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
  </div>
  
  <canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
  