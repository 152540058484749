import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {LivraisonService} from '../services/livraison.service';
import {Livraison} from '../model/livraison';
import {Franchise} from '../model/franchise';
import {Provider} from '../model/provider';

@Component({
  selector: 'app-livraison-list',
  templateUrl: './livraison-list.component.html',
  styleUrls: ['./livraison-list.component.css']
})
export class LivraisonListComponent implements OnInit {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: any[] = [];
  provider: any = null;
  livraisons: Livraison[] = [];
  livraisons0: Livraison[] = [];
  livraisons1: Livraison[] = [];
  livraisons2: Livraison[] = [];
  livraisons3: Livraison[] = [];
  livraisons6: Livraison[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;

  livraisonCreated: any;
  errorMessage: string = '';

  constructor(private router: Router, private livraisonService: LivraisonService, private authService: AuthService) {
  }

  ngOnInit() {
    // window.scrollTo(0, 0);
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
      this.provider = this.decodedToken.provider;
      if (this.isAdmin) {
        this.loadLivraisons();
      } else {
        this.loadLivraisons();
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadLivraisons() {
    this.livraisonService.getLivraisons(this.token)
      .subscribe(
        {
          next: data => this.displayLivraisons(data),
          error: err => console.error(err),
          complete: () => console.log('livraisons loaded!')
        }
      );
  }

  displayLivraisons(response: any = {}) {

    if (response.success) {
      this.livraisons = response.livraisons;

      this.livraisons0 = this.livraisons.filter((livraison: { status: number; }) => livraison.status === 0);
      this.livraisons1 = this.livraisons.filter((livraison: { status: number; }) => livraison.status === 1);
      this.livraisons2 = this.livraisons.filter((livraison: { status: number; }) => livraison.status === 2);
      this.livraisons3 = this.livraisons.filter((livraison: { status: number; }) => livraison.status === 3);
      this.livraisons6 = this.livraisons.filter((livraison: { status: number; }) => livraison.status === 6);

      if (this.isAdmin) {
        const providersLivraisons = this.livraisons.map((livraison: { provider: any; }) => livraison.provider);

        const uniqueLivraisonsArray = (data: any[]) => [...new Set(data.map((o: any) => JSON.stringify(o)))].map(s => JSON.parse(s.toString()));
        this.providers = uniqueLivraisonsArray(providersLivraisons).sort(function(a, b) {
          return a.id - b.id;
        });

        for (let p = 0; p < this.providers.length; p++) {
          this.providers[p]['livraisons0'] = this.livraisons0.filter((livraison: { provider: { id: any; }; }) => livraison.provider.id === this.providers[p].id);
          this.providers[p]['livraisons1'] = this.livraisons1.filter((livraison: { provider: { id: any; }; }) => livraison.provider.id === this.providers[p].id);
          this.providers[p]['livraisons2'] = this.livraisons2.filter((livraison: { provider: { id: any; }; }) => livraison.provider.id === this.providers[p].id);
          this.providers[p]['livraisons3'] = this.livraisons3.filter((livraison: { provider: { id: any; }; }) => livraison.provider.id === this.providers[p].id);
          this.providers[p]['livraisons6'] = this.livraisons6.filter((livraison: { provider: { id: any; }; }) => livraison.provider.id === this.providers[p].id);
        }
      }
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  showHistory() {
    this.router.navigate([`/livraisons/historique`]);
  }

  showAllAdressesLivraison() {
    this.router.navigate([`/livraisons/adresses`]);
  }

  createLivraison() {
    const livraison: Livraison = <Livraison><unknown>{
      id: Date.now(),
      provider: { id: this.provider.id },
      serialNumber: 0,
      status: 0,
      franchise: {},
      address: {},
      validateByASI: false,
      nbPalettes: 0,
      barcode: '',
      creationDate: null,
      //creationDate: Math.floor(Date.now() / 1000),
      validationDate: null,
      shipmentDate: null,
      palettes: []
    };
    console.log('NEW livraison => ' + JSON.stringify(livraison));

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    this.livraisonService.addLivraison(livraison, token).subscribe(
      {
        next: data => this.handleServerResponse(data),
        error: err => console.error(err),
        complete: () => console.log('livraison was created!')
      }
    );
  }

  handleServerResponse(response: { success: any; livraison: any; message: string; }) {
    console.log('handleServerResponse', response);

    if (response.success) {
      this.livraisonCreated = response.livraison;
      this.router.navigateByUrl(`/livraison/${this.livraisonCreated.id}`);
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  handleError(error: any = {}) {
    console.log('handleError ', error.statusText);
    this.error = error;
  }
}
