import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'materielAsiConditionnement'
})
export class MaterielAsiConditionnementPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value.toUpperCase()) {
      case 'C':
        return 'Carton';
      case 'U':
        return 'À l\'unité';
      default:
        return 'Inconnu';
    }
  }

}
