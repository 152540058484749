import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { FranchiseService } from '../services/franchise.service';
import { ActivatedRoute } from '@angular/router';
import { SocieteService } from '../services/societe.service';
// import { FileReader } from 'filereader';

@Component({
  selector: 'app-franchise-add-form',
  templateUrl: './franchise-add-form.component.html',
  styleUrls: ['./franchise-add-form.component.css']
})
export class FranchiseAddFormComponent implements OnInit {

  id: any = '';
  franchise_deja_cree: boolean = true;

  sameadresse: boolean = false;

  franchiseCreated: boolean = false;
  societeCreated: boolean = false;
  societeAddressFacturationCreated: boolean = false;
  societeAddressLivraisonCreated: boolean = false;
  societeAddressLivraison2Created: boolean = false;

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  userIsLoggedin: boolean = false;

  initiales: string = '';
  actif: number = 0;
  societe_id: number = 0;
  franchise_id: number = 0;

  file: any;
  toCreateFranchise: any;
  result: any;

  fmessage: string = '';
  amessage: string = '';
  emessage: string = '';

  societe_adresse_facturation: {
    initiales: string,
    nomFranchise: string,
    prenomFranchise: string,
    telephonePortable: string,
    email: string,
    isActif: number,
    dateCreation: string,
    nom: string,
    forme_juridique: string,
    abreviation_juridique: string,
    siren: number,
    complement_siren: number,
    naf: string,
    TVA_intracom: string,
    capital: number,
    ville_RCS: string,
    actif: number,
    id_franchise: number,
    adresse: string,
    complement_adresse: string,
    code_postal: string,
    ville: string,
    accessibilite_19T: number,
    dateAjout: string
  } = {
    initiales: '',
    nomFranchise: '',
    prenomFranchise: '',
    telephonePortable: '',
    email: '',
    isActif: 0,
    dateCreation: '',
    nom: '',
    forme_juridique: '',
    abreviation_juridique: '',
    siren: 0,
    complement_siren: 0,
    naf: '',
    TVA_intracom: '',
    capital: 0,
    ville_RCS: '',
    actif: 0,
    id_franchise: 0,
    adresse: '',
    complement_adresse: '',
    code_postal: '',
    ville: '',
    accessibilite_19T: 0,
    dateAjout: ''
  };
  societe_adresse_livraison: {
    initiales: string,
    nomFranchise: string,
    prenomFranchise: string,
    telephonePortable: string,
    email: string,
    isActif: number,
    dateCreation: string,
    nom: string,
    forme_juridique: string,
    abreviation_juridique: string,
    siren: number,
    complement_siren: number,
    naf: string,
    TVA_intracom: string,
    actif: number,
    id_franchise: number,
    adresse: string,
    complement_adresse: string,
    code_postal: string,
    ville: string,
    latitude: number | null,
    longitude: number | null,
    accessibilite_19T: number,
    type_adresse: number,
    dateAjout: string
  } = {
    initiales: '',
    nomFranchise: '',
    prenomFranchise: '',
    telephonePortable: '',
    email: '',
    isActif: 0,
    dateCreation: '',
    nom: '',
    forme_juridique: '',
    abreviation_juridique: '',
    siren: 0,
    complement_siren: 0,
    naf: '',
    TVA_intracom: '',
    actif: 0,
    id_franchise: 0,
    adresse: '',
    complement_adresse: '',
    code_postal: '',
    ville: '',
    latitude: null,
    longitude: null,
    accessibilite_19T: 0,
    type_adresse: 1,
    dateAjout: ''
  };

  constructor(private franchiseService: FranchiseService,
                      private societeService: SocieteService,
                      private activatedRoute: ActivatedRoute,
                      private authService: AuthService) { }
  

  ngOnInit(): void {
    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);
      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }
    }

    this.id = this.activatedRoute.snapshot.params['id'];

    if(this.id == 'create'){
      this.franchise_deja_cree = false;
    }
  }

  // getNextId() {
  //   const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

  //   this.franchiseService.getNextId(token).subscribe({
  //     next: (data)
  //   })
  // }

  handleFileInput(files: any) {
    this.file = files.item(0);
    const parsedFile = new FileReader();
    parsedFile.readAsText(this.file, "UTF-8");
    parsedFile.onload = () => {
      this.result = parsedFile.result;
      this.toCreateFranchise = JSON.parse(this.result);
      const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
      console.log("to create json", this.toCreateFranchise);
      // this.franchiseCreated = true;
      // this.societeCreated = true;
      // this.societeAddressFacturationCreated = true;
      // this.societeAddressLivraisonCreated = true;

    }
    parsedFile.onerror = (error: any) => {
      console.log(error);
    }
  }

  validateFranchise(values: any) {
    console.log("VALUES", values)
    this.franchiseCreated = true;
    this.initiales = values.initiales;
    this.actif = values.actif;
    this.franchise_id = values.id;
    this.fmessage = 'Franchisé enregistré.'
    console.log("franchise validated");
    this.onSubmitFranchise(values);
  }

  onSubmitFranchise(franchise: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    franchise.dateCreation = new Date().toISOString().slice(0, 19).replace('T', ' ');
    this.franchiseService.addFranchise(franchise, token).subscribe(
      {
        next: data => this.handleFranchiseResponse(data),
        error: err => console.error(err),
        complete: () => console.log("franchisé was created!")
      }
    )
  }

  handleFranchiseResponse(response: any) {
    console.log("franchise response", response)
    if(response.success) {
      console.log("SUCCESS", response);
      this.franchise_id = response.franchise.id
      console.log("nex id", this.franchise_id)
    } else {
      console.log("FAIL", response)
    }
  }

  validateSociete(values: any) {
    this.societeCreated = true;
    this.fmessage = 'Société enregistrée.'
    console.log("societe validated");
    console.log("values societe (2)", values);
    this.societe_adresse_facturation = values;
    console.log('this.societe_adresse_facturation', this.societe_adresse_facturation)
    this.societe_adresse_livraison = values;
    console.log('this.societe_adresse_livraison', this.societe_adresse_livraison)
  }

  // onSubmitSociete(societe: any) {
  //   const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    
  //   societe.id_franchise = this.initiales;
  //   societe.actif = this.actif;

  //   this.societeService.addSociete(societe, token).subscribe(
  //     {
  //       next: (data: any) => this.handleSocieteResponse(data),
  //       error: (err: any) => console.error(err),
  //       complete: () => console.log("societe was created!")
  //     }
  //   )
  // }

  // handleSocieteResponse(response: any) {
  //   this.societe_id = response.societe.id;
  //   console.log("SOCIETE ID RECEIVED AFTER SUBMIT", this.societe_id)
  //   if(response.success) {
  //     console.log("SUCCESS", response);
  //     this.societe_id = response.societe.id;
  //     console.log("this societe id", this.societe_id)
  //   } else {
  //     console.log("FAIL", response)
  //   }
  // }

  validateFranchiseSocieteForm(franchise: any, societe: any) {
    console.log('franchise', franchise)
    console.log('societe', societe);
    this.societe_adresse_facturation.initiales = franchise.initiales;
    this.societe_adresse_facturation.nomFranchise = franchise.nom;
    this.societe_adresse_facturation.prenomFranchise = franchise.prenom;
    this.societe_adresse_facturation.email = franchise.email;
    this.societe_adresse_facturation.telephonePortable = franchise.numero;
    this.societe_adresse_facturation.isActif = franchise.actif;
    this.societe_adresse_facturation.nom = societe.nom_societe;
    this.societe_adresse_facturation.forme_juridique = societe.forme_juridique;
    this.societe_adresse_facturation.abreviation_juridique = societe.abreviation_juridique;
    this.societe_adresse_facturation.siren = societe.siren;
    this.societe_adresse_facturation.complement_siren = societe.complement_siren;
    this.societe_adresse_facturation.naf = societe.naf;
    this.societe_adresse_facturation.TVA_intracom = societe.TVA_intracom;
    this.societe_adresse_facturation.capital = societe.capital;
    this.societe_adresse_facturation.ville_RCS = societe.ville_RCS;
    this.societe_adresse_facturation.actif = societe.actif;
    this.societe_adresse_facturation.id_franchise = this.franchise_id;

    this.societe_adresse_livraison.nom = societe.nom_societe;
    this.societe_adresse_livraison.forme_juridique = societe.forme_juridique;
    this.societe_adresse_livraison.abreviation_juridique = societe.abreviation_juridique;
    this.societe_adresse_livraison.siren = societe.siren;
    this.societe_adresse_livraison.complement_siren = societe.complement_siren;
    this.societe_adresse_livraison.naf = societe.naf;
    this.societe_adresse_livraison.TVA_intracom = societe.TVA_intracom;
    this.societe_adresse_livraison.actif = societe.actif;
    this.societe_adresse_livraison.id_franchise = this.franchise_id;

    this.emessage = 'Franchisé et société enregistrés.'
    console.log("societe", this.societe_adresse_livraison)
  }

  validateSocieteAdresseFacturation(adresse: any) {
    this.societe_adresse_facturation.adresse = adresse.adresse;
    this.societe_adresse_facturation.complement_adresse = adresse.complement_adresse;
    this.societe_adresse_facturation.code_postal = adresse.code_postal;
    this.societe_adresse_facturation.ville = adresse.ville;
    //this.societe_adresse_facturation.capital = adresse.capital;
    //this.societe_adresse_facturation.ville_RCS = adresse.ville_RCS;

    this.societeAddressFacturationCreated = true;
    this.amessage = 'Adresse de société enregistrée.'
    console.log("societe adresse facturation validated", this.societe_adresse_facturation);
    this.onSubmitSocieteAdresseFacturation(this.societe_adresse_facturation);
  }

  onSubmitSocieteAdresseFacturation(societeAddressFacturation: any) {
    console.log('societeAdresse facturation param before push on db', societeAddressFacturation)
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    societeAddressFacturation.dateAjout = new Date().toISOString().slice(0, 19).replace('T', ' ');

    this.societeService.addSocieteAdresseFacturation(societeAddressFacturation, token).subscribe(
      {
        next: (data: any) => this.handleSocieteAdresseFacturationResponse(data),
        error: (err: any) => console.error(err),
        complete: () => console.log("societe address was created!")
      }
    )
  }

  handleSocieteAdresseFacturationResponse(response: any) {
    if(response.success) {
      console.log("SUCCESS", response);
    } else {
      console.log("FAIL", response)
    }
  }

  useSameAddress(event: any) {
    this.sameadresse = true;
  }

  validateSocieteAdresseLivraison(livraison: any, typeAdresse: number) {
    console.log('LIVRAISON after valider blue button', livraison)
    this.societe_adresse_livraison.adresse = livraison.adresse;
    this.societe_adresse_livraison.complement_adresse = livraison.complement_adresse;
    this.societe_adresse_livraison.code_postal = livraison.code_postal;
    this.societe_adresse_livraison.ville = livraison.ville;
    this.societe_adresse_livraison.latitude = isNaN(parseInt(livraison.latitude)) ? null : livraison.latitude;
    this.societe_adresse_livraison.longitude = isNaN(parseInt(livraison.longitude)) ? null : livraison.longitude;
    this.societe_adresse_livraison.accessibilite_19T = livraison.accessibilite_19T ?? 0;
    this.societe_adresse_livraison.type_adresse = typeAdresse;

    console.log("societe_adresse_livraison", this.societe_adresse_livraison);
    console.log("livraison from parameter", livraison);

    if (typeAdresse === 1) {
      this.societeAddressLivraisonCreated = true;
    } else if (typeAdresse === 2) {
      this.societeAddressLivraison2Created = true;
    }

    this.amessage = 'Adresse de livraison enregistrée.'
    console.log("societe adresse validated", this.societe_adresse_livraison);
    this.onSubmitSocieteAdresseLivraison(this.societe_adresse_livraison);
  }

  onSubmitSocieteAdresseLivraison(societeAddressLivraison: any) {
    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;
    console.log("livraison", societeAddressLivraison)
    societeAddressLivraison.dateAjout = new Date().toISOString().slice(0, 19).replace('T', ' ');

    this.societeService.addSocieteAdresseLivraison(societeAddressLivraison, token).subscribe(
      {
        next: (data: any) => this.handleSocieteAdresseLivraisonResponse(data),
        error: (err: any) => console.error(err),
        complete: () => console.log("societe address was created!")
      }
    )
  }
  
  handleSocieteAdresseLivraisonResponse(response: any) {
    if(response.success) {
      console.log("SUCCESS", response);
    } else {
      console.log("FAIL", response)
    }
  }

  validateAdresseFacturationLivraisonForm() {
    window.document.location.href = `franchise/${this.franchise_id}`
  }
}
