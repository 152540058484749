<div class="container">

    <main role="main" class="container">
  
      <ng-container>
        <div class="d-flex align-items-center p-3 my-3 text-white-50 bg-dark rounded box-shadow">
          <div class="lh-100">
            <button type="button" class="btn btn-lg btn-primary" appNoDblClick (click)="createPalette()">Créer une Palette</button>
          </div>
        </div>
      </ng-container>
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En cours de création ({{ palettesStatus0.length }})</h3>
  
        <ng-container *ngIf="palettesStatus0.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.palettesStatus0.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.palettesStatus0.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row p-2">
                      <ng-container *ngFor="let palette of p.palettesStatus0">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-danger p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            <div class="media row p-2">
              <ng-container *ngFor="let palette of palettesStatus0">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <a class="btn btn-outline-danger p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div> <!-- <div class="media row pt-3"> -->
  
      <div class="my-3 p-3 bg-white rounded box-shadow">
        <h3 class="border-bottom border-gray pb-2 mb-0">En attente d'attribution ({{ palettesStatus1.length }})</h3>
        
        <ng-container *ngIf="palettesStatus1.length > 0">
          <ng-container *ngIf="isAdmin; else noAdmin0">
            <br>
            <ng-container *ngFor="let p of providers">
              <ng-container *ngIf="p.palettesStatus1.length > 0">
                <div class="card">
                  <div class="card-header">
                    {{p.nom}} ({{ p.palettesStatus1.length }})
                  </div>
                  <div class="card-body">
                    
                    <ng-container *ngIf="p.palettesCassettes && p.palettesCassettes.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette de Cassettes ({{ p.palettesCassettes.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesCassettes">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesCustom && p.palettesCustom.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette personnalisée ({{ p.palettesCustom.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesCustom">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesP3P && p.palettesP3P.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette 3 produits [P3P] ({{ p.palettesP3P.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesP3P">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesP4P && p.palettesP4P.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette 4 produits [P4P] ({{ palettesP4P.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesP4P">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesP3PB && p.palettesP3PB.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette 3 produits B [P3PB] ({{ palettesP3PB.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesP3PB">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesPBG && p.palettesPBG.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette de Baguettes [PBG] ({{ palettesPBG.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesPBG">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-3 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesPCN && p.palettesPCN.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette de Canettes [PCN] ({{ palettesPCN.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesPCN">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="p.palettesPFU && p.palettesPFU.length > 0">
                      <div class="media p-2">
                      <div class="card">
                        <div class="card-header">
                          Palette de Fusettes [PFU] ({{ palettesPFU.length }})
                        </div>
                        <div class="card-body">
                          <div class="media row">
                          <ng-container *ngFor="let palette of p.palettesPFU">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <a class="btn btn-outline-warning p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date}}</a>
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div><br>
                      </div>
                    </ng-container>
  
                  </div>
                </div><br>
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-template #noAdmin0>
            
            <ng-container *ngIf="palettesCassettes && palettesCassettes.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette de Cassettes ({{ palettesCassettes.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesCassettes">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesCustom && palettesCustom.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette personnalisée ({{ palettesCustom.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesCustom">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesP3P && palettesP3P.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette 3 produits [P3P] ({{ palettesP3P.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesP3P">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesP4P && palettesP4P.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette 4 produits [P4P] ({{ palettesP4P.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesP4P">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesP3PB && palettesP3PB.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette 3 produits B [P3PB] ({{ palettesP3PB.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesP3PB">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesPBG && palettesPBG.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette de Baguettes [PBG] ({{ palettesPBG.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesPBG">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success m-2 p-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesPCN && palettesPCN.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette de Canettes [PCN] ({{ palettesPCN.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesPCN">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
            <ng-container *ngIf="palettesPFU && palettesPFU.length > 0">
              <div class="media p-2">
                <div class="card">
                  <div class="card-header">
                    Palette de Fusettes [PFU] ({{ palettesPFU.length }})
                  </div>
                  <div class="card-body">
                    <div class="media row">
                      <ng-container *ngFor="let palette of palettesPFU">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                          <a class="btn btn-outline-success p-2 m-2 w-75" href="#" [routerLink]="['/palette', palette.id]">{{palette.serialNumber | paletteBarcode: palette.provider.id}}<br><br>{{palette.creationDate | date:'dd MMM y'}}</a>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div><br>
              </div>
            </ng-container>
  
          </ng-template>
        </ng-container>
        
      </div>
  
    </main>
  
    <div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div>
  </div>
  